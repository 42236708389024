import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import api from "../../../services/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";
import { toast } from "react-toastify";

export default function EditItem() {
  const [images, setImages] = useState([]); // Initialize with an empty array
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const { state } = useUserContext();

  const token = state.token;

  console.log("object token in item", token);

  let navigate = useNavigate();

  const { id } = useParams();

  const [editItem, setEditItem] = useState({
    createdAt: new Date().toISOString(),
    description: "",
    images: [],
    itemType: "",
    price: 0,
    title: "",
    updatedAt: new Date().toISOString(),
  });

  useEffect(() => {
    loadItem();
  }, []);

  const loadItem = async () => {
    setIsSaving(true);
    const response = await client.get(`admin/get-additionalItem/${id}`, {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    const loadedItem = response.data.aditionalItems;

    setEditItem(() => ({
      createdAt: loadedItem.createdAt,
      description: loadedItem.description,
      itemType: loadedItem.itemType,
      price: loadedItem.price,
      title: loadedItem.title,
      updatedAt: loadedItem.updatedAt,
    }));
    const loadedImages = loadedItem.images; // Assuming an array of base64 image strings
    // {"url":"","file":""}
    const imageArray = loadedImages.map((img) => ({ url: img }));
    setIsSaving(false);
    setImages(imageArray);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }
    if (!editItem.itemType) {
      // Check if an item type is selected
      displayError("Please select an item type.");
      return;
    }
    if (!editItem.title) {
      displayError("Title is required.");
      return;
    }
    if (!editItem.price) {
      displayError("Price is required.");
      return;
    }
    if (!editItem.description) {
      displayError("Please enter a valid Description.");
      return;
    }
    if (images.length === 0) {
      toast.error("Please upload at least one image.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    setIsSaving(true); // Show loading animation
    try {
      const formData = new FormData();

      // Append Item details to the form data
      for (const key in editItem) {
        formData.append(key, editItem[key]);
      }

      const previousImagesUrl = [];
      // Append image files to the form data
      images.forEach((img) => {
        if (img.file != null) {
          formData.append("images", img.file); // Use the correct field name expected by the API
        } else {
          formData.append("images", img.url);
        }
      });

      // formData.append("images", previousImagesUrl);

      // Update the images property in editLocation
      const updatedItem = {
        ...editItem,
        images: previousImagesUrl,
      };

      await api.updateItem(id, formData, token); // Pass formData here
      setIsSuccessModalOpen(true);
      openSuccessModal(true);
      setEditItem(updatedItem); // Update local state after successful upload
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsSaving(false); // Hide loading animation
    }
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/additionalItems");
  };

  const handleImageUpload = (event, index) => {
    const newImages = [...images];
    newImages[index] = {
      url: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    };
    setImages(newImages);
  };

  const handleImageDelete = async (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const renderImages = () => {
    if (images && images.length > 0) {
      return images.map((image, index) => (
        <div className="upload-img" key={index}>
          <div className="image-container">
            <img src={image.url} alt={`Uploaded ${index}`} />
            <span
              className="delete-icon-image"
              onClick={() => handleImageDelete(index)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      ));
    } else {
      return null; // No images to render
    }
  };

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/additionalItems"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Edit Item Details",
          ]}
        />
        <div class="add-info">
          <div class="info-heading">
            <h3>General information</h3>
          </div>
          <div class="info-all-details">
            <h5>Item Type:</h5>
            <div class="item-type my-4 d-flex gap-5 align-items-center">
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Flower"
                  checked={editItem.itemType === "Flower"}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      itemType: e.target.value,
                    })
                  }
                />{" "}
                Flower
              </div>
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Tree"
                  checked={editItem.itemType === "Tree"}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      itemType: e.target.value,
                    })
                  }
                />{" "}
                Tree
              </div>
              {/*
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Stone"
                  checked={editItem.itemType === "Stone"}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      itemType: e.target.value,
                    })
                  }
                />{" "}
                Stone
              </div>
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Urn"
                  checked={editItem.itemType === "Urn"}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      itemType: e.target.value,
                    })
                  }
                />{" "}
                Urn
              </div>
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Casket"
                  checked={editItem.itemType === "Casket"}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      itemType: e.target.value,
                    })
                  }
                />{" "}
                Casket
              </div>*/}
            </div>
            <div class="site-location item- title">
              <div class="input-field">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter title"
                  value={editItem.title}
                  onChange={(e) =>
                    setEditItem({
                      ...editItem,
                      title: e.target.value,
                    })
                  }
                />
              </div>
              <div class="input-field for-euro">
                <label>Price</label>
                <div className="price_box">
                  <input
                    type="text"
                    placeholder="Enter price"
                    value={editItem.price}
                    onChange={(e) =>
                      setEditItem({
                        ...editItem,
                        price: e.target.value,
                      })
                    }
                  />
                  <i class="fa fa-pound-sign" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="discription">
              <label>Description</label>
              <textarea
                placeholder="Type something..."
                value={editItem.description}
                onChange={(e) =>
                  setEditItem({
                    ...editItem,
                    description: e.target.value,
                  })
                }
              />
            </div>
            <div class="upload-img-main">
              <div class="img-head">
                <label>
                  Location images <span>( Max 3 images )</span>
                </label>
              </div>
              <div className="image-uploader">
                {images.length < 3 && (
                  <div className="upload-img">
                    <input
                      type="file"
                      onChange={(e) => handleImageUpload(e, images.length)}
                    />
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                )}
                {renderImages()}
              </div>
            </div>
          </div>
        </div>
        <div class="location-save-btn">
          <Link to={"/additionalItems/itemList"}>
            <button class="cancle-btn">Cancel</button>
          </Link>
          <button onClick={onSubmit} class="save-btn">
            Save
          </button>
        </div>
      </div>
      {isSaving && (
        <div className="overlay">
          <Loading />
        </div>
      )}{" "}
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage="Success!"
        ModalMessage="The item details have been successfully updated."
      />
    </>
  );
}
