import { createContext, useContext, useEffect, useReducer } from "react";

const initialState = {
  user: JSON.parse(localStorage.getItem("userData")) || null,
  token: localStorage.getItem("token") || "",
  currentPage: 1,
  adminData: null,
};

// Create the context
const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    if (!state.token) {
      dispatch({ type: "LOGOUT" });
    }
  }, [state.token]);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(state.user));
  }, [state.user]);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

// Create a custom hook to use the context
export const useUserContext = () => {
  return useContext(UserContext);
};

// Action creators
export const setUser = (user, token) => ({
  type: SET_USER,
  payload: { user, token },
});

export const setAdminData = (adminData) => ({
  type: SET_ADMIN_DATA,
  payload: adminData,
});

export const logoutUser = () => ({
  type: LOGOUT,
});

// Define action types
const SET_USER = "SET_USER";
const LOGOUT = "LOGOUT";
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_ADMIN_DATA = "SET_ADMIN_DATA";

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});

// Define a reducer function to update user data
const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      return {
        ...state,
        user: null,
        token: "",
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_ADMIN_DATA:
      return {
        ...state,
        adminData: action.payload,
      };
    default:
      return state;
  }
};
