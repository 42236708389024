import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import error from "../assets/Images/warning 1.png";

Modal.setAppElement("#root");

const LogoutModal = ({
  isOpen,
  onClose,
  SuccessMessage,
  ModalMessage,
  onConfirmLogout,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Location Added Successfully"
      className="modal-contentmodal-content"
      overlayClassName="modal-overlay">
      <div className="modal-body">
        <img src={error} alt="" />
        <h2>{SuccessMessage}</h2>
        <p>{ModalMessage}</p>
        <button className="modal-close-btn" onClick={onClose}>
          No
        </button>
        <button className="modal-confirm-btn ms-2" onClick={onConfirmLogout}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default LogoutModal;
