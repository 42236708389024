import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import CustomModal from "../../../shared/CustomModal";
import client from "../../../services/Client";
import Parser from "html-react-parser";

const BlogDetails = () => {
  const { id } = useParams(); // Access the "id" parameter from the URL
  const [blogDetails, setBlogDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const { state } = useUserContext();

  const currentPage = state.currentPage;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogDetails = async () => {
      setIsLoading(true);
      try {
        const response = await client.get(`admin/get-blog/${id}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        const blogDetail = response.data.blogs;
        console.log(response.data.blogs);
        setBlogDetails(blogDetail); // Assuming the API response contains the location details
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching location details:", error);
        setIsLoading(false); // Stop loading even in case of an error
      }
    };
    fetchBlogDetails();
  }, [id]);

  // DELETE API IMPLEMENTATION

  const handleDelete = (id) => {
    setShowDeleteModal(true);
    setShowOverlay(true);
  };

  const confirmDelete = async (id) => {
    try {
      await client.put(`admin/delete-blog/${id}?isDeleted=true`, null, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay
      navigate("/blog"); // Navigate back to the list page after deletion
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false); // Hide the overlay
  };
  

  return (
    <>
      <div className="location_list" style={{ width: "80%" }}>
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading
          title={[
            <Link to={`/blog?page=${currentPage}`}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"
              ></i>
            </Link>,
            "Blog Detail",
          ]}
          style={{ width: "81% !important" }}
        />
        {isLoading ? (
          <div className="overlay">
            <Loading />
          </div>
        ) : (
          <Container fluid className="p-0">
            {blogDetails && (
              <div style={{ background: "#ffffff", padding: "20px" }}>
                <Row className="mt-5">
                  <div className="d-flex justify-content-between">
                    <div className="Blog_heading">
                      <h3>
                        {blogDetails.title}
                        <button
                          className={`status_button-detail ${
                            blogDetails.status === "published"
                              ? "published"
                              : "draft"
                          }`}
                        >
                          {blogDetails.status === "published"
                            ? "Published"
                            : "Draft"}
                        </button>
                      </h3>
                      <span className="text-muted">
                        Category: {blogDetails.categories}
                      </span>
                      <span className="circle"></span>
                      <span className="text-muted">
                        Added on:{" "}
                        {new Date(blogDetails.createdAt).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}{" "}
                        <span className="time text-muted">
                          {new Date(blogDetails.createdAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </span>
                      </span>
                    </div>

                    <div class="location-edit">
                      <ul className="p-0  d-flex align-items-center">
                        <li>
                          <Link
                            to={`/blog/editblog/${blogDetails.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <i
                              class="ri-pencil-line me-2"
                              style={{
                                color: "#BDBDBD",
                                fontSize: "22px",
                                border: "1px solid #E0E0E0",
                                width: "45px",
                                height: "45px",
                                padding: "10px",
                                borderRadius: "50%",
                                cursor: "pointer",
                                textAlign: "center",
                                textDecoration: "none",
                              }}
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          <i
                            onClick={() => handleDelete(blogDetails.id)}
                            class="fa fa-trash-o"
                            style={{
                              color: "#BDBDBD",
                              fontSize: "20px",
                              border: "1px solid #E0E0E0",
                              padding: "10px",
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            aria-hidden="true"
                          ></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col lg="12" className="mt-4">
                    <img
                      className="blog_image"
                      src={blogDetails.bannerImage}
                      alt=""
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg="3">
                    <h4 className="tags_heading">Page Title</h4>
                    <div class="tags custom-input">
                      <input
                        className="w-full h-[50px]"
                        readOnly
                        type="text"
                        value={blogDetails?.pageTitle}
                      />
                    </div>
                  </Col>
                  <Col lg="9">
                    <h4 className="tags_heading">Meta Name and content</h4>
                    <div className="tags custom-input">
                      <ul>
                        {blogDetails.metaTags.map((metaTags, index) => (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              padding: "0 0",
                              gap:"15px"
                            }}
                          >
                            <input style={{ fontWeight: "bold", width:"auto" }}
                              value={metaTags?.metaName}
                              readOnly
                            />
                            <input value={metaTags?.metaContent} readOnly/>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                </Row>
                <h4 className="my-3 mb-5">Content</h4>
                {blogDetails?.addContent.map((content, index) => (
                  <div
                    key={index}
                    className={`container_one ${
                      index % 2 === 0 ? "even" : "odd"
                    }`}
                  >
                    {content.images ? (
                      <div className="image">
                        <div className="image">
                          <img
                            src={content.images}
                            alt={`ImageContent ${index}`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: "none" }}>no image</div>
                    )}
                    <p className="content">{Parser(content.text)}</p>
                  </div>
                ))}
              </div>
            )}
          </Container>
        )}
      </div>
      {showDeleteModal && <div className="overlay"></div>}
      <CustomModal
        isOpen={showDeleteModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to delete this Blog?"
        onConfirmDelete={() => confirmDelete(blogDetails.id)}
      />
    </>
  );
};

export default BlogDetails;
