import React, { useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate } from "react-router-dom";
import addPhoto from "../../../assets/Images/add_photo_alternate.png";
import addText from "../../../assets/Images/insert_text.png";
import { Col, Container, Row } from "react-bootstrap";
import { WithContext as ReactTags } from "react-tag-input";
import api from "../../../services/api";
import { toast } from "react-toastify";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import Loading from "../../../shared/Loading";
import logoOne from "../../../assets/Images/logoOne.png";
import { useUserContext } from "../../../components/context/UserContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AddBlogDetails() {
  const [bannerImage, setBannerImage] = useState(false);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [content, setContent] = useState([]);
  const [metaList, setMetaList] = useState([{ metaName: "", metaContent: "" }]);

  const [categories, setCategories] = useState("");
  const { state } = useUserContext();
  const token = state.token;

  const navigate = useNavigate();

  const addMetaBlock = () => {
    setMetaList([...metaList, { metaName: "", metaContent: "" }]);
  };

  const removeMetaBlock = (index) => {
    const updatedMetaList = metaList.filter((_, i) => i !== index);
    setMetaList(updatedMetaList);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMetaList = [...metaList];
    updatedMetaList[index][field] = value;
    setMetaList(updatedMetaList);
  };

  // const handleDeleteOne = (i) => {
  //   const newTags = tags.slice();
  //   newTags.splice(i, 1);
  //   setTags(newTags);
  // };

  // const handleDeleteTwo = (i) => {
  //   const newTags = metaTags.slice();
  //   newTags.splice(i, 1);
  //   setMetaTags(newTags);
  // };

  // const handleAdditionOne = (tag) => {
  //   // console.log("tag from tags", tag);
  //   // console.log("tag from tags text", tag.text);
  //   setTags([...tags, tag]);
  // };

  // const handleAdditionTwo = (tag) => {
  //   // console.log("tag from meta", tag);
  //   setMetaTags([...metaTags, tag]);
  // };

  const handleAddDivClick = () => {
    setContent((prevContent) => [
      ...prevContent,
      { addContentText: "", imageFile: null, imageUrl: "" },
    ]);
  };

  const handleDeleteImage = (index) => {
    const updatedContent = [...content];
    updatedContent[index].imageUrl = "";
    updatedContent[index].imageFile = null;
    setContent(updatedContent);
  };

  const handleDeleteClick = (index) => {
    const updatedContent = [...content];
    updatedContent.splice(index, 1);
    setContent(updatedContent);
  };

  const handleContentTextChange = (text, index) => {
    const updatedContent = [...content];

    if (updatedContent[index] && typeof updatedContent[index] === "object") {
      updatedContent[index].addContentText = text;
      setContent(updatedContent);
    }
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/blog");
  };

  const handleAddBlog = async (e, status) => {
    e.preventDefault();

    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }
    if (!title) {
      displayError("Title is required.");
      return;
    }
    if (!slug) {
      displayError("Custom slug is required.");
      return;
    }
    if (!pageTitle) {
      displayError("Page title is required.");
      return;
    }

    // const hasDescriptionMeta = metaList.some(
    //   (meta) =>
    //     meta.metaName.toLowerCase() === "description" &&
    //     meta.metaContent.trim() !== ""
    // );

    // if (!hasDescriptionMeta) {
    //   displayError(
    //     "Add at least one meta tag with the name 'description' and its content."
    //   );
    //   return;
    // }

    if (!metaList.length === 0) {
      displayError("Add at least one meta name and it's content.");
      return;
    }
    if (!bannerImage) {
      displayError("Add banner Image.");
      return;
    }

    // Check if categories field is empty
    const categoriesValue = categories.trim() === "" ? "--" : categories;

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("customSlug", slug);
      formData.append("status", status);
      formData.append("categories", categoriesValue);
      formData.append("pageTitle", pageTitle);
      formData.append("metaTags", JSON.stringify(metaList));

      formData.append("bannerImage", bannerImage);

      for (let i = 0; i < content.length; i++) {
        formData.append("addContentText", content[i].addContentText);
        if (content[i].imageFile) {
          formData.append(`addContentImage#${i}`, content[i].imageFile);
        }
      }
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      setIsSaving(true);

      await api.addBlog(formData, { headers });

      setIsSuccessModalOpen(true);
      openSuccessModal(true);
    } catch (error) {
      console.log("Error adding blog :", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      setIsSaving(false);
    }
  };

  const onBannerImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    setBannerImage(selectedFile);
  };
  const handleImageChange = (event, index) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }
    const imageUrl = URL.createObjectURL(selectedFile);

    const updatedContent = [...content];
    if (content[index].imageUrl === "") {
      updatedContent[index].imageUrl = { logoOne };
    }
    if (content[index].imageFile === "") {
      updatedContent[index].imageUrl = { logoOne };
    }
    updatedContent[index].imageUrl = imageUrl;
    updatedContent[index].imageFile = selectedFile;

    setContent(updatedContent);
    // console.log("Selected Image File:", selectedFile);
  };

  let bannerImageUrl = null; // Initialize with null

  if (bannerImage) {
    bannerImageUrl = URL.createObjectURL(bannerImage); // Create URL for the image file
  }

  const clearImage = () => {
    setBannerImage(null);
  };

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/blog"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"
              ></i>
            </Link>,
            "Add Blog",
          ]}
        />
        <div className="blog-info-main">
          <div className="blog-head">
            <h3>Blog information</h3>
          </div>
          <div className="blog-info">
            <div className="title">
              <label>Blog Title</label>
              <input
                type="text"
                placeholder="Kendle"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="category-slug mt-4">
              <div className="input-group-button">
                <label>Categories</label>
                <input
                  type="text"
                  placeholder="Enter Category"
                  value={categories}
                  onChange={(e) => setCategories(e.target.value)}
                />
              </div>
              <div className="input-group-button ">
                <label>
                  Custom Slug{" "}
                  <span>
                    ( The slug must not contain spaces; use hyphens to separate
                    words )
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
            </div>
            <div className="blog-option">
              <div className="input-group-button-two">
                <label>Page Title</label>
                <div>
                  <input
                    type="text"
                    placeholder="Page Title"
                    value={pageTitle}
                    onChange={(e) => setPageTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="input-group-button-three">
              <label>
                Meta Description
                <span>
                  (Only use 'description' and 'keywords' etc. as meta names)
                </span>
              </label>
              {metaList.map((meta, index) => (
                <div key={index} className="meta-content-description">
                  <div className="first-div">
                    <label>Meta Name</label>
                    <input
                      type="text"
                      placeholder="name"
                      value={meta.metaName}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "metaName",
                          e.target.value.trim()
                        )
                      }
                    />
                  </div>
                  <div className="second-div">
                    <label>Meta Content</label>
                    <input
                      type="text"
                      placeholder="content"
                      value={meta.metaContent}
                      onChange={(e) =>
                        handleInputChange(index, "metaContent", e.target.value)
                      }
                    />
                  </div>
                  <div className="third-div">
                    {index === metaList.length - 1 ? (
                      <button onClick={addMetaBlock}>Add New</button>
                    ) : (
                      <button onClick={() => removeMetaBlock(index)}>
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="blog-banner">
              <span>Banner image</span>
              <div className="blog_banner-image">
                <div className="img-upload">
                  {!bannerImage ? (
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={onBannerImageChange}
                      />
                      <div className="upload-text">
                        <i
                          className="fa fa-cloud-upload"
                          aria-hidden="true"
                        ></i>
                        <span>Upload photo</span>
                        <p>(Recommended size1920x350)</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={bannerImageUrl}
                        alt="Selected"
                        className="selected-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          padding: "5px",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                      <span className="delete-icon-image" onClick={clearImage}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h4 className="content_tag">Add Content</h4>
            {content.length === 0 && (
              <div className="blog-content">
                <div className="add-content">
                  <div className="add-img-text">
                    <span onClick={handleAddDivClick}>
                      <img src={addPhoto} alt="add" />
                    </span>
                    <label>Image</label>
                  </div>
                  <div className="add-img-text">
                    <span onClick={handleAddDivClick}>
                      <img src={addText} alt="add text" />
                    </span>
                    <label>Text</label>
                  </div>
                </div>
              </div>
            )}
            {content.map((_, index) => (
              <div key={index} className="blog-content">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="blog-delete_one">
                    <button onClick={() => handleDeleteClick(index)}>
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                  </div>
                  {index === content.length - 1 && (
                    <div className="blog-delete_two">
                      <button onClick={handleAddDivClick}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  )}
                </div>
                <div className="add-content-nextBlog">
                  <Container fluid>
                    <Row
                      className={`add_content-row ${
                        index % 2 === 0 ? "row-right" : "row-left"
                      }`}
                    >
                      <Col lg="3">
                        {content[index].imageUrl !== "" ? (
                          <div className="uploaded-image">
                            <img
                              src={content[index].imageUrl}
                              alt="Selected"
                              className="selected_image"
                            />
                            <button
                              className="delete-icon"
                              onClick={() => handleDeleteImage(index)}
                            >
                              <i
                                className="fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        ) : (
                          <div class="img-upload">
                            <input
                              type="file"
                              id={`file-input-${index}`}
                              name={`file-input-${index}`}
                              accept="image/*"
                              onChange={(event) =>
                                handleImageChange(event, index)
                              }
                            />
                            <div class="upload-text">
                              <i
                                class="fa fa-cloud-upload"
                                aria-hidden="true"
                              ></i>
                              <span>Upload photo</span>
                            </div>
                            {content[index].imageFile ? (
                              <img
                                src={content[index].imageFile}
                                alt="Placeholder"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </Col>
                      <Col lg="9">
                        <ReactQuill
                          theme="snow"
                          value={content[index].addContentText}
                          onChange={(content) => {
                            handleContentTextChange(content, index);
                          }}
                          // modules={{
                          //   toolbar: false,
                          // }}
                        />

                        {/* <textarea
                          name=""
                          id=""
                          rows="6"
                          value={content[index].addContentText}
                          onChange={(event) =>
                            handleContentTextChange(event.target.value, index)
                          }></textarea> */}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="blog-btn">
          <div className="blog-cancel">
            <Link to="/blog">
              <button>Cancel</button>
            </Link>
          </div>
          <div className="blog-save">
            <button
              onClick={(e) => handleAddBlog(e, "drafts")}
              className="draft-btn"
            >
              Save as draft
            </button>

            <button onClick={(e) => handleAddBlog(e, "published")}>
              Publish
            </button>
          </div>
        </div>
      </div>
      {isSaving && (
        <div className="overlay">
          <Loading />
        </div>
      )}{" "}
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Your Blog has been added successfully"}
      />
    </>
  );
}

export default AddBlogDetails;
