import React from "react";
import homeImg from "../assets/Images/homeImg.png";

export default function HomeImage() {
  const style = {
    homeimg: {
      width: "100%",
      objectFit: "cover",
      height: "100vh",
    },
  };

  return (
    <div>
      <img style={style.homeimg} src={homeImg} alt="" />
    </div>
  );
}
