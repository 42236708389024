import React, { useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useUserContext } from "../../../components/context/UserContext";

function AddRole() {
  const [image, setImage] = useState(false);
  const { state } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const permissionsList = [
    { key: "dashboard", label: "Dashboard" },
    { key: "plots", label: "Plots" },
    // { key: "locations", label: "Location" },
    { key: "additionalItems", label: "Additional Items" },
    { key: "salesAgents", label: "Sales Agent" },
    // { key: "orders", label: "Order" },
    { key: "users", label: "Users" },
    { key: "blog", label: "Blog" },
  ];
  const onImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    setImage(selectedFile);
  };

  let imageUrl = null;

  if (image) {
    imageUrl = URL.createObjectURL(image);
  }

  const clearImage = () => {
    setImage(null);
    // setAddAgent((prevAgent) => ({
    //   ...prevAgent,
    //   image: null,
    // }));
  };

  const handleCheckboxChange = (permission) => {
    setSelectedPermissions((prevPermissions) =>
      prevPermissions.includes(permission)
        ? prevPermissions.filter((perm) => perm !== permission)
        : [...prevPermissions, permission]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName) {
      toast.error("First Name is required");
      return;
    }

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      toast.error("Email is required");
      return;
    } else if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email");
      return;
    }

    if (selectedPermissions.length === 0) {
      toast.error("At least one permission must be selected");
      return;
    }

    if (!image) {
      toast.error("Image is required");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    selectedPermissions.forEach((permission) => {
      formData.append("permissions", permission);
    });

    if (image) {
      formData.append("newProfileImage", image);
    }

    try {
      const response = await api.addSubAdmin(formData, state?.token);
      console.log("response");
      // toast.success(response?.message);
      navigate("/rolesandpermission");
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <div className="add-location-main">
      <LocationHeading
        title={[
          <Link to={"/rolesandpermission"} key="back-link">
            <i
              style={{
                color: "#000",
                fontSize: "16px",
                marginBottom: "10px",
              }}
              className="fa fa-long-arrow-left me-2"
              aria-hidden="true"></i>
          </Link>,
          "Add Role",
        ]}
      />
      <div className="blog-info-main">
        <div className="location-info-head">
          <h3>General information</h3>
        </div>
        <Container className="p-2 main_container" fluid>
          <Row className="mt-5">
            <Col md="3">
              <div class="agent-photo">
                <div class="agent-upload">
                  {!image ? ( // Conditional rendering based on image state
                    <div>
                      <input type="file" onChange={onImageChange} />
                      <div className="upload-icon">
                        <i
                          className="fa fa-cloud-upload"
                          aria-hidden="true"></i>
                        <span>Upload photo</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={imageUrl}
                        alt="Selected"
                        className="selected-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          padding: "5px",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                      <span className="delete-icon-image" onClick={clearImage}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md="9">
              <Row>
                <Col>
                  <div className="agent_input">
                    <label htmlFor="">First Name</label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="agent_input">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="agent_input">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="agent_input">
                    <label htmlFor="">Permission</label>
                    <Table size="lg" className="location_list-table">
                      <thead>
                        <tr>
                          {permissionsList.map((permission) => (
                            <th key={permission.key}>{permission.label}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {permissionsList.map((permission) => (
                            <td key={permission.key}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedPermissions.includes(
                                  permission.key
                                )}
                                onChange={() =>
                                  handleCheckboxChange(permission.key)
                                }
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div class="location-save-btn">
        <Link to={"/rolesandpermission"}>
          <button class="cancle-btn">Cancel</button>
        </Link>
        <button onClick={handleSubmit} class="save-btn user-edit-button">
          {loading ? <div className="edit_loader-one"></div> : "Save"}
        </button>
      </div>
    </div>
  );
}

export default AddRole;
