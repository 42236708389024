import React, { useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
// import AddSalesAgent from "./AddSalesAgent";
import noData from "../../../assets/Images/NoData.svg";
import { Dropdown, Modal, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocationHeading from "../../../shared/LocationHeading";
import CustomModal from "../../../shared/CustomModal";
import DatePicker from "react-datepicker";
import {
  useUserContext,
  setCurrentPage,
} from "../../../components/context/UserContext";
// import { format } from "date-fns";
import client from "../../../services/Client";

function ParentAgent() {
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState("");
  // const [currentPage, setCurrentPage] = useState(1); // Initialize current page to 1
  const [perPage] = useState(15); // Set the items per page
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const [showOverlay, setShowOverlay] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [salesAgentName, setSalesAgentName] = useState("");
  const [debouncedSalesAgentName, setDebouncedSalesAgentName] = useState("");
  const { state, dispatch } = useUserContext();
  const [fromDate, setFromDate] = useState(""); // Filter value for from date
  const [toDate, setToDate] = useState("");
  const currentPage = state.currentPage;
  const navigate = useNavigate();
  const location = useLocation();

  const closeModal = () => {
    setShowModal(false);
    setShowOverlay(false);
    document.body.style.overflow = "auto";
  };

  const fetchAgents = async () => {
    setIsLoading(true);

    const queryParams = [];

    if (salesAgentName) {
      queryParams.push(`salesAgentName=${encodeURIComponent(salesAgentName)}`);
      dispatch(setCurrentPage(1));
    }

    queryParams.push(`page=${currentPage}`);

    const queryString = queryParams.join("&");
    try {
      const response = await client.get(
        `admin/get-sales-agents?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        },
        {
          params: {
            salesAgentName: debouncedSalesAgentName,
            perPage: perPage,
          },
        }
      );
      const agentData = response.data.data.salesAgents;
      setAgents(agentData);
      setTotal(response.data.data.total);
      setIsLoading(false);
      console.log("agents are :", response);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSalesAgentName(salesAgentName);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [salesAgentName]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1;

    // Check if the current page is different from the one in the URL
    if (page !== currentPage) {
      dispatch(setCurrentPage(1));
    }
    fetchAgents();
  }, [location.search, currentPage, dispatch, debouncedSalesAgentName]);

  const handleClearInput = () => {
    setSalesAgentName(""); // Clear the input value
    setIsLoading(true);
    navigate(`/salesAgent?page=${currentPage}`);
  };

  // DELETE API IMPLEMENTATION

  const handleDelete = (agentId) => {
    const agent = agents.find((agent) => agent.id === agentId);
    setAgentToDelete(agent);
    setShowDeleteModal(true);
    setShowOverlay(true); // Show the overlay
  };

  const confirmDelete = async (agentId) => {
    try {
      setIsLoading(true);
      await client.put(
        `admin/delete-sales-agent/${agentId}?isDeleted=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      setAgents((prevAgent) =>
        prevAgent.filter((agent) => agent.id !== agentId)
      );
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
      fetchAgents();
      // No need to call fetchPlots() here, as setPlots already updated the plots state
    } catch (error) {
      console.error("Error deleting plots:", error);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false); // Hide the overlay
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      // Dispatch the action to set the current page
      dispatch({ type: "SET_CURRENT_PAGE", payload: newPage });
      navigate(`/salesAgent?page=${newPage}`);
    }
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading title={"Sales Agent"} />
        <div class="loaction-list-main">
          <div class="loaction-list-head">
            <div class="search-bar">
              <input
                type="text"
                value={salesAgentName}
                placeholder="Search by name"
                onChange={(e) => setSalesAgentName(e.target.value)}
              />
              {salesAgentName && (
                <i
                  class="fa fa-times-circle clear-icon"
                  aria-hidden="true"
                  onClick={handleClearInput}
                  style={{ cursor: "pointer" }}></i>
              )}
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <div class="add-location">
              <button>
                <Link to={"/salesAgent/addSalesAgentDetails"}>
                  <i class="fa fa-plus me-2" aria-hidden="true"></i>
                  Sales Agent
                </Link>
              </button>
            </div>
          </div>
          {isLoading ? (
            // Render the loader if isLoading is true
            <div className="overlay">
              <Loading />
            </div>
          ) : (
            <>
              <Table size="lg" className="location_list-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th className="text-center">Upcoming Meetings</th>
                    <th>Created On</th>
                    {/* <th>Status</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {agents && agents.length > 0 ? (
                    debouncedSalesAgentName &&
                    agents.every(
                      (agent) =>
                        !agent.firstName
                          .toLowerCase()
                          .includes(debouncedSalesAgentName.toLowerCase())
                    ) ? (
                      <tr>
                        <td colSpan="6" className="text-center">
                          {debouncedSalesAgentName
                            ? `No agents found with "${debouncedSalesAgentName}"`
                            : "No agents found"}
                        </td>
                      </tr>
                    ) : (
                      agents.map((agent) => (
                        <tr>
                          <td>
                            <table>
                              <tr>
                                <td
                                  class="product-img"
                                  style={{ border: "none" }}>
                                  <img
                                    src={agent.image}
                                    alt=""
                                    className="me-2"
                                  />
                                </td>
                                <td class="rating" style={{ border: "none" }}>
                                  {agent.firstName} {agent.lastName}
                                  <p>{agent.location}</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td>{agent.email}</td>
                          <td className="text-center">
                            {agent.upcomingEventsCount}
                          </td>
                          <td className="datetime-cell">
                            {new Date(agent.updatedAt).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                            <br />
                            <span className="time text-muted">
                              {new Date(agent.updatedAt).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </span>
                          </td>
                          {/* <td>
                            <button className="status_button">Available</button>
                              </td> */}
                          <td>
                            <Dropdown className="details_dropdown">
                              <Dropdown.Toggle id="dropdown-basic">
                                <button>
                                  <i
                                    class="fa fa-ellipsis-h"
                                    aria-hidden="true"></i>
                                </button>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <Link
                                    to={`/salesAgent/salesAgentDetails/${agent.id}`}>
                                    <i
                                      className="fa fa-eye me-2"
                                      style={{ color: "yellow" }}
                                      aria-hidden="true"></i>
                                    View Details
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <Link
                                    to={`/salesAgent/editAgentDetails/${agent.id}`}>
                                    <i
                                      class="ri-pencil-line me-2"
                                      style={{ color: "green" }}
                                      aria-hidden="true"></i>
                                    Edit
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <Link onClick={() => handleDelete(agent.id)}>
                                    <i
                                      className="fa fa-trash me-2"
                                      style={{ color: "red" }}
                                      aria-hidden="true"></i>
                                    Delete
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    )
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center no_data-image">
                        <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <div className="next-previous-slide">
                <div className="slides-heading">
                  <span>
                    Showing {(currentPage - 1) * perPage + 1} to{" "}
                    {Math.min(currentPage * perPage, total)} out of {total}
                  </span>
                </div>

                <div className="slides-button">
                  <ul className="d-flex justify-center align-items-center">
                    <li>
                      <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}>
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                          style={{ cursor: "pointer", color: "#333" }}></i>
                      </button>
                    </li>
                    <li className="d-flex justify-center align-items-center">
                      {Array.from({ length: Math.ceil(total / perPage) }).map(
                        (_, index) => {
                          // Add logic to display ellipsis (...) if there are many pages
                          if (
                            index < 2 ||
                            index > Math.ceil(total / perPage) - 3 ||
                            (index >= currentPage - 2 &&
                              index <= currentPage + 1)
                          ) {
                            return (
                              <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={
                                  currentPage === index + 1 ? "active" : ""
                                }>
                                {index + 1}
                              </button>
                            );
                          } else if (
                            index === 2 ||
                            index === Math.ceil(total / perPage) - 3
                          ) {
                            // Display ellipsis (...) for the third and third-to-last position
                            return <span key={index}>...</span>;
                          }
                          return null;
                        }
                      )}
                    </li>
                    <li>
                      <button
                        disabled={currentPage === Math.ceil(total / perPage)}
                        onClick={() => handlePageChange(currentPage + 1)}>
                        <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                          style={{ cursor: "pointer" }}></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
        <Modal
          show={showModal}
          onHide={closeModal}
          dialogClassName="modal-right" // Custom CSS class for styling the modal
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="filter-status">
              <span>Status</span>
              <div class="input-group">
                <input type="checkbox" className="me-2" />
                <label>Available</label>
              </div>
              <div class="input-group">
                <input type="checkbox" className="me-2" />
                <label>Sold</label>
              </div>
            </div>
            <div class="filter-status">
              <span>Category</span>
              <div class="input-group">
                <input type="checkbox" className="me-2" />
                <label>Burial Plots</label>
              </div>
              <div class="input-group">
                <input type="checkbox" className="me-2" />
                <label>Urn Plots</label>
              </div>
            </div>
            <div class="filter-date">
              <div class="input-group">
                <label>From</label>
                <div class="input-date" style={{ position: "relative" }}>
                  <DatePicker
                    selected={fromDate}
                    value={fromDate}
                    onChange={(fromDate) => setFromDate(fromDate)}
                    placeholderText="MM-DD-YYYY"
                    dateFormat="MM-dd-yyyy"
                  />
                </div>
              </div>
              <div class="input-group">
                <label>To</label>
                <DatePicker
                  selected={toDate}
                  value={toDate}
                  onChange={(toDate) => setToDate(toDate)}
                  placeholderText="MM-DD-YYYY"
                  dateFormat="MM-dd-yyyy"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="model_button">
              <button className="reset-btn">Reset</button>
              <button className="close-btn" onClick={closeModal}>
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      {showDeleteModal && <div className="overlay"></div>}
      <CustomModal
        isOpen={showDeleteModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to delete this Agent?"
        onConfirmDelete={() => confirmDelete(agentToDelete.id)}
      />
    </>
  );
}

export default ParentAgent;
