// PlotDetailsOne.js
import React, { useEffect, useState } from "react";
import { usePlotContext } from "../../../components/context/PlotContext";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import client from "../../../services/Client";
import { useUserContext } from "../../../components/context/UserContext";

function PlotDetailsOne() {
  const { id } = useParams();
  const [plotDetails, setPlotDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const { plotState } = usePlotContext();
  const navigate = useNavigate();
  const { state } = useUserContext();
  const selectedPlots = plotState.selectedPlots;

  // console.log(selectedPlots);
  const token = state.token;

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // const calculateTotalPrice = (plots) => {
  //   return plots.reduce((total, plot) => {
  //     const plotTotalPrice = plot.plots.reduce((plotTotal, plotDetail) => {
  //       return plotTotal + plotDetail.price;
  //     }, 0);

  //     return total + plotTotalPrice;
  //   }, 0);
  // };

  useEffect(() => {
    const fetchPlotDetails = async () => {
      setIsLoading(true);
      try {
        const response = await client.get(`admin/get-plot-info/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const plots = response.data.data;
        console.log("Fetched plots:", plots);
        setPlotDetails(plots);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching location details:", error);
        setIsLoading(false);
      }
    };
    fetchPlotDetails();
  }, [id]);

  return (
    <>
      <div className="location_list">
        <LocationHeading
          title={[
            <Link onClick={handleGoBack}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Plot Details",
          ]}
        />
        <div className="blog-info-main p-2">
          <Container className="mt-5">
            {plotDetails && (
              <Row>
                <Col lg="4">
                  <div className="details-img">
                    <div className="view-img">
                      <div className="main_image">
                        <img src={plotDetails.plots[0].images[0]} alt="" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="8">
                  <div class="about-location">
                    <div class="about-location-head">
                      <div class="about-heading">
                        <h3>{plotDetails.siteName}</h3>
                        <span>
                          Added on:{" "}
                          {new Date(plotDetails.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )}{" "}
                          <span className="time">
                            {new Date(plotDetails.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="about-category d-flex mt-4">
                      <div className="me-5">
                        <h6>Category</h6>
                        <span>
                        {plotDetails.type === "Single Plot"
                          ? plotDetails.category.slice(0, -1)
                          : plotDetails.category}</span>
                      </div>
                      <div className="ms-5">
                        <h6>Type</h6>
                        <span>{plotDetails.type}</span>
                      </div>
                    </div>
                    <div class="about-price">
                      <span>£{plotDetails.totalPlotPrice}</span>
                    </div>
                    {plotDetails.plots.map((plotDetail, plotIndex) => (
                      <div key={plotIndex}>
                        <h4 className="mt-4">Plot {plotIndex + 1}</h4>
                        <div class="about-code mt-4">
                          <h5>Plot Code</h5>
                          <span className="text-muted">
                            {plotDetail.plotcode}
                          </span>
                        </div>
                        <div class="about-discription mt-4">
                          <h4>Description</h4>
                          <p className="text-muted">{plotDetail.description}</p>
                        </div>
                        <div class="about-sizes">
                          <ul>
                            <li>
                              <p>Plot Size</p>
                              {plotDetail.plotSize}
                            </li>
                            <li>
                              <p>Plot Number</p>
                              {plotDetail.plotNumber}
                            </li>
                            <li>
                              <p>Phase</p>
                              {plotDetail.phase}
                            </li>
                            <li>
                              <p>Subphase</p>
                              {plotDetail.subPhase}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

export default PlotDetailsOne;
