import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LocationHeading from "../../../shared/LocationHeading";
import { Col, Container, Row } from "react-bootstrap";
import zoomLogo from "../../../assets/Images/company_logo.png";
import calendlyLogo from "../../../assets/Images/company.png";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import CustomModal from "../../../shared/CustomModal";
import client from "../../../services/Client";
import Calendar from "react-calendar";
import { format } from "date-fns";
import videoIcon from "../../../assets/Images/video_icon.svg";
import watchIcon from "../../../assets/Images/watch_icon.svg";
import agentImg from "../../../assets/Images/agent_profile.png";

function SalesAgentDetails() {
  const { id } = useParams(); // Access the "id" parameter from the URL
  const [agentDetails, setAgentDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const { state } = useUserContext();
  const [showPassword, setShowPassword] = useState(false);
  // const [showZoomPassword, setShowZoomPassword] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  const currentPage = state.currentPage;

  const getAsterisks = (password) => {
    return "*".repeat(password.length);
  };

  const token = state.token;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgentDetails = async () => {
      setIsLoading(true);
      try {
        const response = await client.get(`admin/sales-agent/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAgentDetails(response.data.salesAgent); // Assuming the API response contains the location details
        // console.log("sales agent is:", response.data.salesAgent);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching location details:", error);
        setIsLoading(false);
      }
    };
    fetchAgentDetails();
  }, [id]);

  const fetchAgentEvent = async () => {
    setIsLoading(true);
    try {
      const formattedEventDate = format(new Date(eventDate), "MM-dd-yyyy");

      const response = await client.get(`admin/get-salesAgent-events`, {
        params: {
          salesAgentId: id,
          eventDate: formattedEventDate,
        },
      });
      const eventData = response.data.data;
      setEvents(eventData);
      console.log("Events are in details", response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (eventDate) {
      fetchAgentEvent();
    }
  }, [eventDate]);

  // DELETE API IMPLEMENTATION

  const handleDelete = () => {
    setShowDeleteModal(true);
    setShowOverlay(true); // Show the overlay
  };

  const confirmDelete = async () => {
    try {
      await client.put(`admin/delete-sales-agent/${id}?isDeleted=true`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay

      navigate(`/salesAgent/?page=${currentPage}`); // Navigate back to the list page after deletion
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false); // Hide the overlay
  };
  console.log("agentDetails", agentDetails);
  console.log("events", events);

  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading
          title={[
            <Link to={`/salesAgent/?page=${currentPage}`}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Agent Details",
          ]}
        />
        {isLoading ? ( // Display loading animation while data is being fetched
          <div className="overlay">
            <Loading />
          </div>
        ) : (
          <div className="blog-info-main p-2">
            <Container fluid className="mt-5">
              {agentDetails && (
                <Row>
                  <Col lg="3">
                    <div class="details-img">
                      <div class="view-img">
                        <img src={agentDetails.image} alt="" />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div class="about-location">
                      <div class="about-location-head">
                        <div class="about-heading">
                          <h3>
                            {agentDetails.firstName} {agentDetails.lastName}
                          </h3>
                          <span>
                            Added on:{" "}
                            {new Date(
                              agentDetails.createdAt
                            ).toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}{" "}
                            <span className="time">
                              {new Date(
                                agentDetails.createdAt
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </span>
                        </div>
                        <div class="location-edit">
                          <ul className="p-0 m-0 d-flex">
                            <li>
                              <Link
                                to={`/salesAgent/editAgentDetails/${agentDetails.id}`}
                                style={{ textDecoration: "none" }}>
                                <i
                                  class="ri-pencil-line me-2"
                                  style={{
                                    color: "#BDBDBD",
                                    fontSize: "24px",
                                    border: "1px solid #E0E0E0",
                                    width: "50px",
                                    height: "50px",
                                    padding: "10px",
                                    borderRadius: "50%",
                                    textDecoration: "none",
                                  }}
                                  aria-hidden="true"></i>
                              </Link>
                            </li>
                            <li>
                              <i
                                onClick={() => handleDelete(agentDetails.id)}
                                class="fa fa-trash-o me-2"
                                style={{
                                  color: "#BDBDBD",
                                  fontSize: "22px",
                                  textAlign: "center",
                                  border: "1px solid #E0E0E0",
                                  padding: "10px",
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                aria-hidden="true"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <Row className="mt-4">
                        <Col>
                          <div className="agent_email">
                            <h5>Email</h5>
                            <p className="text-muted">{agentDetails.email}</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="agent_email">
                            <h5>Address</h5>
                            <p className="text-muted">
                              {agentDetails.location}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <div class="about-discription mt-3">
                        <h4>Bio</h4>
                        <p className="text-muted">{agentDetails.shortBio}</p>
                      </div>
                      <div class="about-discription mt-3">
                        <h4>Expended Bio</h4>
                        <p className="text-muted">{agentDetails.expendedBio}</p>
                      </div>
                    </div>
                    <div class="integrate">
                      <h3>Integrate with:</h3>
                      <div class="Login-details">
                        <span>
                          <img src={calendlyLogo} alt="" />
                        </span>
                        <div class="agent-login">
                          <label>Username</label>
                          <p>{agentDetails.integrate.calendly.userName}</p>
                        </div>
                        <div class="agent-login">
                          <label>Password</label>
                          <div className="d-flex justify-content-between align-items-center">
                            {showPassword ? (
                              <p>{agentDetails.integrate.calendly.password}</p>
                            ) : (
                              <p>
                                {getAsterisks(
                                  agentDetails.integrate.calendly.password
                                )}
                              </p>
                            )}
                            <button
                              style={{
                                background: "none",
                                border: "0px",
                                color: "#828282",
                              }}
                              onClick={() => setShowPassword(!showPassword)}
                              className="password-toggle-button">
                              {showPassword ? (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"></i>
                              ) : (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              )}
                            </button>
                          </div>
                        </div>
                        <div class="agent-login">
                          <label>Link</label>
                          <p>{agentDetails.integrate.calendly.link}</p>
                        </div>
                      </div>
                      {/*  <div class="Login-details">
                        <span>
                          <img src={zoomLogo} alt="" />
                        </span>
                        <div class="agent-login">
                          <label>Username</label>
                          <p>{agentDetails.integrate.zoom.userName}</p>
                        </div>
                        <div class="agent-login">
                          <label>Password</label>
                          <div className="d-flex justify-content-between align-items-center">
                            {showZoomPassword ? (
                              <p>{agentDetails.integrate.zoom.password}</p>
                            ) : (
                              <p>
                                {getAsterisks(
                                  agentDetails.integrate.zoom.password
                                )}
                              </p>
                            )}
                            <button
                              style={{
                                background: "none",
                                border: "0px",
                                color: "#828282",
                              }}
                              onClick={() =>
                                setShowZoomPassword(!showZoomPassword)
                              }
                              className="password-toggle-button">
                              {showZoomPassword ? (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"></i>
                              ) : (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              )}
                            </button>
                          </div>
                        </div>
                        <div class="agent-login">
                          <label>Link</label>
                          <p>{agentDetails.integrate.zoom.link}</p>
                        </div>
                      </div> */}
                    </div>
                  </Col>
                  <Col lg="3">
                    <div class="dashboard-right-details">
                      <div className="calendar-container">
                        <Calendar
                          onChange={(eventDate) => setEventDate(eventDate)}
                          value={eventDate} // Set it to the formatted date
                          calendarType="US"
                          formatShortWeekday={(locale, date) =>
                            date.toLocaleDateString(locale, {
                              weekday: "short",
                            })[0]
                          }
                        />
                      </div>
                      <div class="admin-meeting-main">
                        {!events || events.length === 0 ? (
                          <div className="text-center fw-bold">
                            No meetings found
                          </div>
                        ) : (
                          events.map((event) => {
                            // Check if the event is canceled
                            if (event.event.status === "canceled") {
                              return null; // Skip rendering canceled events
                            }

                            // Convert event start and end times to Date objects
                            const startTime = new Date(event.event.startTime);
                            const endTime = new Date(event.event.endTime);
                            const currentTime = new Date();

                            // Determine the status of the meeting
                            let status = "Upcoming";
                            let dotColor = "yellow";
                            if (
                              currentTime >= startTime &&
                              currentTime <= endTime
                            ) {
                              status = "In-Progress";
                              dotColor = "green";
                            } else if (currentTime > endTime) {
                              status = "Completed";
                              dotColor = "red";
                            }

                            return (
                              <div class="admin-meeting-info" key={event.id}>
                                <div class="vertical-line-green"></div>
                                <span style={{ marginTop: "0px !important" }}>
                                  Meeting with {event.invitee.name}
                                </span>
                                <div class="meeting-time">
                                  <span>
                                    <img
                                      className="me-2"
                                      src={videoIcon}
                                      alt=""
                                    />
                                    {event.event.integrateType} meeting
                                  </span>
                                  <span>
                                    <img
                                      className="mx-2"
                                      src={watchIcon}
                                      alt=""
                                    />
                                    {new Date(
                                      event.event.startTime
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}{" "}
                                    -{" "}
                                    {new Date(
                                      event.event.endTime
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </span>
                                </div>
                                <div class="meeting-profile d-flex justify-content-between mt-2 me-2">
                                  <div>
                                    <span>
                                      <img
                                        src={event.salesAgent.image}
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div>
                                    <div class={`status-dot ${dotColor}`}></div>
                                    <span
                                      style={{ fontSize: "12px" }}
                                      className="status-badge fw-bold">
                                      {status}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        )}
      </div>
      {showDeleteModal && <div className="overlay"></div>}
      <CustomModal
        isOpen={showDeleteModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to delete this Agent?"
        onConfirmDelete={() => confirmDelete(agentDetails.id)}
      />
    </>
  );
}

export default SalesAgentDetails;
