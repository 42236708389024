import React from "react";
import "./commonSection.css";
import { Col, Container, Row } from "react-bootstrap";

const CommonSection = ({ title }) => {
  return (
    <section className="common-section">
      <Container>
        <Row>
          <Col lg={12} style={{ paddingLeft: "0" }}>
            <h2>{title}</h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CommonSection;
