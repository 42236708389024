import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import closeIcon from "../../assets/images/buyers_close_btn.png";
// import CustomInput from "../Common/CustomInput";
import agent from "../../../agent";
import Loader from "../../../shared/Loader";
import CustomInput from "../../../shared/CustomInput";
import api from "../../../services/api";

const Buyers = ({ handleOpenBuyersDetail }) => {
  const fullAddress = {
    country: "",
    houseName: "",
    street: "",
    county: "",
    town_or_city: "",
  };
  const initialValues = {
    name: "",
    lastName: "",
    email: "",
    postCode: "",
    contact: "",
    ...fullAddress,
  };

  const [postCodeToggle, setPostCodetoggle] = useState(true);
  const [activeStates, setActiveStates] = useState([true, false]);
  const [formData, setFormData] = useState(initialValues);
  const [buyerFormData, setBuyerFormData] = useState(initialValues);
  const [submittedBy, setSubmittedBy] = useState(0);
  const [postCodeSuggestion, setPostCodeSuggestion] = useState([]);

  const [postCodeLoading, setPostCodeLoading] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // const currentUser = useSelector((state) => state.common.currentUser);
  const [postCodeSummary, setPostCodeSummary] = useState([]);

  const getPostCodeValue = async (postCode) => {
    try {
      setPostCodeLoading(true);
      let res = await api.getPostCodeSuggestion(postCode);
      if (res?.status) {
        setPostCodeSuggestion(res?.suggestions);
        setPostCodeLoading(false);
      }
    } catch (error) {
      setPostCodeLoading(false);
    }
  };

  // useEffect(() => {
  //   if (currentUser) {
  //     setFormData({
  //       ...formData,
  //       name: currentUser?.firstName,
  //       lastName: currentUser?.lastName,
  //       email: currentUser?.email,
  //       contact: currentUser?.phoneNumber?.number,
  //     });
  //   }
  // }, [currentUser]);

  const handlePostCode = () => {
    setPostCodetoggle(!postCodeToggle);
  };

  const handleClick = (idx) => {
    const newActiveStates = activeStates.map((state, i) => i === idx);
    setActiveStates(newActiveStates);
    setSubmittedBy(idx);
    setPostCodeSuggestion([]);
  };

  const handleFormData = async (e) => {
    const { name, value } = e.target;
    if (name == "contact") {
      if (value?.length > 11) return;
    }
    if (name === "postCode") {
      getPostCodeValue(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleBuyerFormData = (e) => {
    const { name, value } = e.target;
    if (name == "contact") {
      if (value?.length > 11) return;
    }
    if (name === "postCode") {
      getPostCodeValue(value);
    }
    setBuyerFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleClickNext = () =>{
  //     if(submittedBy === 0) {
  //         if (!formData.name || !formData.lastName || !formData.address || !formData.postCode || !formData.contact || !formData.email) {
  //             toast.error("Please fill in all required fields before proceeding.", {
  //                 position: toast.POSITION.TOP_RIGHT
  //             });
  //             return;
  //         }
  //         if (!emailRegex.test(formData.email)) {
  //             toast.error("Please enter a valid email address.", {
  //                 position: toast.POSITION.TOP_RIGHT
  //             });
  //             return;
  //         }
  //         const isValidPost = validateUKPostcode(formData.postCode)
  //         if(!isValidPost)  {
  //             toast.error("Post code is invalid", {
  //             position: toast.POSITION.TOP_RIGHT
  //         });
  //         return;
  //     }
  //         handleCheckout(formData,buyerFormData,submittedBy)
  //     }else{
  //         if (!emailRegex.test(buyerFormData.email)) {
  //             toast.error("Please enter a valid email address.", {
  //                 position: toast.POSITION.TOP_RIGHT
  //             });
  //             return;
  //         }
  //         const isValidPostCode = validateUKPostcode(buyerFormData.postCode)
  //         if(!isValidPostCode) {
  //             toast.error("Post code is invalid", {
  //                 position: toast.POSITION.TOP_RIGHT
  //             });

  //             return;
  //         }

  //         handleCheckout(formData,buyerFormData,submittedBy)
  //     }

  // }

  const handleClickNext = () => {
    const currentFormData = submittedBy === 0 ? formData : buyerFormData;
    // Check if all fields of formData are empty

    // Check if any required fields are empty
    for (const key in currentFormData) {
      if (!currentFormData[key]) {
        toast.error("Please fill in all required fields before proceeding.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Prevent further execution if any field is empty
      }
    }

    // Check for email validity
    if (!emailRegex.test(currentFormData.email)) {
      toast.error("Please enter a valid email address.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let formDataIsEmpty = false;
    if (submittedBy != 0) {
      for (const key in formData) {
        if (!formData[key]) {
          formDataIsEmpty = true;
          break;
        }
      }
    }

    if (formDataIsEmpty) {
      toast.error("Buyer One information is mandatory.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    // If all checks pass, proceed to the next step
    // handleCheckout(formData, buyerFormData, submittedBy);
  };

  // const handlePostCodeAddress = async (value) => {
  //   setPostCodeSummary([]);
  //   setPostCodetoggle(false);
  //   if(submittedBy === 0){
  //     setFormData({...formData , "country" : "UK" , "town_or_city":value?.posttown , "county":value?.county , "houseName":value?.premise  , "street":value?.street , "postCode":value?.postcode
  //   })

  //   }else{
  //     setBuyerFormData({...buyerFormData , "country" : "UK" , "town_or_city":value?.posttown , "county":value?.county , "houseName":value?.premise  , "street":value?.street , "postCode":value?.postcode
  //   })
  //   }

  // };

  const handlePostCodeAddress = async (id) => {
    setPostCodetoggle(false);
    setPostCodeSuggestion([]);

    try {
      let res = await api.getAddress(id);
      if (res.status) {
        const data = await res?.data;
        let street = await data?.thoroughfare;
        let houseName =
          data?.line_1?.length == 1
            ? data?.line_1 + " " + data?.line_2
            : data?.line_1;
        if (houseName && houseName !== street) {
          houseName = houseName.replace(street, " ");
        }

        if (submittedBy === 0) {
          setFormData({
            ...formData,
            country: data?.country,
            town_or_city: data?.town_or_city,
            county: data?.county,
            houseName: houseName,
            street: data?.thoroughfare,
            postCode: data?.postcode,
          });
        } else {
          setBuyerFormData({
            ...buyerFormData,
            country: data?.country,
            town_or_city: data?.town_or_city,
            county: data?.county,
            houseName: houseName,
            street: data?.thoroughfare,
            postCode: data?.postcode,
          });
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  // {
  //   postCodeSummary?.length > 0 && formData.postCode && (
  //     <>
  //      <div className="postcode-bar">
  //       {
  //         postCodeLoading ? <Loader/> :   <ul>
  //         {
  //           postCodeSummary?.map((value)=>{
  //             return   <li onClick={()=>handlePostCodeAddress(value)}>{value?.summaryline}</li>
  //           })
  //         }

  //       </ul>
  //       }

  // </div>

  //     </>
  //   )
  // }

  return (
    <Fragment>
      <div
        style={{ position: "fixed" }}
        className="overlay buyers-main-active"
      />
      <ToastContainer />
      <div className="buyers-main">
        <button
          className="closeBtn"
          onClick={() => handleOpenBuyersDetail(false)}
        >
          {/* <img src={closeIcon} alt="close btn" /> */}
          X
        </button>
        <h2>Add buyer’s details</h2>
        <div className="head">
          <p>
            <span
              className={activeStates[0] ? "active" : ""}
              onClick={() => handleClick(0)}
            >
              Buyer 1 <b>*</b>
            </span>
            <span
              className={activeStates[1] ? "active" : ""}
              onClick={() => handleClick(1)}
            >
              Buyer 2 (optional)
            </span>
          </p>
        </div>
        <div className="input-group">
          <div className="input-field input-pin">
            <div className="input-code">
              {/* <label>Full Name <span>{submittedBy === 0 ? '*' :''}</span></label> */}
              <CustomInput
                type={"text"}
                name="name"
                value={submittedBy === 0 ? formData.name : buyerFormData.name}
                label={"First name"}
                handleChange={
                  submittedBy === 0 ? handleFormData : handleBuyerFormData
                }
              />
            </div>
            <div className="input-code">
              {/* <label>Full Name <span>{submittedBy === 0 ? '*' :''}</span></label> */}
              <CustomInput
                type={"text"}
                name="lastName"
                value={
                  submittedBy === 0 ? formData.lastName : buyerFormData.lastName
                }
                label={"Last name"}
                handleChange={
                  submittedBy === 0 ? handleFormData : handleBuyerFormData
                }
              />
            </div>
          </div>

          <div className="input-field input-pin">
            <div className="input-code">
              {/* <label>Email Address <span>{submittedBy === 0 ? '*' :''}</span></label> */}
              <CustomInput
                type={"email"}
                name="email"
                value={submittedBy === 0 ? formData.email : buyerFormData.email}
                label={"Your email address"}
                handleChange={
                  submittedBy === 0 ? handleFormData : handleBuyerFormData
                }
              />
            </div>

            <div className="input-code">
              {/* <label>Contact Number <span>{submittedBy === 0 ? '*' :''}</span></label> */}
              <CustomInput
                name="contact"
                type="number"
                value={
                  submittedBy === 0 ? formData.contact : buyerFormData.contact
                }
                label={"Contact number"}
                handleChange={
                  submittedBy === 0 ? handleFormData : handleBuyerFormData
                }
              />
            </div>
          </div>

          <div className="address">
            <label htmlFor="" className="address-heading">
              Address
            </label>
            <div className="address-type">
              <ul>
                <li
                  onClick={handlePostCode}
                  className={`${postCodeToggle ? "active" : ""}`}
                >
                  Postcode Finder
                </li>
                <li
                  onClick={handlePostCode}
                  className={`${postCodeToggle ? "" : "active"}`}
                >
                  Enter Manually
                </li>
              </ul>
              {postCodeToggle ? (
                <div className="address-fields active">
                  <div className="input-code">
                    <CustomInput
                      name="postCode"
                      type="text"
                      value={
                        submittedBy === 0
                          ? formData.postCode
                          : buyerFormData.postCode
                      }
                      label={"Enter your postcode"}
                      handleChange={
                        submittedBy === 0 ? handleFormData : handleBuyerFormData
                      }
                    />
                  </div>
                  {postCodeSuggestion?.length > 0 && formData.postCode && (
                    <>
                      <div className="postcode-bar">
                        {postCodeLoading ? (
                          <Loader />
                        ) : (
                          <ul>
                            {postCodeSuggestion?.map((value) => {
                              return (
                                <li
                                  onClick={() =>
                                    handlePostCodeAddress(value?.id)
                                  }
                                >
                                  {value?.address.replace(/^(\d+),\s*/, "$1 ")}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="address-fields">
                  <div className="input-field input-pin">
                    <div className="input-code">
                      <CustomInput
                        type={"text"}
                        name="houseName"
                        value={
                          submittedBy === 0
                            ? formData.houseName
                            : buyerFormData.houseName
                        }
                        label={"House Name"}
                        handleChange={
                          submittedBy === 0
                            ? handleFormData
                            : handleBuyerFormData
                        }
                      />
                    </div>
                    <div className="input-code">
                      <CustomInput
                        name="street"
                        type="text"
                        value={
                          submittedBy === 0
                            ? formData.street
                            : buyerFormData.street
                        }
                        label={"Street"}
                        handleChange={
                          submittedBy === 0
                            ? handleFormData
                            : handleBuyerFormData
                        }
                      />
                    </div>
                  </div>
                  <div className="input-field input-pin">
                    <div className="input-code">
                      <CustomInput
                        type={"text"}
                        name="town_or_city"
                        value={
                          submittedBy === 0
                            ? formData.town_or_city
                            : buyerFormData.town_or_city
                        }
                        label={"Town/City"}
                        handleChange={
                          submittedBy === 0
                            ? handleFormData
                            : handleBuyerFormData
                        }
                      />
                    </div>
                    <div className="input-code">
                      <CustomInput
                        name="county"
                        type="text"
                        value={
                          submittedBy === 0
                            ? formData.county
                            : buyerFormData.county
                        }
                        label={"County"}
                        handleChange={
                          submittedBy === 0
                            ? handleFormData
                            : handleBuyerFormData
                        }
                      />
                    </div>
                  </div>
                  <div className="input-field input-pin">
                    <div className="input-code">
                      <CustomInput
                        type={"text"}
                        name="postCode"
                        value={
                          submittedBy === 0
                            ? formData.postCode
                            : buyerFormData.postCode
                        }
                        label={"Post Code"}
                        handleChange={
                          submittedBy === 0
                            ? handleFormData
                            : handleBuyerFormData
                        }
                      />
                    </div>
                    <div className="input-code">
                      <CustomInput
                        name="country"
                        type="text"
                        value={
                          submittedBy === 0
                            ? formData.country
                            : buyerFormData.country
                        }
                        label={"Country"}
                        handleChange={
                          submittedBy === 0
                            ? handleFormData
                            : handleBuyerFormData
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <button className="next-btn" onClick={() => handleClickNext()}>
            Next
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default Buyers;
