import React, { useEffect, useState } from "react";
import $ from "jquery";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
// import zoomLogo from "../../../assets/Images/company_logo.png";
import calendlyLogo from "../../../assets/Images/company.png";
import calendlyLogoOne from "../../../assets/Images/company_one.png";
// import zoomLogoOne from "../../../assets/Images/zoom.png";
import { toast } from "react-toastify";
import api from "../../../services/api";
import Loading from "../../../shared/Loading";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";

function AddSalesAgentDetails() {
  const [image, setImage] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  // const [shortBioWordCount, setShortBioWordCount] = useState(0);
  // const [expandedBioWordCount, setExpandedBioWordCount] = useState(0);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeCompany, setActiveCompany] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // Initialize with false
  const [activeTab, setActiveTab] = useState("tab1"); // Set the initial active tab
  const [activeCalendlyImage, setActiveCalendlyImage] = useState(true); // true indicates first image is active
  const [activeZoomImage, setActiveZoomImage] = useState(false); // false indicates second image is not active
  const [isActive, setIsActive] = useState(true); // Initialize with true to apply "active" class

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const handleShortBioChange = (e) => {
    const text = e.target.value;

    setAddAgent((prevAgent) => ({
      ...prevAgent,
      shortBio: text,
    }));
  };

  const handleExpandedBioChange = (e) => {
    const text = e.target.value;

    setAddAgent((prevAgent) => ({
      ...prevAgent,
      expendedBio: text,
    }));
  };

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    if (tabKey === "tab1") {
      setActiveCalendlyImage(true);
      setActiveZoomImage(false);
      setActiveCompany("zoom"); // Update activeCompany state
    } else if (tabKey === "tab2") {
      setActiveCalendlyImage(false);
      setActiveZoomImage(true);
      setActiveCompany("zoom"); // Update activeCompany state
    }
  };

  useEffect(() => {
    $(".agent-intregate ul li").on("click", function () {
      $(".agent-intregate ul li").removeClass("company-active");
      $(".company-logo-first, .company-logo-second").addClass("company-active");
      $(this).addClass("company-active");
    });
  });

  const navigate = useNavigate();

  const [addAgent, setAddAgent] = useState({
    calendlyLink: "",
    calendlyPassword: "",
    calendlyUserName: "",
    city: "",
    country: "",
    createdAt: new Date().toISOString(),
    email: "",
    expendedBio: "",
    firstName: "",
    lastName: "",
    location: "",
    shortBio: "",
    state: "",
    updatedAt: new Date().toISOString(),
    zoomLink: "",
    zoomPassword: "",
    zoomUserName: "",
  });

  // console.log("Sending agent data:", addAgent);
  const handleAddAgent = async (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;

    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }
    if (!addAgent.firstName) {
      displayError("First name Name is required.");
      return;
    }
    if (!addAgent.email) {
      displayError("email is required.");
      return;
    }
    if (!addAgent.location) {
      displayError("Location is required.");
      return;
    }
    if (!addAgent.shortBio) {
      displayError("Short Bio is required");
      return;
    }
    if (!addAgent.expendedBio) {
      displayError("Expended Bio is required");
      return;
    }
    if (
      !addAgent.calendlyUserName ||
      !emailRegex.test(addAgent.calendlyUserName)
    ) {
      displayError("Please enter a valid email address for calendly.");
      return;
    }
    if (!addAgent.calendlyPassword) {
      displayError("Calendly Password is required.");
      return;
    }
    if (!addAgent.calendlyLink) {
      displayError("Calendly Link is required.");
      return;
    }
    if (!image) {
      toast.error("Sales agent image is required.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    try {
      setIsSaving(true); // Show loading animation
      const response = await api.addAgent(addAgent, image);
      if (response) {
        setIsSuccessModalOpen(true);
        openSuccessModal(true);
      } else {
        alert("Error adding location");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
      setIsSaving(false); // Hide loading animation
    }
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/salesAgent/salesAgentList");
  };

  const onImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    setImage(selectedFile);
    setAddAgent((prevAgent) => ({
      ...prevAgent,
      image: selectedFile,
    }));
  };

  let imageUrl = null; // Initialize with null

  if (image) {
    imageUrl = URL.createObjectURL(image);
  }

  const clearImage = () => {
    setImage(null); // Reset image state
    setAddAgent((prevAgent) => ({
      ...prevAgent,
      image: null, // Clear image reference
    }));
  };

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/salesAgent"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Add Agent",
          ]}
        />
        <div className="blog-info-main">
          <div className="location-info-head">
            <h3>Agent information</h3>
          </div>
          <Container className="p-2 main_container" fluid>
            <Row className="mt-5">
              <Col md="3">
                <div class="agent-photo">
                  <div class="agent-upload">
                    {!image ? ( 
                      <div>
                        <input type="file" onChange={onImageChange} />
                        <div className="upload-icon">
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"></i>
                          <span>Upload photo</span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={imageUrl}
                          alt="Selected"
                          className="selected-image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            padding: "5px",
                            position: "absolute",
                            top: "0",
                            left: "0",
                          }}
                        />
                        <span
                          className="delete-icon-image"
                          onClick={clearImage}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="9">
                <Row>
                  <Col>
                    <div className="agent_input">
                      <label htmlFor="">First Name</label>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        value={addAgent.firstName}
                        onChange={(e) =>
                          setAddAgent((prevAgent) => ({
                            ...prevAgent,
                            firstName: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="agent_input">
                      <label htmlFor="">Last Name</label>
                      <input
                        type="text"
                        value={addAgent.lastName}
                        onChange={(e) =>
                          setAddAgent((prevAgent) => ({
                            ...prevAgent,
                            lastName: e.target.value,
                          }))
                        }
                        placeholder="Enter Name"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="agent_input">
                      <label htmlFor="">Email</label>
                      <input
                        type="email"
                        value={addAgent.email}
                        onChange={(e) =>
                          setAddAgent((prevAgent) => ({
                            ...prevAgent,
                            email: e.target.value,
                          }))
                        }
                        placeholder="Enter Email"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <div className="agent_input">
                      <label htmlFor="">Location</label>
                      <input
                        type="text"
                        value={addAgent.location}
                        onChange={(e) =>
                          setAddAgent((prevAgent) => ({
                            ...prevAgent,
                            location: e.target.value,
                          }))
                        }
                        placeholder="Enter Location"
                      />
                    </div>
                  </Col>
                </Row>
                <Col>
                  <Col className="mt-4">
                    <div className="agent_input">
                      <label htmlFor="">Short Bio</label>
                      <textarea
                        className="agent_bio"
                        value={addAgent.shortBio}
                        onChange={handleShortBioChange}
                        rows={3}
                        placeholder="Type something..."
                      />
                    </div>
                  </Col>
                  <Col className="mt-4">
                    <div className="agent_input">
                      <label htmlFor="">Expanded Bio</label>
                      <textarea
                        className="agent_bio"
                        value={addAgent.expendedBio}
                        onChange={handleExpandedBioChange}
                        rows={3}
                        placeholder="Type something..."
                      />
                    </div>
                  </Col>
                </Col>

                <div class="agent-intregate mt-5">
                  <h3>Integrate with:</h3>
                  <Tab.Container
                    activeKey={activeTab}
                    onSelect={handleTabSelect}>
                    <Row>
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="tab1"
                            className="nav_item"
                            activeClassName="nav_item">
                            <ul className="ps-0">
                              <li class="company-active">
                                <img src={calendlyLogoOne} alt="" />
                                <img
                                  class="company-logo-first"
                                  src={calendlyLogo}
                                  style={{ padding: "10px" }}
                                  alt=""
                                />
                              </li>
                            </ul>
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="tab2">
                            <ul className="ps-0">
                              <li class="">
                                <img src={zoomLogoOne} alt="" />
                                <img
                                  class="company-logo-first"
                                  src={zoomLogo}
                                  alt=""
                                />
                              </li>
                            </ul>
                          </Nav.Link>
                        </Nav.Item> */}
                        {/* Add more Nav.Items for other tabs */}
                      </Nav>
                    </Row>
                    <div
                      style={{
                        // display: "block",
                        marginTop: "100px",
                        marginLeft: "-198px",
                      }}>
                      <Tab.Content style={{ marginTop: "-1px" }}>
                        <Tab.Pane eventKey="tab1">
                          <Container
                            fluid
                            style={{ margin: "0px", padding: "0px" }}>
                            <Row className="mt-3" removeClass="active">
                              <Col
                                style={{ background: "#ffffff" }}
                                className="name_input"
                                activeClassName="nav_item">
                                <div>
                                  <label htmlFor="">Email</label>
                                </div>
                                <input
                                  type="text"
                                  value={addAgent.calendlyUserName} // Value from state
                                  onChange={(e) =>
                                    setAddAgent((prevAgent) => ({
                                      ...prevAgent,
                                      calendlyUserName: e.target.value, // Update the specific property
                                    }))
                                  }
                                  placeholder="abc@domain.com"
                                />
                              </Col>
                              <Col
                                className="name_input"
                                style={{
                                  background: "#ffffff",
                                  marginLeft: "-1px",
                                }}>
                                <div>
                                  <label
                                    htmlFor=""
                                    style={{ background: "none !important" }}>
                                    Password
                                  </label>
                                  <input
                                    type="text"
                                    value={addAgent.calendlyPassword} // Value from state
                                    onChange={(e) =>
                                      setAddAgent((prevAgent) => ({
                                        ...prevAgent,
                                        calendlyPassword: e.target.value, // Update the specific property
                                      }))
                                    }
                                    placeholder="******"
                                    style={{
                                      "::placeholder": { color: "red" },
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="p-0 name_input">
                              <div class="zoom-link mt-3 mb-5  me-0">
                                <label>Link</label>
                                <input
                                  type="text"
                                  value={addAgent.calendlyLink}
                                  onChange={(e) =>
                                    setAddAgent((prevAgent) => ({
                                      ...prevAgent,
                                      calendlyLink: e.target.value,
                                    }))
                                  }
                                  placeholder="https://calendly.com"
                                />
                              </div>
                            </Row>
                          </Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab2">
                          <Container
                            fluid
                            style={{ margin: "0px", padding: "0px" }}>
                            <Row className="mt-3">
                              <Col
                                className="name_input"
                                style={{
                                  background: "#ffffff",
                                  marginTop: "-1px !important",
                                }}>
                                <div className="">
                                  <label htmlFor="">Email</label>
                                  <input
                                    type="text"
                                    value={addAgent.zoomUserName}
                                    onChange={(e) =>
                                      setAddAgent((prevAgent) => ({
                                        ...prevAgent,
                                        zoomUserName: e.target.value,
                                      }))
                                    }
                                    placeholder="abc@domain.com"
                                  />
                                </div>
                              </Col>
                              <Col
                                className="name_input"
                                style={{
                                  background: "#ffffff",
                                  marginLeft: "-1px",
                                }}>
                                <div className="">
                                  <label htmlFor="">Password</label>
                                  <input
                                    type="text"
                                    value={addAgent.zoomPassword}
                                    onChange={(e) =>
                                      setAddAgent((prevAgent) => ({
                                        ...prevAgent,
                                        zoomPassword: e.target.value,
                                      }))
                                    }
                                    placeholder="******"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="p-0 name_input me-0">
                              <div class="zoom-link mt-3 mb-5">
                                <label>Link</label>
                                <input
                                  type="text"
                                  value={addAgent.zoomLink}
                                  onChange={(e) =>
                                    setAddAgent((prevAgent) => ({
                                      ...prevAgent,
                                      zoomLink: e.target.value,
                                    }))
                                  }
                                  placeholder="https://zoom.com"
                                />
                              </div>
                            </Row>
                          </Container>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="location-save-btn agent_save-button">
          <Link to={"/salesAgent"}>
            <button className="cancle-btn">Cancel</button>
          </Link>
          <button onClick={handleAddAgent} className="save-btn">
            Save
          </button>
        </div>
      </div>
      {isSaving && (
        <div className="overlay">
          <Loading />
        </div>
      )}{" "}
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Sales Agent has been added successfully"}
      />
    </>
  );
}

export default AddSalesAgentDetails;
