// PlotContext.js
import React, { createContext, useContext, useReducer } from "react";

const PlotContext = createContext();

export const PlotProvider = ({ children }) => {
  const initialState = {
    plotState: {
      selectedPlots: [],
    },
  };

  const plotReducer = (plotState, action) => {
    switch (action.type) {
      case "ADD_PLOT":
        return {
          // ...plotState,
          selectedPlots: [...plotState.plotState.selectedPlots, action.payload],
        };
      case "REMOVE_PLOT":
        return {
          ...plotState,
          selectedPlots: plotState.plotState.selectedPlots.filter(
            (plot) => plot.id !== action.payload
          ),
        };
      default:
        return plotState;
    }
  };

  const [plotState, dispatch] = useReducer(plotReducer, initialState);

  return (
    <PlotContext.Provider value={{ plotState, dispatch }}>
      {children}
    </PlotContext.Provider>
  );
};

export const usePlotContext = () => {
  const context = useContext(PlotContext);
  if (!context) {
    throw new Error("usePlotContext must be used within a PlotProvider");
  }
  return context;
};
