import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import "../../../styles/dashboard.css";
import { Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../shared/Loading";
import noData from "../../../assets/Images/NoData.svg";
import {
  useUserContext,
  setCurrentPage,
} from "../../../components/context/UserContext";
import client from "../../../services/Client";

function OrderList() {
  const [orders, setOrders] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState("");
  const [perPage] = useState(15);
  const { state, dispatch } = useUserContext();
  const [productType, setProductType] = useState("");
  const [debouncedProductType, setDebouncedProductType] = useState("");

  const token = state.token;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = state.currentPage;

  const fetchOrder = async () => {
    try {
      setIsLoading(true);

      const queryParams = [];

      if (productType) {
        queryParams.push(`productType=${encodeURIComponent(productType)}`);
        dispatch(setCurrentPage(1));
      }

      queryParams.push(`page=${currentPage}`);

      const queryString = queryParams.join("&");

      const response = await client.get(`admin/all-orders?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const orderData = response.data.data.orders;
      // console.log("order list", orderData);
      setTotal(response.data.data.total);
      setCurrentPage(response.data.data.currentPage);
      if (orderData) {
        orderData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setOrders(orderData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching order:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedProductType(productType);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [productType]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1;

    // Check if the current page is different from the one in the URL
    if (page !== currentPage) {
      dispatch(setCurrentPage(1));
    }
    fetchOrder();
  }, [debouncedProductType, location.search, currentPage, dispatch]);

  const handleClearInput = () => {
    setProductType("");
    setIsLoading(true);
    navigate(`/order?page=${currentPage}`);
  };

  const handlePageChange = (newPage) => {
    console.log("handlePageChange called with newPage:", newPage);
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      console.log("Setting currentPage to", newPage);
      dispatch({ type: "SET_CURRENT_PAGE", payload: newPage });
      navigate(`/order?page=${newPage}`);
    }
  };

  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading title={"Orders"} />
        <div className="loaction-list-main">
          <div className="loaction-list-head">
            <div className="search-bar">
              <input
                type="text"
                value={productType}
                placeholder="Search by product"
                onChange={(e) => setProductType(e.target.value)}
              />
              {productType && (
                <i
                  class="fa fa-times-circle clear-icon"
                  aria-hidden="true"
                  onClick={handleClearInput}
                  style={{ cursor: "pointer" }}></i>
              )}
              <i className="fa fa-search" aria-hidden="true"></i>
            </div>
          </div>
          {isLoading ? ( // Render the loader if isLoading is true
            <div className="overlay">
              <Loading />
            </div>
          ) : (
            <>
              <Table size="lg" className="location_list-table">
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    borderBottom: "1px solid #dcdada",
                  }}>
                  <tr>
                    <th style={{ padding: "-1px 0 0 15px" }}>Order ID</th>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Created On</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.length === 0 ||
                  (debouncedProductType &&
                    orders.every(
                      (order) =>
                        !order.productType
                          .toLowerCase()
                          .includes(debouncedProductType.toLowerCase())
                    )) ? (
                    <tr>
                      <td colSpan="6" className="text-center no_data-image">
                        {debouncedProductType
                          ? <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                          : "No Order found"}
                      </td>
                    </tr>
                  ) : (
                    orders.map((order) => (
                      <tr>
                        <td style={{ padding: "0 0 0 15px" }}>
                          {order.orderNumber}
                        </td>
                        <td>
                          <table>
                            <tr>
                              <td
                                class="product-img"
                                style={{ border: "none" }}>
                                <img
                                  src={order.imageURL}
                                  alt=""
                                  className="me-2"
                                />
                              </td>
                              <td class="rating" style={{ border: "none" }}>
                              {order.price === 1550 ? order.productType.slice(0, -1) : order.productType}
                                <p>{order.siteName}</p>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td>£{order.price.toFixed(2)}</td>
                        <td className="datetime-cell">
                          {new Date(order.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                          <br />
                          <span className="time text-muted">
                            {new Date(order.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </span>
                        </td>
                        <td>
                          {order.documentation.status ? (
                            <button className="status_button-order green">
                              Completed
                            </button>
                          ) : (
                            <button className="status_button-order yellow">
                              Pending
                            </button>
                          )}
                        </td>
                        <td>
                          <Link to={`/order/details/${order.id}`}>
                            <i
                              className="fa fa-eye me-2"
                              style={{ color: "#BDBDBD", cursor: "pointer" }}
                              aria-hidden="true"></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              <div className="next-previous-slide">
                <div className="slides-heading">
                  <span>
                    Showing {(currentPage - 1) * perPage + 1} to{" "}
                    {Math.min(currentPage * perPage, total)} out of {total}
                  </span>
                </div>

                <div className="slides-button">
                  <ul className="d-flex justify-center align-items-center">
                    <li>
                      <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}>
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                          style={{ cursor: "pointer", color: "#333" }}></i>
                      </button>
                    </li>
                    <li className="d-flex justify-center align-items-center">
                      {Array.from({ length: Math.ceil(total / perPage) }).map(
                        (_, index) => {
                          // Add logic to display ellipsis (...) if there are many pages
                          if (
                            index < 2 ||
                            index > Math.ceil(total / perPage) - 3 ||
                            (index >= currentPage - 2 &&
                              index <= currentPage + 1)
                          ) {
                            return (
                              <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={
                                  currentPage === index + 1 ? "active" : ""
                                }>
                                {index + 1}
                              </button>
                            );
                          } else if (
                            index === 2 ||
                            index === Math.ceil(total / perPage) - 3
                          ) {
                            // Display ellipsis (...) for the third and third-to-last position
                            return <span key={index}>...</span>;
                          }
                          return null;
                        }
                      )}
                    </li>
                    <li>
                      <button
                        disabled={currentPage === Math.ceil(total / perPage)}
                        onClick={() => handlePageChange(currentPage + 1)}>
                        <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                          style={{ cursor: "pointer" }}></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default OrderList;
