import React, { useEffect, useRef, useState } from "react";
import "./commonSection.css";
import {
  setAdminData,
  useUserContext,
} from "../components/context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import LogoutModal from "./LogoutModal";
import Loading from "./Loading";
import client from "../services/Client";

const LocationHeading = ({ title }) => {
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [admin, setAdmin] = useState([]);
  const { state, dispatch } = useUserContext();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const id = state.user.id;

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    setLogoutModalOpen(true);
  };
  const onConfirmLogout = () => {
    dispatch({ type: "LOGOUT" });
    setIsLoading(true);
    navigate("/login");
    setLogoutModalOpen(false);
    setIsLoading(false);
  };
  const cancelLogout = () => {
    setLogoutModalOpen(false);
  };

  const fetchAdmin = async () => {
    setIsLoading(true);
    try {
      const response = await client.get(`admin/get-admin-data/${id}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const adminData = response?.data?.admin;

      setAdmin(adminData);
      dispatch(setAdminData(adminData));
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/login");
      }

      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAdmin();
  }, [state.user.image]);

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }
  return (
    <div className="addlocation_header">
      <h2>{title}</h2>
      <div className="profile-dropdown-container" ref={dropdownRef}>
        {state.user && admin ? (
          <img src={admin.image} alt="" onClick={toggleDropdown} />
        ) : null}
        {dropdownOpen && (
          <div className="profile-dropdown">
            <div class="profile-info">
              <img src={admin.image} alt="Profile" />
              <h3 className="mt-2">
                {admin.firstName} {admin.lastName}
              </h3>
              <p className="user_email">{admin.email}</p>
            </div>
            <ul>
              <li>
                <Link
                  style={{ textDecoration: "none", color: "#000000" }}
                  to={"/settings"}>
                  <i className="fa fa-user me-2" aria-hidden="true"></i>My
                  Profile
                </Link>
              </li>
              <li onClick={handleLogout}>
                <i className="fa fa-sign-out me-2" aria-hidden="true"></i>
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
      {logoutModalOpen && <div className="overlay"></div>}
      <LogoutModal
        isOpen={logoutModalOpen}
        onClose={cancelLogout}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want logout?"
        onConfirmLogout={onConfirmLogout}
      />
    </div>
  );
};

export default LocationHeading;
