import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";
import { toast } from "react-toastify";

function EditPlot() {
  const [images, setImages] = useState([]);
  const [location, setLocation] = useState([]);
  const [data, setData] = useState({
    categories: [],
    phases: [],
    subPhases: [],
    types: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const { state } = useUserContext();

  const token = state.token;

  let navigate = useNavigate();

  const { id } = useParams();

  const [editPlot, setEditPlot] = useState({
    createdAt: new Date().toISOString(),
    bottomLeftLatitude: "",
    bottomLeftLongitude: "",
    bottomRightLatitude: "",
    bottomRightLongitude: "",
    category: "",
    description: "",
    images: [],
    locationId: "",
    phase: "",
    plotNumber: "",
    plotSize: "",
    price: "",
    siteName: "",
    subPhase: "",
    topLeftLatitude: "",
    topLeftLongitude: "",
    topRightLatitude: "",
    topRightLongitude: "",
    type: "",
    updatedAt: new Date().toISOString(),
  });

  useEffect(() => {
    loadPlot();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }

    if (!editPlot.siteName) {
      displayError("Site name is required.");
      return;
    }
    if (!editPlot.phase) {
      displayError("Phase is required.");
      return;
    }
    if (!editPlot.subPhase) {
      displayError("Subphase is required.");
      return;
    }
    if (!editPlot.plotNumber) {
      displayError("Enter a valid plot number.");
      return;
    }
    if (!editPlot.category) {
      displayError("Category is required.");
      return;
    }
    if (!editPlot.type) {
      displayError("Type is required.");
      return;
    }
    if (!editPlot.plotSize) {
      displayError("Enter a valid plot size.");
      return;
    }

    if (!editPlot.price) {
      displayError("Price is required.");
      return;
    }
    if (!editPlot.description) {
      displayError("Description is required.");
      return;
    }
    if (
      !editPlot.bottomLeftLatitude ||
      !editPlot.bottomLeftLongitude ||
      !editPlot.bottomRightLatitude ||
      !editPlot.bottomRightLongitude ||
      !editPlot.topLeftLatitude ||
      !editPlot.topLeftLongitude ||
      !editPlot.topRightLatitude ||
      !editPlot.topRightLongitude
    ) {
      displayError("Please enter all coordinates are required.");
      return;
    }
    if (images.length === 0) {
      toast.error("Please upload at least one image.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    setIsLoading(true); 
    try {
      const formData = new FormData();

      for (const key in editPlot) {
        formData.append(key, editPlot[key]);
      }

      const previousImagesUrl = [];
      images.forEach((img) => {
        if (img.file != null) {
          formData.append("images", img.file);
        } else {
          previousImagesUrl.push(img.url);
          formData.append("images", img.url);
        }
      });

      const updatedPlot = {
        ...editPlot,
        images: previousImagesUrl,
      };

      await api.updatePlot(id, formData, token);
      setIsSuccessModalOpen(true);
      openSuccessModal(true);
      setEditPlot(updatedPlot);
    } catch (error) {
      console.log(error);
    }
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/plots");
  };

  const loadPlot = async () => {
    setIsLoading(true);
    const response = await client.get(`admin/get-plot/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const loadedPlot = response.data.data;

    setEditPlot(() => ({
      createdAt: new Date().toISOString(),
      bottomLeftLatitude: loadedPlot.gpsCoordinates.bottomLeft[1],
      bottomLeftLongitude: loadedPlot.gpsCoordinates.bottomLeft[0],
      bottomRightLatitude: loadedPlot.gpsCoordinates.bottomRight[1],
      bottomRightLongitude: loadedPlot.gpsCoordinates.bottomRight[0],
      category: loadedPlot.category,
      description: loadedPlot.description,
      locationId: loadedPlot.locationId,
      phase: loadedPlot.phase,
      plotNumber: loadedPlot.plotNumber,
      plotSize: loadedPlot.plotSize,
      price: loadedPlot.price,
      siteName: loadedPlot.siteName,
      subPhase: loadedPlot.subPhase,
      topLeftLatitude: loadedPlot.gpsCoordinates.topLeft[1],
      topLeftLongitude: loadedPlot.gpsCoordinates.topLeft[0],
      topRightLatitude: loadedPlot.gpsCoordinates.topRight[1],
      topRightLongitude: loadedPlot.gpsCoordinates.topRight[0],
      type: loadedPlot.type,
      updatedAt: new Date().toISOString(),
    }));

    const loadedImages = loadedPlot.images;
    const imageArray = loadedImages.map((img) => ({ url: img }));
    setIsLoading(false);
    setImages(imageArray);
  };

  // FETCH MISCELLANEOUS DATA TO DISPLAY
  useEffect(() => {
    const miscData = async () => {
      try {
        const response = await client.get(`admin/get-data`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });
        console.log(response);
        setData(response.data.data);
        const locations = response.data.data.locations;
        setLocation(locations);
      } catch (error) {
        console.error("Error fetching plot:", error);
      }
    };
    miscData();
  }, []);

  const handleImageUpload = (event, index) => {
    const newImages = [...images];
    newImages[index] = {
      url: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    };
    setImages(newImages);
  };

  const handleImageDelete = async (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const renderImages = () => {
    if (images && images.length > 0) {
      return images.map((image, index) => (
        <div className="upload-img" key={index}>
          <div className="image-container">
            <img src={image.url} alt={`Uploaded ${index}`} />
            <span
              className="delete-icon-image"
              onClick={() => handleImageDelete(index)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      ));
    } else {
      return null; // No images to render
    }
  };

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/plots"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Edit Plot Details",
          ]}
        />
        <div className="add-info">
          <div className="info-heading">
            <h3>Plot information</h3>
          </div>
          {isLoading ? (
            <div className="overlay">
              <Loading />
            </div> // Show loading animation while loading
          ) : (
            <div className="info-all-details">
              <div className="site-location">
                <div className="input-field">
                  <label>Site Location/Name</label>
                  <select
                    value={editPlot.siteName}
                    onChange={(e) => {
                      const selectedSiteName = e.target.value;
                      const selectedLocation = location.find(
                        (loc) => loc.siteName === selectedSiteName
                      );

                      setEditPlot({
                        ...editPlot,
                        siteName: selectedSiteName,
                        locationId: selectedLocation ? selectedLocation.id : "",
                      });
                    }}>
                    <option value="">Site Location/Name</option>
                    {location.map((loc, index) => (
                      <option key={index} value={loc.siteName}>
                        {loc.siteName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-field">
                  <label>Phase</label>
                  <select
                    value={editPlot.phase}
                    onChange={(e) =>
                      setEditPlot({
                        ...editPlot,
                        phase: e.target.value,
                      })
                    }>
                    <option value="">Select a phase</option>
                    {data.phases.map((phase, index) => (
                      <option key={index} value={phase}>
                        {phase}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-field">
                  <label>Subphase</label>
                  <select
                    value={editPlot.subPhase}
                    onChange={(e) =>
                      setEditPlot({
                        ...editPlot,
                        subPhase: e.target.value,
                      })
                    }>
                    <option value="">Select a sub-phase</option>
                    {data.subPhases.map((subPhase, index) => (
                      <option key={index} value={subPhase}>
                        {subPhase}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-field">
                  <label>Plot Number</label>
                  <input
                    type="text"
                    placeholder="Enter number"
                    value={editPlot.plotNumber}
                    onChange={(e) =>
                      setEditPlot({
                        ...editPlot,
                        plotNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="site-location">
                <div className="input-field">
                  <label>Category</label>
                  <select
                    value={editPlot.category}
                    onChange={(e) =>
                      setEditPlot({
                        ...editPlot,
                        category: e.target.value,
                      })
                    }>
                    <option value="">Select a category</option>
                    {data.categories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-field">
                  <label>Type</label>
                  <select
                    value={editPlot.type}
                    onChange={(e) =>
                      setEditPlot({
                        ...editPlot,
                        type: e.target.value,
                      })
                    }>
                    <option value="">Select a type</option>
                    {data.types.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-field">
                  <label>Plot Size</label>
                  <input
                    type="text"
                    placeholder="Enter size"
                    value={editPlot.plotSize}
                    onChange={(e) =>
                      setEditPlot({
                        ...editPlot,
                        plotSize: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-field">
                  <label>Price</label>
                  <div className="for-euro">
                    <input
                      type="number"
                      placeholder="Enter  price"
                      value={editPlot.price}
                      onChange={(e) =>
                        setEditPlot({
                          ...editPlot,
                          price: e.target.value,
                        })
                      }
                    />
                    <i className="fa fa-pound-sign" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div className="discription">
                <label>Description</label>
                <textarea
                  placeholder="Type something..."
                  value={editPlot.description}
                  onChange={(e) =>
                    setEditPlot({
                      ...editPlot,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div className="plot-address-main">
                <div className="plot-gps">
                  <div className="plot-gps-head">
                    <span>GPS Coordinates</span>
                  </div>
                  <div className="gps_boxes">
                    <div className="gps-box">
                      <label>Top Left</label>
                      <input
                        type="number"
                        placeholder="Long"
                        value={editPlot.topLeftLongitude}
                        onChange={(e) =>
                          setEditPlot({
                            ...editPlot,
                            topLeftLongitude: e.target.value,
                          })
                        }
                      />
                      <input
                        type="number"
                        placeholder="Lat"
                        value={editPlot.topLeftLatitude}
                        onChange={(e) =>
                          setEditPlot({
                            ...editPlot,
                            topLeftLatitude: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="gps-box2">
                      <label>Top Right</label>
                      <input
                        type="number"
                        placeholder="Long"
                        value={editPlot.topRightLongitude}
                        onChange={(e) =>
                          setEditPlot({
                            ...editPlot,
                            topRightLongitude: e.target.value,
                          })
                        }
                      />
                      <input
                        type="number"
                        placeholder="Lat"
                        value={editPlot.topRightLatitude}
                        onChange={(e) =>
                          setEditPlot({
                            ...editPlot,
                            topRightLatitude: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="gps_boxes mt-4">
                    <div className="gps-box">
                      <label>Bottom Left</label>

                      <input
                        type="number"
                        placeholder="Long"
                        value={editPlot.bottomLeftLongitude}
                        onChange={(e) =>
                          setEditPlot({
                            ...editPlot,
                            bottomLeftLongitude: e.target.value,
                          })
                        }
                      />
                      <input
                        type="number"
                        placeholder="Lat"
                        value={editPlot.bottomLeftLatitude}
                        onChange={(e) =>
                          setEditPlot({
                            ...editPlot,
                            bottomLeftLatitude: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="gps-box2">
                      <label>Bottom Right</label>

                      <input
                        type="number"
                        placeholder="Long"
                        value={editPlot.bottomRightLongitude}
                        onChange={(e) =>
                          setEditPlot({
                            ...editPlot,
                            bottomRightLongitude: e.target.value,
                          })
                        }
                      />
                      <input
                        type="number"
                        placeholder="Lat"
                        value={editPlot.bottomRightLatitude}
                        onChange={(e) =>
                          setEditPlot({
                            ...editPlot,
                            bottomRightLatitude: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="upload-img-main">
                <div className="img-head">
                  <label>
                    Plot images <span>( Max 5 images )</span>
                  </label>
                </div>
                <div className="image-uploader">
                  {images.length < 5 && (
                    <div className="upload-img">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, images.length)}
                      />
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </div>
                  )}
                  {renderImages()}
                </div>
              </div>
            </div>
          )}
          <div className="location-save-btn mb-5">
            <Link to={"/plots/plotlist"}>
              <button className="cancle-btn my-5">Cancel</button>
            </Link>
            <button onClick={onSubmit} className="save-btn my-5">
              Save
            </button>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="overlay">
          <Loading />
        </div>
      )}{" "}
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage="Success!"
        ModalMessage="The plot details have been successfully updated."
      />
    </>
  );
}

export default EditPlot;
