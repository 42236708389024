import React, { useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
// import AddPlot from "./AddPlot";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Modal, Table } from "react-bootstrap";
import "../../../styles/dashboard.css";
import CustomModal from "../../../shared/CustomModal";
import noData from "../../../assets/Images/NoData.svg";
import {
  useUserContext,
  setCurrentPage,
} from "../../../components/context/UserContext";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/themes/base/all.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { toast } from "react-toastify";
import client from "../../../services/Client";

function ParentPlot() {
  const [plots, setPlots] = useState([]);
  const [total, setTotal] = useState("");
  const [plotNumber, setPlotNumber] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [perPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [plotToDelete, setPlotToDelete] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isAvailable, setIsAvailable] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [debouncedPlotNumber, setSebouncedPlotNumber] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);

  const { state, dispatch } = useUserContext();

  const currentDate = new Date();
  const currentPage = state.currentPage;

  const navigate = useNavigate();
  const location = useLocation();

  const openModal = () => {
    setShowModal(true);
    setShowOverlay(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowOverlay(false);
    document.body.style.overflow = "auto";
  };

  const fetchPlots = async () => {
    setIsLoading(true);

    const queryParams = [];

    if (plotNumber) {
      queryParams.push(`plotNumber=${encodeURIComponent(plotNumber)}`);
      dispatch(setCurrentPage(1));
    }
    const formattedFromDate = fromDate
      ? format(new Date(fromDate), "MM-dd-yyyy")
      : "";
    const formattedToDate = toDate
      ? format(new Date(toDate), "MM-dd-yyyy")
      : "";

    if (formattedFromDate) {
      queryParams.push(`fromDate=${encodeURIComponent(formattedFromDate)}`);
    }

    if (formattedToDate) {
      queryParams.push(`toDate=${encodeURIComponent(formattedToDate)}`);
    }
    if (isAvailable) {
      queryParams.push(
        `isAvailable=${encodeURIComponent(isAvailable.join(","))}`
      );
    }

    queryParams.push(`page=${currentPage}`);

    if (selectedCategory) {
      queryParams.push(
        `category=${encodeURIComponent(selectedCategory.join(","))}`
      );
    }

    const queryString = queryParams.join("&");

    try {
      const response = await client.get(
        `admin/get-plots?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        },
        {
          params: {
            plotNumber: debouncedPlotNumber,
            perPage: perPage,
          },
        }
      );
      const plotData = response.data.data.plotRes;
      // console.log("plots are", response.data.data);

      if (plotData) {
        plotData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPlots(plotData);
      }
      setNoDataFound(plotData);

      setTotal(response.data.data.total);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching plots:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSebouncedPlotNumber(plotNumber);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [plotNumber]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1;
    if (page !== currentPage) {
      dispatch(setCurrentPage(1));
    }
    fetchPlots();
  }, [debouncedPlotNumber, location.search, currentPage, dispatch]);

  const applyFilters = () => {
    if (fromDate > toDate) {
      toast.error("From date can not be greater than to date.");
      openModal();
    }
    dispatch(setCurrentPage(1));
    navigate(`/plots?page=${currentPage}`);

    const isAvailableFilterApplied = isAvailable.length > 0;
    const anyFilterApplied =
      isAvailableFilterApplied ||
      selectedCategory.length > 0 ||
      fromDate ||
      toDate;

    // Set filtersApplied based on whether any filter is applied
    setFiltersApplied(anyFilterApplied);
    fetchPlots();
    closeModal();
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      dispatch({ type: "SET_CURRENT_PAGE", payload: newPage });
      navigate(`/plots?page=${newPage}`);
    }
  };

  // console.log("plots are", plots);

  const handleCategoryChange = (categoryValue) => {
    setSelectedCategory((prevSelectedCategories) => {
      if (prevSelectedCategories.includes(categoryValue)) {
        return prevSelectedCategories.filter(
          (category) => category !== categoryValue
        );
      } else {
        return [...prevSelectedCategories, categoryValue];
      }
    });
  };
  const handleStatusChange = (statusValue) => {
    setIsAvailable((prevIsAvailable) => {
      if (prevIsAvailable.includes(statusValue)) {
        return prevIsAvailable.filter((status) => status !== statusValue);
      } else {
        return [...prevIsAvailable, statusValue];
      }
    });
  };

  const resetFilters = () => {
    setFromDate("");
    setToDate("");
    setSelectedCategory([]);
    setIsAvailable([]);
    setFiltersApplied(false);
    dispatch(setCurrentPage(1));
  };
  const handleClearInput = () => {
    setPlotNumber("");
    setIsLoading(true);
    navigate(`/plots?page=${currentPage}`);
  };

  // DELETE API IMPLEMENTATION

  const handleDelete = (plotId) => {
    const plot = plots.find((plot) => plot.id === plotId);
    setPlotToDelete(plot);
    setShowDeleteModal(true);
    setShowOverlay(true);
  };

  const confirmDelete = async (plotId) => {
    try {
      setIsLoading(true);
      await client.put(`admin/delete-plot/${plotId}?isDeleted=true`, null, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setPlots((prevPlots) => prevPlots.filter((plot) => plot.id !== plotId));
      setShowDeleteModal(false);
      setShowOverlay(false);
      setIsLoading(false);
      fetchPlots();
    } catch (error) {
      console.error("Error deleting plots:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false);
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading title={"Plots"} />
        <div class="loaction-list-main">
          <div class="loaction-list-head">
            <div class="search-bar">
              <input
                type="text"
                value={plotNumber}
                placeholder="Search by plot no."
                onChange={(e) => setPlotNumber(e.target.value)}
              />
              {plotNumber && (
                <i
                  class="fa fa-times-circle clear-icon"
                  aria-hidden="true"
                  onClick={handleClearInput}
                  style={{ cursor: "pointer" }}></i>
              )}
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <div class="add-location">
              {filtersApplied ? <div className="red-dot"></div> : null}
              <i
                class="ri-filter-3-line me-2 filter_icon"
                onClick={openModal}></i>
              {/* <button>
                <Link to={"/plots/addPlotDetails"}>
                  <i class="fa fa-plus me-2" aria-hidden="true"></i>
                  Add Plot
                </Link>
              </button>*/}
            </div>
          </div>
        </div>

        <div>
          <Table size="lg" className="location_list-table table">
            <thead>
              <tr>
                <th>Site Name</th>
                <th>Category</th>
                <th>Type</th>
                <th>Plot No.</th>
                <th>Size(m2)</th>
                <th>Price</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {plots && plots.length > 0 ? (
                plots.map((plot) => (
                  <tr>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td
                              className="product-img"
                              style={{ border: "none" }}>
                              {plot.images && plot.images.length > 0 ? (
                                <img
                                  src={plot.images[0]}
                                  alt=""
                                  className="me-2"
                                />
                              ) : (
                                <span>No Image</span>
                              )}
                            </td>
                            <td
                              className="rating"
                              style={{
                                border: "none",
                                textTransform: "capitalize",
                              }}>
                              {plot.siteName}
                              <p>{plot.plotCode}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      {plot.type === "Single Plot"
                        ? plot.category.slice(0, -1)
                        : plot.category}
                    </td>
                    <td>{plot.type}</td>
                    <td>{plot.plotNumber.join(", ")}</td>
                    <td>{plot.plotSize}</td>
                    <td>£{plot.price.toFixed(2)}</td>
                    <td>
                      {plot.isAvailable ? (
                        <button className="status_button available">
                          Available
                        </button> // Check the status of the plot
                      ) : (
                        <button className="status_button sold">Sold</button> // Red button for sold
                      )}
                    </td>
                    <td>
                      <Dropdown className="details_dropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                          <button>
                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                          </button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <Link to={`/plots/newPlotDetail/${plot.id}`}>
                              <i
                                className="fa fa-eye me-2"
                                style={{ color: "yellow" }}
                                aria-hidden="true"></i>
                              View Details
                            </Link>
                          </Dropdown.Item>
                          {/*
                          {plot.isAvailable === true ? (
                            <div>
                              <Dropdown.Item>
                                <Link
                                  to={`/plots/editplot/${plot.id}`}
                                  style={{ color: "#000000" }}>
                                  <i
                                    class="ri-pencil-line me-2"
                                    style={{ color: "green" }}
                                    aria-hidden="true"></i>
                                  Edit
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link onClick={() => handleDelete(plot.id)}>
                                  <i
                                    className="fa fa-trash me-2"
                                    style={{ color: "red" }}
                                    aria-hidden="true"></i>
                                  Delete
                                </Link>
                              </Dropdown.Item>
                            </div>
                          ) : null} */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center no_data-image">
                    <img
                      src={noData}
                      alt="No Data"
                      width={"100%"}
                      height="100%"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="next-previous-slide">
            <div className="slides-heading">
              <span>
                Showing {(currentPage - 1) * perPage + 1} to{" "}
                {Math.min(currentPage * perPage, total)} out of {total}
              </span>
            </div>

            <div className="slides-button">
              <ul className="d-flex justify-center align-items-center">
                <li>
                  <button
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}>
                    <i
                      className="fa fa-angle-left"
                      aria-hidden="true"
                      style={{ cursor: "pointer", color: "#333" }}></i>
                  </button>
                </li>
                <li className="d-flex justify-center align-items-center">
                  {Array.from({ length: Math.ceil(total / perPage) }).map(
                    (_, index) => {
                      // Add logic to display ellipsis (...) if there are many pages
                      if (
                        index < 2 ||
                        index > Math.ceil(total / perPage) - 3 ||
                        (index >= currentPage - 2 && index <= currentPage + 1)
                      ) {
                        return (
                          <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={
                              currentPage === index + 1 ? "active" : ""
                            }>
                            {index + 1}
                          </button>
                        );
                      } else if (
                        index === 2 ||
                        index === Math.ceil(total / perPage) - 3
                      ) {
                        // Display ellipsis (...) for the third and third-to-last position
                        return <span key={index}>...</span>;
                      }
                      return null;
                    }
                  )}
                </li>
                <li>
                  <button
                    disabled={currentPage === Math.ceil(total / perPage)}
                    onClick={() => handlePageChange(currentPage + 1)}>
                    <i
                      className="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Modal
          show={showModal}
          onHide={closeModal}
          dialogClassName="modal-right" // Custom CSS class for styling the modal
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="filter-status">
              <span>Status</span>
              <div class="input-group">
                <input
                  type="checkbox"
                  className="me-2"
                  checked={isAvailable.includes(true)}
                  onChange={() => handleStatusChange(true)}
                />
                <label>Available</label>
              </div>
              <div class="input-group">
                <input
                  type="checkbox"
                  className="me-2"
                  checked={isAvailable.includes(false)}
                  onChange={() => handleStatusChange(false)}
                />
                <label>Sold</label>
              </div>
            </div>
            <div class="filter-status">
              <span>Category</span>
              <div class="input-group">
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedCategory.includes("Burial Plots")}
                  onChange={() => handleCategoryChange("Burial Plots")}
                />

                <label>Burial Plots</label>
              </div>
              <div class="input-group">
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedCategory.includes("Urn Plots")}
                  onChange={() => handleCategoryChange("Urn Plots")}
                />
                <label>Urn Plots</label>
              </div>
            </div>
            <div class="filter-status pb-0">
              <span>Created At</span>
            </div>
            <div class="filter-date">
              <div class="input-group">
                <label>from</label>
                <div class="input-date" style={{ position: "relative" }}>
                  <DatePicker
                    selected={fromDate || currentDate}
                    value={fromDate}
                    onChange={(fromDate) => setFromDate(fromDate)}
                    placeholderText="MM-DD-YYYY"
                    dateFormat="MM-dd-yyyy"
                    maxDate={currentDate}
                  />
                </div>
              </div>
              <div class="input-group">
                <label>To</label>
                <DatePicker
                  selected={toDate}
                  value={toDate}
                  onChange={(toDate) => setToDate(toDate)}
                  placeholderText="MM-DD-YYYY"
                  dateFormat="MM-dd-yyyy"
                  maxDate={currentDate}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="model_button">
              <button className="reset-btn" onClick={resetFilters}>
                Reset
              </button>
              <button className="close-btn" onClick={applyFilters}>
                Apply
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      {showDeleteModal && <div className="overlay"></div>}
      <CustomModal
        isOpen={showDeleteModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to delete this plot?"
        onConfirmDelete={() => confirmDelete(plotToDelete.id)}
      />
    </>
  );
}

export default ParentPlot;
