import React, { useEffect, useState } from "react";
import AddBlog from "./AddBlog";
import Loading from "../../../shared/Loading";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Dropdown, Table } from "react-bootstrap";
import noData from "../../../assets/Images/NoData.svg";
import CustomModal from "../../../shared/CustomModal";
import UnpublishModal from "../../../shared/UnpublishModal";
import {
  useUserContext,
  setCurrentPage,
} from "../../../components/context/UserContext";
import PublishModal from "../../../shared/PublishModal";
import client from "../../../services/Client";

function ParentBlog() {
  const [blogs, setBlogs] = useState([]);
  const [total, setTotal] = useState("");
  const [perPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState(null);
  const [blogToUnpublish, setBlogToUnpublish] = useState(null);
  const [blogPublish, setBlogPublish] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [title, setTitle] = useState("");
  const [debouncedTitle, setDebouncedTitle] = useState("");
  const { state, dispatch } = useUserContext();
  

  const currentPage = state.currentPage;
  const navigate = useNavigate();
  const location = useLocation();

  const fetchBlogs = async () => {
    setIsLoading(true);

    const queryParams = [];

    if (title) {
      queryParams.push(`title=${encodeURIComponent(title)}`);
      dispatch(setCurrentPage(1));
    }

    queryParams.push(`page=${currentPage}`);

    const queryString = queryParams.join("&");

    try {
      const response = await client.get(
        `admin/get-blogs?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        },
        {
          params: {
            title: debouncedTitle,
            perPage: perPage,
          },
        }
      );

      const blogData = response.data.data.BlogsRes;

      setTotal(response.data.data.total);

      if (blogData) {
        blogData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setBlogs(blogData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTitle(title); // Update the debounced value after the delay
    }, 1000); // Adjust the delay time as needed (e.g., 300ms)

    // Clear the timer if siteName changes again before the delay completes
    return () => {
      clearTimeout(timerId);
    };
  }, [title]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1;

    // Check if the current page is different from the one in the URL
    if (page !== currentPage) {
      dispatch(setCurrentPage(1));
    }
    fetchBlogs();
  }, [debouncedTitle, location.search, currentPage, dispatch]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      // Dispatch the action to set the current page
      dispatch({ type: "SET_CURRENT_PAGE", payload: newPage });
      navigate(`/blog?page=${newPage}`);
    }
  };

  // DELETE API IMPLEMENTATION

  const handleDelete = (blogId) => {
    const blog = blogs.find((blog) => blog.id === blogId);
    setBlogToDelete(blog);
    setShowDeleteModal(true);
    setShowOverlay(true);
  };

  const confirmDelete = async (blogId) => {
    try {
      setIsLoading(true);
      await client.put(`admin/delete-blog/${blogId}?isDeleted=true`, null, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== blogId));
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowPublishModal(false);
    setShowOverlay(false); // Hide the overlay
  };

  const handleClearInput = () => {
    setTitle("");
    setIsLoading(true);
    navigate(`/blog?page=${currentPage}`);
  };

  // Unpublish Blog

  const handleUnpublish = (blogId) => {
    const blog = blogs.find((blog) => blog.id === blogId);
    setBlogToUnpublish(blog);
    setShowUnpublishModal(true);
    setShowOverlay(true);
  };

  const handlePublish = (blogId) => {
    const blog = blogs.find((blog) => blog.id === blogId);
    setBlogPublish(blog);
    setShowPublishModal(true);
    setShowOverlay(true);
  };

  const confirmUnpublish = async (blogId) => {
    try {
      setIsLoading(true);
      await client.put(`admin/toggle-blog-status/${blogId}`, null, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      // Update the blogs in state by setting the status to "draft"
      setBlogs((prevBlogs) =>
        prevBlogs.map((blog) =>
          blog.id === blogId ? { ...blog, status: "draft" } : blog
        )
      );
      setShowUnpublishModal(false);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
    } catch (error) {
      console.error("Error un-publishing blog:", error);
    }
  };
  const confirmPublish = async (blogId) => {
    try {
      setIsLoading(true);
      await client.put(`admin/toggle-blog-status/${blogId}`, null, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      // Update the blogs in state by setting the status to "draft"
      setBlogs((prevBlogs) =>
        prevBlogs.map((blog) =>
          blog.id === blogId ? { ...blog, status: "published" } : blog
        )
      );
      setShowPublishModal(false);
      setShowOverlay(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error un-publishing blog:", error);
    }
  };
  const cancelPublish = () => {
    setShowUnpublishModal(false);
    setShowPublishModal(false);
    setShowOverlay(false);
  };

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    } else {
      return str.slice(0, num) + "...";
    }
  }

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  if (blogs && blogs.length > 0) {
    return (
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading title={"Blogs"} />
        <div class="loaction-list-main">
          <div class="loaction-list-head">
            <div class="search-bar">
              <input
                type="text"
                value={title}
                placeholder="Search by title"
                onChange={(e) => setTitle(e.target.value)}
              />
              {title && (
                <i
                  class="fa fa-times-circle clear-icon"
                  aria-hidden="true"
                  onClick={handleClearInput}
                  style={{ cursor: "pointer" }}></i>
              )}
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <div class="add-location">
              {/* <i
                class="ri-filter-3-line me-2 filter_icon"
                onClick={openModal}></i> */}
              <button>
                <Link to={"/blog/addBlogDetails"}>
                  <i class="fa fa-plus me-2" aria-hidden="true"></i>
                  Add Blog
                </Link>
              </button>
            </div>
          </div>
        </div>
        {isLoading ? ( // Render the loader if isLoading is true
          <div className="overlay">
            <Loading />
          </div>
        ) : (
          <div>
            <Table size="lg" className="location_list-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Created On</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {blogs.length === 0 ||
                (debouncedTitle &&
                  blogs.every(
                    (blog) =>
                      !blog.title
                        .toLowerCase()
                        .includes(debouncedTitle.toLowerCase())
                  )) ? (
                  <tr>
                    <td colSpan="6" className="text-center  no_data-image">
                      {debouncedTitle ? (
                        <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                      ) : (
                        "No Blogs found"
                      )}
                    </td>
                  </tr>
                ) : (
                  blogs.map((blog, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <img src={blog.bannerImage} alt="kendel" />
                          <div className="ms-2">
                            {truncateString(blog.title, 60)}
                          </div>
                        </div>
                      </td>
                      <td>{blog.categories}</td>
                      <td className="datetime-cell">
                        {new Date(blog.createdAt).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                        <br />
                        <span className="time text-muted">
                          {new Date(blog.createdAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </span>
                      </td>
                      <td>
                        <button
                          className={`status_button ${
                            blog.status === "published" ? "published" : "draft"
                          }`}>
                          {blog.status === "published" ? "Published" : "Draft"}
                        </button>
                      </td>
                      <td>
                        <Dropdown className="details_dropdown">
                          <Dropdown.Toggle id="dropdown-basic">
                            <button>
                              <i
                                class="fa fa-ellipsis-h"
                                aria-hidden="true"></i>
                            </button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link to={`/blog/blogDetails/${blog.id}`}>
                                <i
                                  className="fa fa-eye me-2"
                                  style={{ color: "yellow" }}
                                  aria-hidden="true"></i>
                                View Details
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={`/blog/editBlog/${blog.id}`}
                                style={{ color: "#000000" }}>
                                <i
                                  class="ri-pencil-line me-2"
                                  style={{ color: "green" }}
                                  aria-hidden="true"></i>
                                Edit
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link onClick={() => handleDelete(blog.id)}>
                                <i
                                  className="fa fa-trash me-2"
                                  style={{ color: "red" }}
                                  aria-hidden="true"></i>
                                Delete
                              </Link>
                            </Dropdown.Item>
                            {blog.status === "published" ? (
                              <Dropdown.Item>
                                <Link onClick={() => handleUnpublish(blog.id)}>
                                  <i
                                    className="fa fa-eye-slash me-2"
                                    style={{ color: "grey" }}
                                    aria-hidden="true"></i>
                                  Un-Publish
                                </Link>
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item>
                                <Link onClick={() => handlePublish(blog.id)}>
                                  <i
                                    className="fa fa-upload me-2"
                                    style={{ color: "blue" }}
                                    aria-hidden="true"></i>
                                  Publish
                                </Link>
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <div className="next-previous-slide">
              <div className="slides-heading">
                <span>
                  Showing {(currentPage - 1) * perPage + 1} to{" "}
                  {Math.min(currentPage * perPage, total)} out of {total}
                </span>
              </div>

              <div className="slides-button">
                <ul className="d-flex justify-center align-items-center">
                  <li>
                    <button
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}>
                      <i
                        className="fa fa-angle-left"
                        aria-hidden="true"
                        style={{ cursor: "pointer", color: "#333" }}></i>
                    </button>
                  </li>
                  <li className="d-flex justify-center align-items-center">
                    {Array.from({ length: Math.ceil(total / perPage) }).map(
                      (_, index) => {
                        // Add logic to display ellipsis (...) if there are many pages
                        if (
                          index < 2 ||
                          index > Math.ceil(total / perPage) - 3 ||
                          (index >= currentPage - 2 && index <= currentPage + 1)
                        ) {
                          return (
                            <button
                              key={index + 1}
                              onClick={() => handlePageChange(index + 1)}
                              className={
                                currentPage === index + 1 ? "active" : ""
                              }>
                              {index + 1}
                            </button>
                          );
                        } else if (
                          index === 2 ||
                          index === Math.ceil(total / perPage) - 3
                        ) {
                          // Display ellipsis (...) for the third and third-to-last position
                          return <span key={index}>...</span>;
                        }
                        return null;
                      }
                    )}
                  </li>
                  <li>
                    <button
                      disabled={currentPage === Math.ceil(total / perPage)}
                      onClick={() => handlePageChange(currentPage + 1)}>
                      <i
                        className="fa fa-angle-right"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {showDeleteModal && <div className="overlay"></div>}
        <CustomModal
          isOpen={showDeleteModal}
          onClose={cancelDelete}
          SuccessMessage="Please confirm!"
          ModalMessage="Are you sure you want to delete this Blog?"
          onConfirmDelete={() => confirmDelete(blogToDelete.id)}
        />
        {showUnpublishModal && <div className="overlay"></div>}
        <UnpublishModal
          isOpen={showUnpublishModal}
          onClose={cancelPublish}
          SuccessMessage="Please confirm!"
          ModalMessage="Are you sure you want to un-publish this Blog?"
          onConfirmUnpublish={() => confirmUnpublish(blogToUnpublish.id)}
        />
        <PublishModal
          isOpen={showPublishModal}
          onClose={cancelDelete}
          SuccessMessage="Please confirm!"
          ModalMessage="Are you sure you want to Publish this Blog?"
          onConfirmPublish={() => confirmPublish(blogPublish.id)}
        />
      </div>
    );
  } else {
    return <AddBlog />;
  }
}

export default ParentBlog;
