import React, { useEffect, useState } from "react";
import $ from "jquery";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { MdCheck } from "react-icons/md";
import Loading from "../../../shared/Loading";
import pdfImage from "../../../assets/Images/pdfimg.png";
import userImg from "../../../assets/Images/Vector.png";
import email from "../../../assets/Images/email.png";
import post from "../../../assets/Images/post.png";
import address from "../../../assets/Images/entypo_address.png";
import phone from "../../../assets/Images/phone.png";
import { toast } from "react-toastify";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";
import DocumentModal from "../../../shared/DocumentModal";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";

$(".selectable-img").on("click", function (event) {
  event.preventDefault();
  $(".selectable-img li").removeClass("selected");
  $(this).parent().addClass("selected");
  var imageUrl = $(this).find("img").attr("src");
  $(".view-img img").attr("src", imageUrl);
});

function OrderDetails() {
  const { id } = useParams();
  const [orderDetail, setOrderDetail] = useState({});
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("Buyer 1");
  const [orderToComplete, setOrderToComplete] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showDocsModal, setShowDocsModal] = useState(false);
  const [uploadedPdfUrls, setUploadedPdfUrls] = useState([]);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const { state } = useUserContext();

  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // setIsLoading(true);
  };

  // Handle the Go Back button click
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const token = state.token;

  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const response = await client.get(`admin/get-order/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const orderData = response.data.data;
      setOrderDetail(orderData);
      console.log("order data :", orderData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]);

  const showNoDocumentsErrorToast = () => {
    toast.error("Please upload at least one document.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // Auto-close the toast after 3 seconds
    });
  };

  const uploadDocuments = () => {
    setShowDocsModal(true);
    setShowOverlay(true);
    handleSaveDocuments();
  };

  const handleSaveDocuments = async () => {
    try {
      setIsLoading(true);
      if (uploadedFiles.length === 0) {
        showNoDocumentsErrorToast();
        setIsLoading(false);
        setShowOverlay(false);
        return;
      }
      // Create a form data object to send files
      const formData = new FormData();
      uploadedFiles.forEach((file, index) => {
        formData.append("documents", file);
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Required for file uploads
        },
      };
      const response = await client.post(
        `admin/add-documents/${id}`,
        formData,
        config
      );
      const updatedDocumentData = response.data.data;

      setShowDocsModal(false);
      setIsSuccessModalOpen(true);
      setShowOverlay(false);
      setUploadedPdfUrls(updatedDocumentData);
      fetchOrderDetails();
      setUploadedFiles([]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error saving documents:", error);
    }
  };

  const cancelDelete = () => {
    setShowDocsModal(false);
    setShowCompleteModal(false);
    setShowOverlay(false);
  };

  const handleComplete = (orderId) => {
    if (orderDetail.id === orderId) {
      setShowCompleteModal(true);
      setOrderToComplete(orderDetail);
      setShowOverlay(true);
    } else {
      console.error("Order not found");
    }
  };
  const showOrderCompleteToast = () => {
    toast.success("Order marked as complete.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    // navigate(`/order/detail${id}`);
  };

  const confirmComplete = async (orderId) => {
    setIsLoading(true);

    try {
      const response = await client.put(
        `admin/update-documentation/${orderId}?status=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.status === true) {
        const updatedOrderDetail = { ...orderDetail };
        updatedOrderDetail.documentation.status = true;
        setOrderDetail(updatedOrderDetail);
        setStatusUpdated(true);
      } else {
        console.error("Unexpected response status code:", response.status);
      }

      setShowCompleteModal(false);
      setShowOverlay(false);
      setIsLoading(false);

      showOrderCompleteToast();
    } catch (error) {
      console.error("Error Completing Order:", error);
      toast.error("Complete the documents first.");
      setShowCompleteModal(false);
      setShowOverlay(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files);
    setUploadedFiles([...uploadedFiles, ...newFiles]);
  };

  const renderFiles = () => {
    return (
      <div className="pdf-document">
        {uploadedFiles.map((file, index) => (
          <div className="pdf-file" key={index}>
            {file.type === "application/pdf" ? (
              <img style={{ width: "50px" }} src={pdfImage} alt="pdf" />
            ) : (
              <img src={URL.createObjectURL(file)} alt={file.name} />
            )}
            <span>
              {file.type === "application/pdf"
                ? file.name.slice(-20)
                : file.name}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const getInitials = (firstName, lastName) => {
    const initials = `${firstName.charAt(0)}${lastName.charAt(
      0
    )}`.toUpperCase();
    return initials;
  };

  // console.log("orderDetail.user.firstName", orderDetail.user.firstName);
  console.log("orderDetail", orderDetail);

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  if (orderDetail.plotProduct) {
    return (
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading
          title={[
            <Link onClick={handleGoBack}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Order Detail",
          ]}
        />

        <Container fluid>
          <div style={{ maxWidth: "1920px" }}>
            {orderDetail.plotProduct && (
              <div className="blog-info-main p-2 me-2">
                <Row className="mt-5">
                  <Col lg="3">
                    <div class="details-img">
                      <div class="view-img">
                        <div className="main_image">
                          <img
                            src={orderDetail.plotProduct.plots[0].images[0]}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div class="location-details-main order-info-main">
                      <div class="about-location">
                        <div class="about-location-head">
                          <div class="about-heading">
                            <h3 className="d-flex align-items-center">
                              {orderDetail.plotProduct.siteName}
                              {orderDetail.documentation.status ? null : (
                                <button class="available pending-order ms-1">
                                  Pending
                                </button>
                              )}
                            </h3>
                            <span>
                              <b>Order ID</b>: {orderDetail.orderNumber}
                            </span>
                            <span className="mx-2 dot_span"></span>
                            <span>
                              <b> Created on:</b>{" "}
                              {new Date(orderDetail.createdAt)
                                .toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })
                                .replace("at", " ")}
                            </span>
                          </div>
                          {/*
                          <div class="order-mark">
                            {orderDetail.documentation.status ? (
                              <button className="completed-button completed">
                                <MdCheck className="me-1" />
                                Completed
                              </button>
                            ) : (
                              <button
                                onClick={() => handleComplete(orderDetail.id)}
                                className={`completed-button ${
                                  orderDetail.documentation.status
                                    ? "completed"
                                    : "mark-complete"
                                }`}>
                                <MdCheck className="me-1" />
                                {orderDetail.documentation.status
                                  ? "Completed"
                                  : "Mark as Complete"}
                              </button>
                            )}
                          </div>
                          */}
                        </div>
                        <div class="about-category d-flex mt-4">
                          <div className="me-5">
                            <h6>Category</h6>
                            <span>
                              {orderDetail.plotProduct.type === "Single Plot"
                                ? orderDetail.productType.slice(0, -1)
                                : orderDetail.productType}
                            </span>
                          </div>
                          <div className="ms-5">
                            <h6>Product Type</h6>
                            <span>
                              {orderDetail.plotProduct.type === "Single Plot"
                                ? orderDetail?.plotProduct?.category.slice(
                                    0,
                                    -1
                                  )
                                : orderDetail?.plotProduct?.category}
                            </span>
                          </div>
                          <div className="ms-5">
                            <h6>Plot Type</h6>
                            <span>{orderDetail.plotProduct.type}</span>
                          </div>
                        </div>
                        <div class="about-price order-price">
                          <span>£{orderDetail.price}</span>
                        </div>

                        {orderDetail.plotProduct.plots.map(
                          (plotDetail, plotIndex) => (
                            <div key={plotIndex}>
                              <h4 className="mt-4">Plot {plotIndex + 1}</h4>
                              {/* <div class="about-code mt-4">
                                <h5>Plot Code</h5>
                                <span className="text-muted">
                                  {plotDetail.plotcode}
                                </span>
                              </div> */}
                              <div class="about-discription mt-4">
                                <h4>Description</h4>
                                <p className="text-muted">
                                  {plotDetail.description}
                                </p>
                              </div>
                              <div class="about-sizes">
                                <ul>
                                  <li>
                                    <p>Plot Size</p>
                                    {plotDetail.plotSize}
                                  </li>
                                  <li>
                                    <p>Plot Number</p>
                                    {plotDetail.plotNumber}
                                  </li>
                                  <li>
                                    <p>Phase</p>
                                    {plotDetail.phase}
                                  </li>
                                  <li>
                                    <p>Subphase</p>
                                    {plotDetail.subPhase}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div class="customer-order">
                      <div class="customer-details">
                        <h2>Customer info</h2>
                        <div class="customer-profile">
                          <div class="customer-img">
                            {orderDetail.user.image ? (
                              <img src={orderDetail.user.image} alt="agent" />
                            ) : (
                              <div className="placeholder-name_user">
                                {getInitials(
                                  orderDetail.user.firstName,
                                  orderDetail.user.lastName
                                )}
                              </div>
                            )}
                            <div class="customer-name">
                              <span>{orderDetail.user.name}</span>
                              <p>
                                <b>Registered on:</b>{" "}
                                {new Intl.DateTimeFormat("en-US", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })
                                  .format(new Date(orderDetail.user.createdAt))
                                  .replace("at", "")}{" "}
                              </p>
                            </div>
                          </div>
                          <div class="customer-contact">
                            <span>Contact info</span>
                            <ul>
                              <li>
                                <i
                                  class="fa fa-envelope-o me-2"
                                  aria-hidden="true"></i>
                                {orderDetail.user.email}
                              </li>
                              <li>
                                <i
                                  class="fa fa-phone me-2"
                                  aria-hidden="true"></i>
                                {orderDetail.user.phoneNumber.dialCode}-
                                {orderDetail.user.phoneNumber.number}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="buyer-details">
                          <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist">
                            {orderDetail.buyerAddress.map((buyer, index) => (
                              <li
                                key={index}
                                className={
                                  activeTab === `Buyer ${index + 1}`
                                    ? "buyer-button"
                                    : "buyer-button-one"
                                }
                                onClick={() =>
                                  handleTabClick(`Buyer ${index + 1}`)
                                }>
                                {buyer.type}
                              </li>
                            ))}
                          </ul>
                          <div className="tab-content" id="myTabContent">
                            {orderDetail.buyerAddress.map((buyer, index) => (
                              <div
                                style={{
                                  background: "#ffffff",
                                  color: "black",
                                }}
                                key={index}
                                className={`tab-pane fade ${
                                  activeTab === `Buyer ${index + 1}`
                                    ? "show active"
                                    : ""
                                }`}>
                                <div className="buyer-info">
                                  <ul
                                    style={{
                                      display: "inline",
                                      color: "#000",
                                    }}>
                                    <li>
                                      {" "}
                                      <img
                                        src={userImg}
                                        alt=""
                                        className="me-2"
                                      />
                                      {buyer.firstName} {buyer.lastName}
                                    </li>
                                    <li>
                                      <img src={post} alt="" className="me-2" />
                                      {buyer.postalCode}
                                    </li>
                                    {buyer.address && (
                                      <li>
                                        <img
                                          src={address}
                                          alt=""
                                          className="me-2"
                                        />
                                        {buyer.address}
                                      </li>
                                    )}
                                    <li>
                                      <img
                                        src={email}
                                        alt=""
                                        className="me-2"
                                      />
                                      {buyer.email}
                                      {/* {buyer.email.length > 25
                                        ? buyer.email.slice(0, 25) + "..."
                                        : buyer.email}*/}
                                    </li>

                                    <li>
                                      <img
                                        src={phone}
                                        alt=""
                                        className="me-2"
                                      />
                                      {buyer.contactNumber.dialCode}{" "}
                                      {buyer.contactNumber.number}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/*  <div class="customer-documents">
                        <div className="documents-head">
                          <h2>Documents</h2>
                          {orderDetail.documentation.status ? null : (
                            <button onClick={() => uploadDocuments()}>
                              Save
                            </button>
                          )}
                        </div>
                        {renderFiles()}
                        <div className="pdf-document">
                          {orderDetail.documentation.documents
                            ? orderDetail.documentation.documents.map(
                                (document, index) => (
                                  <div
                                    id="file"
                                    className="pdf-file"
                                    key={index}>
                                    <img
                                      style={{ width: "50px" }}
                                      src={pdfImage}
                                      alt="pdf"
                                    />
                                    <div className="pdf-name">
                                      <a
                                        href={document}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {decodeURIComponent(document).slice(
                                          -20
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                )
                              )
                            : ""}
                        </div>
                        {orderDetail.documentation.status === true ? null : (
                          <div className="add-document">
                            <div className="upload-pdf">
                              <input
                                type="file"
                                accept=".pdf"
                                multiple
                                onChange={handleFileUpload}
                              />
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"></i>
                            </div>
                          </div>
                        )}
                      </div>
                    */}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Container>

        {showCompleteModal && <div className="overlay"></div>}
        <DocumentModal
          isOpen={showCompleteModal}
          onClose={cancelDelete}
          SuccessMessage="Please confirm!"
          ModalMessage="Are you sure all the document process has been completed?"
          onConfirmSave={() => confirmComplete(orderToComplete.id)}
        />
        {isSuccessModalOpen && <div className="overlay"></div>}
        <LocationSuccessModal
          isOpen={isSuccessModalOpen}
          onClose={closeSuccessModal}
          SuccessMessage={"Success!"}
          ModalMessage={"Your Documents has been updated successfully"}
        />
      </div>
    );
  } else if (orderDetail.additionalItemProduct) {
    return (
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading
          title={[
            <Link onClick={handleGoBack}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Order Detail",
          ]}
        />
        {isLoading ? (
          <div className="overlay">
            <Loading />
          </div>
        ) : (
          <Container fluid>
            <div style={{ maxWidth: "1920px" }}>
              <Row className="mt-5">
                <Col lg="3">
                  <div class="details-img">
                    <div class="view-img">
                      <div className="main_image">
                        <img
                          src={
                            orderDetail.additionalItemProduct.images[
                              selectedImageIndex
                            ]
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="selectable-img">
                      <ul>
                        {orderDetail.additionalItemProduct.images.map(
                          (image, index) => (
                            <li
                              key={index}
                              className={
                                index === selectedImageIndex ? "selected" : ""
                              }
                              onClick={() => setSelectedImageIndex(index)}>
                              <img src={image} alt={`Location ${index + 1}`} />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <div class="location-details-main order-info-main">
                    <div class="about-location">
                      <div class="about-location-head">
                        <div class="about-heading">
                          <h3 className="d-flex align-items-center">
                            {orderDetail.additionalItemProduct.title}
                          </h3>
                          <span>
                            <b>Order ID</b>: 125468
                          </span>
                          <span class="circle"></span>
                          <span>
                            <b> Created on:</b>{" "}
                            {new Date(orderDetail.createdAt)
                              .toLocaleString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                              .replace("at", " ")}
                          </span>
                        </div>
                      </div>
                      <div class="about-price order-price">
                        <span>£{orderDetail.additionalItemProduct.price}</span>
                      </div>
                      <div class="about-discription order-discription">
                        <p class="p-spacing">
                          {orderDetail.additionalItemProduct.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="3">
                  <div class="customer-order">
                    <div class="customer-details">
                      <h2>Customer info</h2>
                      <div class="customer-profile">
                        <div class="customer-img">
                          <span>
                            {orderDetail.user.image ? (
                              <img src={orderDetail.user.image} alt="agent" />
                            ) : (
                              <div className="placeholder-name_user">
                                {getInitials(
                                  orderDetail.user.firstName,
                                  orderDetail.user.lastName
                                )}
                              </div>
                            )}
                          </span>
                          <div class="customer-name">
                            <span>{orderDetail.user.name}</span>
                            <p>
                              Registered on:
                              {new Intl.DateTimeFormat("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                                .format(new Date(orderDetail.user.createdAt))
                                .replace("at", "")}{" "}
                            </p>
                          </div>
                        </div>
                        <div class="customer-contact">
                          <span>Contact info</span>
                          <ul>
                            <li>
                              <i
                                class="fa fa-envelope-o me-2"
                                aria-hidden="true"></i>
                              {orderDetail.user.email}
                            </li>
                            <li>
                              <i
                                class="fa fa-phone me-2"
                                aria-hidden="true"></i>
                              {orderDetail.user.phoneNumber.dialCode}-
                              {orderDetail.user.phoneNumber.number}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </div>
    );
  }
}

export default OrderDetails;
