import React, { useState, useEffect, useRef } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import MergePlotModal from "./MergePlotModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";
import NewMap from "./NewMap";
import { Tab, Tabs } from "react-bootstrap";
import noData from "../../../assets/Images/NoData.svg";
import UnMergerModal from "../../../shared/UnMergeModal";
import MergePlotModalOne from "./MergePlotModalOne";
import CustomModal from "../../../shared/CustomModal";
import BuyerPopup from "../../../shared/BuyerPopup";

function PlotManagement() {
  const [plotNumberFilter, setPlotNumberFilter] = useState("");
  const [plotData, setPlotData] = useState([]);
  const [plotId, setPlotId] = useState("");
  const [plotType, setPlotType] = useState("");
  const [plotView, setPlotView] = useState("map");
  const [areaData, setAreaData] = useState([]);
  const [types, setTypes] = useState([]);
  const [filteredPlotData, setFilteredPlotData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Single Plot");
  const [selectedPlots, setSelectedPlots] = useState([]);
  const [openAddBuyers, setOpenAddBuyers] = useState(false);
  const [selectedPlotsData, setSelectedPlotsData] = useState([]);
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const [buyer, setBuyer] = useState(false);
  const [isMergeModalListOpen, setIsMergeModalListOpen] = useState(false);
  const mapRef = useRef(null);
  const [change, setChange] = useState(false);
  const [checkedPlots, setCheckedPlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([
    "Single Plot",
    "Double Plot",
    "3 Family Plot",
    "4 Family Plot",
    "5 Family Plot",
    "Sold",
  ]);
  const [selectedCategory, setSelectedCategory] = useState("Urn Plots");
  const [data, setData] = useState({
    categories: [],
  });
  const [modal, setModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedSite, setSelectedSite] = useState("Inspired Meadows Kendal");
  const [location, setLocation] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [plotToBreak, setPlotToBreak] = useState(null);
  const [plotToDelete, setPlotToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { state } = useUserContext();

  const handleSelectTypes = async (e) => {
    const val = e.target.value;
    const isSelected = selectedTypes?.includes(val);
    if (isSelected) {
      setSelectedTypes([...selectedTypes.filter((type) => type !== val)]);
    } else {
      setSelectedTypes([...selectedTypes, val]);
    }
    e.target.checked = !isSelected;
  };

  const filterUrnPlots = () => {
    if (selectedTypes?.length === 6) {
      setFilteredPlotData([...plotData]);
    } else {
      const hasSoldType = selectedTypes?.includes("Sold");

      const filteredUrnPlots = plotData?.filter((plot) => {
        const isSelectedType = selectedTypes?.includes(plot?.type);
        if (hasSoldType) {
          return plot?.isAvailable === !hasSoldType || isSelectedType;
        } else {
          return plot?.isAvailable === !hasSoldType && isSelectedType;
        }
      });
      setFilteredPlotData(filteredUrnPlots);
    }
  };


  const fetchData = async () => {
    try {
      const response = await client.get(`admin/get-data`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTypes(response.data.data.types || []);
      const locations = response.data.data.locations;
      setLocation(locations);
      setLocationId(locations?.[0]?.id);

      // setIsLoading(false);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    localStorage.removeItem("plotsId");
    fetchData();
  }, [state.token]);

  const fetchPlots = async (locationId) => {
    // setIsLoading(true);
    try {
      const response = await client.get(
        `admin/get-all-plots?locationId=${locationId}`,
        {
          params: {
            category: selectedCategory,
            siteName: selectedSite,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      const allPlots = response?.data?.data[0]?.plots || [];
      setPlotData(allPlots);
      setAreaData(response?.data?.data[0]?.areas);
    } catch (error) {
      console.error("Error fetching plots:", error);
    } finally {
    }
  };
  useEffect(() => {
    fetchPlots(locationId, selectedTypes, selectedCategory);
  }, [locationId, selectedCategory, selectedSite, selectedTypes]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem("plotsId", JSON.stringify(checkedPlots));
  }, [checkedPlots]);

  const handleSiteChange = (event) => {
    setSelectedSite(event.target.value);
  };

  const handleMergePlotModal = () => {
    setIsMergeModalOpen(true);
    const storedPlotIds = JSON.parse(localStorage.getItem("plotsId")) || [];

    const payload = {
      plotsId: storedPlotIds.map((id) => id.toString()),
    };
    const selectedPlotsData = plotData.filter((plot) =>
      payload.plotsId.includes(plot.id.toString())
    );
    setSelectedPlotsData(selectedPlotsData);
  };

  let updatedCheckedPlots = [];

  const handleMergePlots = async () => {
    setIsLoading(true);

    try {
      const storedPlotIds = JSON.parse(localStorage.getItem("plotsId")) || [];

      const payload = {
        plotsId: storedPlotIds.map((id) => id.toString()),
      };

      const response = await client.post(`admin/merge-plots`, payload);
      const mergeData = await response.data.data;
      const updatedData = plotData.filter(
        (plot) => !payload.plotsId.includes(plot.id.toString())
      );
      const mergedPlots = mergeData.map((mergedPlot) => ({
        ...mergedPlot,
      }));

      const updatedNewData = [...updatedData, ...mergedPlots];
      setPlotData(updatedNewData);
      toast.success("Plots successfully merged.");
      localStorage.removeItem("plotsId");
      updatedCheckedPlots = [];
      setCheckedPlots([]);
      setSelectedPlots([]);

      setChange(false);
      setIsMergeModalOpen(false);
      setIsMergeModalListOpen(false);
      setIsLoading(false);
    } catch (error) {
      setChange(false);
      localStorage.removeItem("plotsId");
      setCheckedPlots([]);
      setIsLoading(false);

      mapRef.current.setPaintProperty("plotLayer", "fill-color", [
        "case",
        ["boolean", ["get", "isAvailable"], true],
        [
          "match",
          ["get", "type"],
          "Single Plot",
          "#219653",
          "Double Plot",
          "#C8D400",
          "3 Family Plot",
          "#20519b",
          "4 Family Plot",
          "orange",
          "5 Family Plot",
          "#b6424c",
          "#219653",
        ],
        "red",
      ]);
      toast.error("Error merging plots. Please try again.");
      setCheckedPlots([]);
      setSelectedPlots([]);
      setIsMergeModalOpen(false);
      setIsMergeModalListOpen(false);
    }
  };

  const handleCheckboxChange = (plotId, plotData) => {
    // let updatedCheckedPlots;

    if (checkedPlots.includes(plotId)) {
      updatedCheckedPlots = checkedPlots.filter((id) => id !== plotId);
    } else {
      if (checkedPlots.length >= 5 || checkedPlots.includes(plotId)) {
        toast.error("You can select up to 5 plots only");
        return;
      }

      updatedCheckedPlots = [...checkedPlots, plotId];
    }

    setChange(updatedCheckedPlots.length >= 2);

    setCheckedPlots(updatedCheckedPlots);

    const updatedSelectedPlotsData = selectedPlots.filter(
      (plot) => plot.id !== plotData.id
    );

    if (!checkedPlots.includes(plotId)) {
      updatedSelectedPlotsData.push(plotData);
    }

    setSelectedPlots(updatedSelectedPlotsData);

    localStorage.setItem("plotsId", JSON.stringify(updatedCheckedPlots));
  };

  useEffect(() => {
    filterUrnPlots();
  }, [selectedTypes, plotData]);

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setIsMergeModalOpen(false);
    setIsMergeModalListOpen(false);
    setModal(false);
    setShowOverlay(false);
  };

  //  BREAK PLOT API IMPLEMENTATION

  const handleBreak = (clickedPlotId) => {
    setModal(true);
    setShowOverlay(false);
    setPlotToBreak(clickedPlotId);
  };

  // console.log("===", plotToBreak);

  const handleConfirmUnMerge = async () => {
    setIsLoading(true);
    try {
      const response = await client.post(`admin/break-plots/${plotToBreak}`);
      const brokenPlots = await response.data.broken_plots;
      const updatedData = plotData.filter(
        (plot) => !plotToBreak.includes(plot.id.toString())
      );

      const updatedNewData = [...updatedData, ...brokenPlots];
      setPlotData(updatedNewData);

      toast.success("Plots were broken successfully.");
      setIsLoading(false);
    } catch (error) {
      console.error("Error breaking plots:", error);
      toast.error("Something went wrong");

      setModal(false);
      setShowOverlay(false);
      setIsLoading(false);
    } finally {
      setModal(false);
      setShowOverlay(false);
      setIsLoading(false);
    }
  };

  const openMergePlotModal = () => {
    setChange(true);
  };

  const switchToListView = () => {
    setPlotView("list");
    localStorage.removeItem("plotsId");
  };

  const switchToMapView = () => {
    setPlotView("map");
    localStorage.removeItem("plotsId");
    setCheckedPlots([]);
    setSelectedPlots([]);
    setChange(false);
    mapRef.current.setPaintProperty("plotLayer", "fill-color", [
      "case",
      ["boolean", ["get", "isAvailable"], true],
      [
        "match",
        ["get", "type"],
        "Single Plot",
        "#219653",
        "Double Plot",
        "#C8D400",
        "3 Family Plot",
        "#20519b",
        "4 Family Plot",
        "orange",
        "5 Family Plot",
        "#b6424c",
        "#219653",
      ],
      "red",
    ]);
  };

  // MAKE PLOT AVAILABLE API

  const handleMakeAvailable = (plotId) => {
    const plot = plotData.find((plot) => plot.id === plotId);
    setPlotToDelete(plot);
    setShowDeleteModal(true);
    setShowOverlay(true);
  };

  const confirmAvailable = async (plotId) => {
    try {
      setIsLoading(true);
      await client.put(`customer/make-plot-available/${plotId}`, null, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setPlotData((prevPlots) =>
        prevPlots.map((plot) =>
          plot.id === plotId ? { ...plot, isAvailable: true } : plot
        )
      );
      setShowDeleteModal(false);
      setShowOverlay(false);
      setIsLoading(false);
      switchToListView();

      toast.success("Plots are now available");
    } catch (error) {
      console.error("Error making available plots:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterPlotsByPlotNumber = (plots, plotNumberFilter) => {
    const filterValueLower = plotNumberFilter.toLowerCase();

    if (!filterValueLower) {
      return plots;
    }

    return plots.filter((plot) =>
      plot.plots.some(
        (singlePlot) => singlePlot.plotNumber.toLowerCase() === filterValueLower
      )
    );
  };

  const handlePlotNumberFilterChange = (e) => {
    const filterValue = e.target.value;
    setPlotNumberFilter(filterValue);

    if (!filterValue) {
      setSelectedTab("Single Plot");
    }

    const filteredPlots = filterPlotsByPlotNumber(plotData, filterValue);
    setFilteredPlotData(filteredPlots);

    const filteredPlotType =
      filteredPlots.length > 0 ? filteredPlots[0].type : "";
    const correspondingTab = determineCorrespondingTab(filteredPlotType);
    handleTabChange(correspondingTab);
  };

  const handleTabChange = (selectedTab) => {
    setSelectedTab(selectedTab);
  };

  const determineCorrespondingTab = (plotType) => {
    switch (plotType) {
      case "Single Plot":
        return "Single Plot";
      case "Double Plot":
        return "Double Plot";
      case "3 Family Plot":
        return "3 Family Plot";
      case "4 Family Plot":
        return "4_family_tab";
      case "5 Family Plot":
        return "5_family_tab";
      default:
        return "Single Plot";
    }
  };

  const sortedFilteredPlotData = filteredPlotData
    .filter(
      (plot) =>
        plot.type === "Single Plot" && plot.category === selectedCategory
    )
    .sort((a, b) => {
      const plotNumberA = a.plots[0].plotNumber;
      const plotNumberB = b.plots[0].plotNumber;

      const regex = /([a-zA-Z]+)([0-9]+)/;
      const [, alphaA, numA] = plotNumberA.match(regex);
      const [, alphaB, numB] = plotNumberB.match(regex);

      if (alphaA !== alphaB) {
        return alphaA.localeCompare(alphaB);
      } else {
        return parseInt(numA) - parseInt(numB);
      }
    });

  const sortedDoublePlotData = filteredPlotData
    .filter(
      (plot) =>
        (plot.type === "Double Plot" ||
          plot.type === "3 Family Plot" ||
          plot.type === "4 Family Plot" ||
          plot.isAvailable === false ||
          (plot.type === "5 Family Plot" &&
            (!selectedCategory || plot.category === selectedCategory))) &&
        (!selectedCategory || plot.category === selectedCategory)
    )
    // .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .sort((a, b) => {
      // Extract numeric part of plot numbers
      const plotNumberA = parseInt(a.plots[0].plotNumber.slice(1)); // Assuming plotNumber format is 'B#'
      const plotNumberB = parseInt(b.plots[0].plotNumber.slice(1));

      // Compare plot numbers
      return plotNumberA - plotNumberB;
    });

   const handleBuy = (id,category) => {
     setPlotId(id);
     setPlotType(category)
     setBuyer(true);
   };
  useEffect(() => {
    if (plotId) {
      setBuyer(true);
    }
  }, [plotId]);

  return (
    <div className="location_list">
      {showOverlay && <div className="overlay"></div>}
      <LocationHeading title={"Plot Management"} />
      {plotView === "map" ? (
        <div className="plot_map">
          <div className="loaction-list-main">
            <div className="loaction-list-head" style={{ padding: "0px" }}>
              <div className="search-bar_one d-flex">
                <div className="me-3">
                  <button
                    className="merge_button_one"
                    onClick={switchToListView}
                  >
                    List View
                  </button>
                </div>
                <select
                  id="mySelect"
                  className="me-3"
                  value={selectedSite}
                  onChange={handleSiteChange}
                >
                  {location?.map((loc, index) => (
                    <option key={index} value={loc.siteName}>
                      {loc.siteName}
                    </option>
                  ))}
                </select>
                <select
                  id="mySelect"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    handleCategoryChange(e.target.value);
                  }}
                >
                  {data.categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="add-location-button">
                {change ? (
                  <button
                    className="merge_button"
                    onClick={handleMergePlotModal}
                  >
                    Merge Plots
                  </button>
                ) : (
                  <button className="merge_button_disabled">Merge Plots</button>
                )}
              </div>
            </div>
          </div>
          <div className="product-type d-flex justify-content-end">
            <span>Type:</span>
            {types &&
              types.map((type, idx) => (
                <div key={idx} className="product-field">
                  <input
                    id={type.toLowerCase().replaceAll(" ", "-")}
                    type="checkbox"
                    value={type}
                    onChange={handleSelectTypes}
                    checked={selectedTypes?.includes(type)}
                  />
                  <label>{type}</label>
                </div>
              ))}
            <div className="product-field">
              <input
                type="checkbox"
                value="Sold"
                className="input-box"
                onChange={handleSelectTypes}
                checked={selectedTypes?.includes("Sold")}
              />
              <label htmlFor="sold-checkbox">Sold</label>
            </div>
          </div>

          <div className="map mt-5 mb-5 my-5">
            <NewMap
              plotData={filteredPlotData}
              openMergePlotModal={openMergePlotModal}
              selectedSite={selectedSite}
              handleBreak={handleBreak}
              selectedCategory={selectedCategory}
              areaData={areaData}
              change={change}
              mapRef={mapRef}
            />
          </div>
        </div>
      ) : (
        <div className="list_map">
          <div className="loaction-list-main">
            <div className="loaction-list-head" style={{ padding: "0px" }}>
              <div className="search-bar_one d-flex">
                <div className="me-3">
                  <button
                    className="merge_button_one"
                    onClick={switchToMapView}
                  >
                    Map View
                  </button>
                </div>
                <div class="search-bar me-3">
                  <input
                    type="text"
                    value={plotNumberFilter}
                    placeholder="Search by plot no."
                    onChange={handlePlotNumberFilterChange}
                  />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </div>
                <select
                  id="mySelect"
                  className="me-3"
                  value={selectedSite}
                  onChange={handleSiteChange}
                >
                  {location.map((loc, index) => (
                    <option key={index} value={loc.siteName}>
                      {loc.siteName}
                    </option>
                  ))}
                </select>
                <select
                  id="mySelect"
                  className="me-3"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  {data.categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="add-location-button">
                {change &&
                selectedTab === "Single Plot" &&
                checkedPlots.length >= 2 &&
                checkedPlots.length <= 5 ? (
                  <button
                    className="merge_button"
                    onClick={() => setIsMergeModalListOpen(true)}
                  >
                    Merge Plots
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <Tabs
            activeKey={selectedTab}
            onSelect={handleTabChange}
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab
              eventKey="Single Plot"
              title="Single Plot"
              className="tab_content plot-management-tab"
              style={{
                background: "transparent",
                color: "black !important",
                width: "100% !important",
              }}
            >
              <table border="0" cellpadding="0" style={{ width: "100%" }}>
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    border: "1px solid #EDEDED",
                  }}
                >
                  <tr>
                    <th style={{ padding: "22px 15px 22px 30px" }}>
                      Site Name
                    </th>
                    <th>Category</th>
                    <th>Plot Number</th>
                    <th>Size (m2)</th>
                    <th>Price</th>
                    <th>Select</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sortedFilteredPlotData
                    .filter((plot) => plot.type === "Single Plot")
                    .map((filteredPlot, index) => (
                      <tr key={index}>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  className="product-img"
                                  style={{
                                    border: "none",
                                    padding: "10px 10px 10px 25px",
                                  }}
                                >
                                  {filteredPlot.plots[0].images &&
                                  filteredPlot.plots[0].images[0] ? (
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={filteredPlot.plots[0].images[0]}
                                      alt="location"
                                    />
                                  ) : (
                                    <span>No Image Available</span>
                                  )}
                                </td>
                                <td
                                  className="rating"
                                  style={{ border: "none" }}
                                >
                                  {filteredPlot.siteName}
                                  <p>{filteredPlot.plotCode}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          {filteredPlot.type === "Single Plot"
                            ? filteredPlot.category.slice(0, -1)
                            : filteredPlot.category}
                        </td>
                        <td>
                          {filteredPlot.plots
                            .map((singlePlot) => singlePlot.plotNumber)
                            .join(", ")}
                        </td>
                        <td>{filteredPlot.totalPlotSize}</td>
                        <td>{`£${filteredPlot.totalPrice}`}</td>
                        <td
                          style={{
                            padding: "0 30px",
                            accentColor: "#219653",
                          }}
                        >
                          <input
                            type="checkbox"
                            name=""
                            checked={checkedPlots.includes(filteredPlot.id)}
                            onChange={() =>
                              handleCheckboxChange(
                                filteredPlot.id,
                                filteredPlot
                              )
                            }
                          />
                        </td>
                        {/* <td>
                          <button
                            onClick={() =>
                              handleBuy(
                                filteredPlot?.id,
                                filteredPlot?.category
                              )
                            }
                            className="purchase-button"
                          >
                            Buy
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  {sortedFilteredPlotData.filter(
                    (plot) => plot.type === "Single Plot"
                  ).length === 0 && (
                    <tr>
                      <td colSpan="6" className="text-center no_data-image-one">
                        <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Tab>
            <Tab
              eventKey="Double Plot"
              title="Double Plot"
              className="tab_content plot-management-tab"
            >
              <table border="0" cellpadding="0" style={{ width: "100%" }}>
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    border: "1px solid #EDEDED",
                  }}
                >
                  <tr>
                    <th style={{ padding: "22px 15px 22px 30px" }}>
                      Site Name
                    </th>
                    <th>Category</th>
                    <th>Plot Number</th>
                    <th>Size (m2)</th>
                    <th>Price</th>
                    <th className="text-center">Select</th>
                    {/* <th className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sortedDoublePlotData
                    .filter(
                      (plot) =>
                        plot.type === "Double Plot" && plot.isAvailable === true
                    )
                    .map((filteredPlot, index) => (
                      <tr key={index}>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  className="product-img"
                                  style={{
                                    border: "none",
                                    padding: "10px 10px 10px 25px",
                                  }}
                                >
                                  {filteredPlot.plots[0].images &&
                                  filteredPlot.plots[0].images[0] ? (
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={filteredPlot.plots[0].images[0]}
                                      alt="location"
                                    />
                                  ) : (
                                    <span>No Image Available</span>
                                  )}
                                </td>
                                <td
                                  className="rating"
                                  style={{ border: "none" }}
                                >
                                  {filteredPlot.siteName}
                                  <p>{filteredPlot.plotCode}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{filteredPlot.category}</td>
                        <td>
                          {filteredPlot.plots
                            .map((singlePlot) => singlePlot.plotNumber)
                            .join(", ")}
                        </td>
                        <td>{filteredPlot.totalPlotSize}</td>
                        <td>{`£${filteredPlot.totalPrice}`}</td>
                        <td
                          style={{
                            padding: "0 30px",
                            accentColor: "#219653",
                            textAlign: "center",
                          }}
                        >
                          <button
                            onClick={() => handleBreak(filteredPlot.id)}
                            className="break_plot"
                          >
                            Break Plot
                          </button>
                        </td>
                      </tr>
                    ))}
                  {sortedDoublePlotData.filter(
                    (plot) => plot.type === "Double Plot"
                  ).length === 0 && (
                    <tr>
                      <td colSpan="6" className="text-center no_data-image-one">
                        <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Tab>
            <Tab
              eventKey="3 Family Plot"
              title="3 Family Plot"
              className="tab_content plot-management-tab"
            >
              <table border="0" cellpadding="0" style={{ width: "100%" }}>
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    border: "1px solid #EDEDED",
                  }}
                >
                  <tr>
                    <th style={{ padding: "22px 15px 22px 30px" }}>
                      Site Name
                    </th>
                    <th>Category</th>
                    <th>Plot Number</th>
                    <th>Size (m2)</th>
                    <th>Price</th>
                    <th className="text-center">Select</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sortedDoublePlotData
                    .filter(
                      (plot) =>
                        plot.type === "3 Family Plot" &&
                        plot.isAvailable === true
                    )
                    .map((filteredPlot, index) => (
                      <tr key={index}>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  className="product-img"
                                  style={{
                                    border: "none",
                                    padding: "10px 10px 10px 25px",
                                  }}
                                >
                                  {filteredPlot.plots[0].images &&
                                  filteredPlot.plots[0].images[0] ? (
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={filteredPlot.plots[0].images[0]}
                                      alt="location"
                                    />
                                  ) : (
                                    <span>No Image Available</span>
                                  )}
                                </td>
                                <td
                                  className="rating"
                                  style={{ border: "none" }}
                                >
                                  {filteredPlot.siteName}
                                  <p>{filteredPlot.plotCode}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{filteredPlot.category}</td>
                        <td>
                          {filteredPlot.plots
                            .map((singlePlot) => singlePlot.plotNumber)
                            .join(", ")}
                        </td>
                        <td>{filteredPlot.totalPlotSize}</td>
                        <td>{`£${filteredPlot.totalPrice}`}</td>
                        <td
                          style={{
                            padding: "0 30px",
                            accentColor: "#219653",
                            textAlign: "center",
                          }}
                        >
                          <button
                            className="break_plot"
                            onClick={() => handleBreak(filteredPlot.id)}
                          >
                            Break Plot
                          </button>
                        </td>
                      </tr>
                    ))}
                  {sortedDoublePlotData.filter(
                    (plot) => plot.type === "3 Family Plot"
                  ).length === 0 && (
                    <tr>
                      <td colSpan="6" className="text-center no_data-image-one">
                        <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Tab>
            <Tab
              eventKey="4_family_tab"
              title="4 Family Plot"
              className="tab_content plot-management-tab"
            >
              <table border="0" cellpadding="0" style={{ width: "100%" }}>
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    border: "1px solid #EDEDED",
                  }}
                >
                  <tr>
                    <th style={{ padding: "22px 15px 22px 30px" }}>
                      Site Name
                    </th>
                    <th>Category</th>
                    <th>Plot Number</th>
                    <th>Size (m2)</th>
                    <th>Price</th>
                    <th className="text-center">Select</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sortedDoublePlotData
                    .filter(
                      (plot) =>
                        plot.type === "4 Family Plot" &&
                        plot.isAvailable === true
                    )
                    .map((filteredPlot, index) => (
                      <tr key={index}>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  className="product-img"
                                  style={{
                                    border: "none",
                                    padding: "10px 10px 10px 25px",
                                  }}
                                >
                                  {filteredPlot.plots[0].images &&
                                  filteredPlot.plots[0].images[0] ? (
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={filteredPlot.plots[0].images[0]}
                                      alt="location"
                                    />
                                  ) : (
                                    <span>No Image Available</span>
                                  )}
                                </td>
                                <td
                                  className="rating"
                                  style={{ border: "none" }}
                                >
                                  {filteredPlot.siteName}
                                  <p>{filteredPlot.plotCode}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{filteredPlot.category}</td>
                        <td>
                          {filteredPlot.plots
                            .map((singlePlot) => singlePlot.plotNumber)
                            .join(", ")}
                        </td>
                        <td>{filteredPlot.totalPlotSize}</td>
                        <td>{`£${filteredPlot.totalPrice}`}</td>
                        <td
                          style={{
                            padding: "0 30px",
                            accentColor: "#219653",
                            textAlign: "center",
                          }}
                        >
                          <button
                            className="break_plot"
                            onClick={() => handleBreak(filteredPlot.id)}
                          >
                            Break Plot
                          </button>
                        </td>
                      </tr>
                    ))}
                  {sortedDoublePlotData.filter(
                    (plot) => plot.type === "4 Family Plot"
                  ).length === 0 && (
                    <tr>
                      <td colSpan="6" className="text-center no_data-image-one">
                        <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Tab>
            <Tab
              eventKey="5_family_tab"
              title="5 Family Plot"
              className="tab_content plot-management-tab"
            >
              <table border="0" cellpadding="0" style={{ width: "100%" }}>
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    border: "1px solid #EDEDED",
                  }}
                >
                  <tr>
                    <th style={{ padding: "22px 15px 22px 30px" }}>
                      Site Name
                    </th>
                    <th>Category</th>
                    <th>Plot Number</th>
                    <th>Size (m2)</th>
                    <th>Price</th>
                    <th className="text-center">Select</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sortedDoublePlotData
                    .filter(
                      (plot) =>
                        plot.type === "5 Family Plot" &&
                        plot.isAvailable === true
                    )
                    .map((filteredPlot, index) => (
                      <tr key={index}>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  className="product-img"
                                  style={{
                                    border: "none",
                                    padding: "10px 10px 10px 25px",
                                  }}
                                >
                                  {filteredPlot.plots[0].images &&
                                  filteredPlot.plots[0].images[0] ? (
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={filteredPlot.plots[0].images[0]}
                                      alt="location"
                                    />
                                  ) : (
                                    <span>No Image Available</span>
                                  )}
                                </td>
                                <td
                                  className="rating"
                                  style={{ border: "none" }}
                                >
                                  {filteredPlot.siteName}
                                  <p>{filteredPlot.plotCode}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{filteredPlot.category}</td>
                        <td>
                          {filteredPlot.plots
                            .map((singlePlot) => singlePlot.plotNumber)
                            .join(", ")}
                        </td>
                        <td>{filteredPlot.totalPlotSize}</td>
                        <td>{`£${filteredPlot.totalPrice}`}</td>
                        <td
                          style={{
                            padding: "0 30px",
                            accentColor: "#219653",
                            textAlign: "center",
                          }}
                        >
                          <button
                            className="break_plot"
                            onClick={() => handleBreak(filteredPlot.id)}
                          >
                            Break Plot
                          </button>
                        </td>
                      </tr>
                    ))}
                  {sortedDoublePlotData.filter(
                    (plot) => plot.type === "5 Family Plot"
                  ).length === 0 && (
                    <tr>
                      <td colSpan="6" className="text-center no_data-image-one">
                        <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Tab>
            <Tab
              eventKey="sold"
              title="Sold"
              className="tab_content plot-management-tab"
            >
              <table border="0" cellpadding="0" style={{ width: "100%" }}>
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    border: "1px solid #EDEDED",
                  }}
                >
                  <tr>
                    <th style={{ padding: "22px 15px 22px 30px" }}>
                      Site Name
                    </th>
                    <th>Category</th>
                    <th>Plot Number</th>
                    <th>Size (m2)</th>
                    <th>Price</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedDoublePlotData
                    .filter((plot) => plot.isAvailable === false)
                    .map((filteredPlot, index) => (
                      <tr key={index}>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  className="product-img"
                                  style={{
                                    border: "none",
                                    padding: "10px 10px 10px 25px",
                                  }}
                                >
                                  {filteredPlot.plots[0].images &&
                                  filteredPlot.plots[0].images[0] ? (
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={filteredPlot.plots[0].images[0]}
                                      alt="location"
                                    />
                                  ) : (
                                    <span>No Image Available</span>
                                  )}
                                </td>
                                <td
                                  className="rating"
                                  style={{ border: "none" }}
                                >
                                  {filteredPlot.siteName}
                                  <p>{filteredPlot.plotCode}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          {filteredPlot.type === "Single Plot"
                            ? filteredPlot.category.slice(0, -1)
                            : filteredPlot.category}
                        </td>
                        <td>
                          {filteredPlot.plots
                            .map((singlePlot) => singlePlot.plotNumber)
                            .join(", ")}
                        </td>
                        <td>{filteredPlot.totalPlotSize}</td>
                        <td>{`£${filteredPlot.totalPrice}`}</td>
                        <td
                          style={{
                            padding: "0 30px",
                            accentColor: "#219653",
                            textAlign: "center",
                          }}
                        >
                          <button
                            onClick={() => handleMakeAvailable(filteredPlot.id)}
                            className="break_plot"
                            style={{ width: "120px", background: "#219653" }}
                          >
                            Make Available
                          </button>
                        </td>
                      </tr>
                    ))}
                  {sortedDoublePlotData.filter(
                    (plot) => plot.isAvailable === false
                  ).length === 0 && (
                    <tr>
                      <td colSpan="6" className="text-center no_data-image-one">
                        <img
                          src={noData}
                          alt="No Data"
                          width={"100%"}
                          height="100%"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Tab>
            {/* <Tab
              eventKey="company_plot"
              title="Company Plots"
              className="tab_content plot-management-tab"
            ></Tab> */}
          </Tabs>
          <div className="map mt-5 mb-5 my-5" style={{ display: "none" }}>
            <NewMap
              plotData={filteredPlotData}
              openMergePlotModal={openMergePlotModal}
              selectedSite={selectedSite}
              handleBreak={handleBreak}
              selectedCategory={selectedCategory}
              areaData={areaData}
              change={change}
              mapRef={mapRef}
            />
          </div>
        </div>
      )}
      {isMergeModalOpen && <div className="overlay"></div>}
      {isMergeModalListOpen && <div className="overlay"></div>}
      {isMergeModalOpen && (
        <MergePlotModal
          isOpen={isMergeModalOpen}
          onClose={cancelDelete}
          SuccessMessage="Please Confirm!"
          ModalMessage="Are you sure you want to merge these plots?"
          onConfirmMerge={handleMergePlots}
          isLoading={isLoading}
          selectedPlotsData={selectedPlotsData}
        />
      )}
      {isMergeModalListOpen && (
        <MergePlotModalOne
          isOpen={isMergeModalListOpen}
          onClose={cancelDelete}
          SuccessMessage="Please Confirm!"
          ModalMessage="Are you sure you want to merge these plots?"
          isLoading={isLoading}
          onConfirmMerge={handleMergePlots}
          selectedPlots={selectedPlots}
        />
      )}
      {modal && <div className="overlay"></div>}
      {modal && (
        <UnMergerModal
          isOpen={modal}
          onClose={() => setModal(false)}
          isLoading={isLoading}
          SuccessMessage="Please confirm!"
          ModalMessage="Are you sure you want to Break these plots?"
          onConfirmUnMerge={handleConfirmUnMerge}
        />
      )}
      <CustomModal
        isOpen={showDeleteModal}
        onClose={cancelDelete}
        isLoading={isLoading}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to make available this plot?"
        onConfirmDelete={() => confirmAvailable(plotToDelete.id)}
      />

      {buyer && (
        <BuyerPopup
          productId={plotId}
          buyerValue={setBuyer}
          productType={plotType}
        />
      )}
    </div>
  );
}

export default PlotManagement;
