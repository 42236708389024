import React, { Fragment, useState } from "react";
// import './popup.css'
import cartImage from "../assets/Images/cart_icon.svg";
import Buyers from "../Pages/Dashboard/plotManagement/Buyer";
import api from "../services/api";
import { toast } from "react-toastify";

function BuyerPopup({ productId, buyerValue, productType }) {
  const [loading, setIsLoading] = useState(false);
  const [openAddBuyers, setOpenAddBuyers] = useState(false);
  const handleOpenBuyersDetail = (value) => {
    setOpenAddBuyers(value);
  };

  console.log(productId, productType, "productId");

  const handleBuyCompanyPlot = async (e) => {
    e.preventDefault();

    const data = {
      productId: productId,
      productType: productType,
      quantity: 0,
    };
    try {
      setIsLoading(true);

      const response = await api.buyCompanyPlot(data);
      console.log(response, "buy request completed");

      setIsLoading(false);
      buyerValue(0);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosePopup = () => {
    buyerValue(0);
  };

  return (
    <Fragment>
      <div
        style={{ position: "fixed" }}
        className="overlay buyers-main-active"
      />
      <div class="">
        <div class="cart_main">
          <div className="button-div">
            <button onClick={handleClosePopup} className="buyer_close-popup">
              X
            </button>
          </div>
          <div>
            <img src={cartImage} alt="" />
          </div>
          <span>Please confirm!</span>
          <p>Buy this plot for ?</p>
          <div className="buyer-popup_button">
            <button onClick={handleBuyCompanyPlot} class="no-btn">
              {loading ? (
                <div className="edit_loader-one"></div>
              ) : (
                "For Company"
              )}
            </button>
            <button onClick={handleOpenBuyersDetail} class="yes-btn">
              For Customer
            </button>
          </div>
        </div>
      </div>
      {openAddBuyers && (
        <Buyers
          // handleCheckout={handleCheckout}
          handleOpenBuyersDetail={handleOpenBuyersDetail}
        />
      )}
    </Fragment>
  );
}

export default BuyerPopup;
