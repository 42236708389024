import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import error from "../assets/Images/warning 1.png";

Modal.setAppElement("#root");

const NewUnBlockModal = ({
  isOpen,
  onClose,
  SuccessMessage,
  ModalMessage,
  onConfirmUnBlock,
  isLoading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Location Added Successfully"
      className="modal-contentmodal-content"
      overlayClassName="modal-overlay">
      <div className="modal-body">
        <img src={error} alt="" />
        <h2>{SuccessMessage}</h2>
        <p>{ModalMessage}</p>
        <div className="modal-footer mt-4">
          <button className="modal-close-btn user-no-button" onClick={onClose}>
            No
          </button>
          <buttonplog
            className="modal-confirm-btn ms-3 user-edit-button"
            onClick={onConfirmUnBlock}
            disabled={isLoading}>
            {isLoading ? <div className="edit_loader-one"></div> : "Confirm"}
          </buttonplog>
        </div>
      </div>
    </Modal>
  );
};

export default NewUnBlockModal;
