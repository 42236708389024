import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import client from "../../../services/Client";
import { useUserContext } from "../../../components/context/UserContext";
import { Col, Container, Row, Table } from "react-bootstrap";
import Loading from "../../../shared/Loading";
import api from "../../../services/api";
import { toast } from "react-toastify";
import queryString from "query-string";

function RoleDetails() {
  const { id } = useParams();
  const location = useLocation();
  const [editLoading, setEditLoading] = useState(false);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useUserContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.editable === "true") {
      setIsEditable(true);
    }
  }, [location.search]);

  console.log(isEditable, "==============");
  console.log("-------------", location, "==============");

  const fetchUser = async () => {
    setIsLoading(true);
    try {
      const response = await client.get(
        `/admin/subadmin/get-subadmin-detail/${id}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      const blogData = response?.data?.data;
      setSelectedPermissions(blogData?.permissions);
      setUser(blogData);
      setFirstName(blogData?.firstName);
      setLastName(blogData?.lastName);
      setImage(blogData?.image); // Set existing image from user data
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [id]);

  const handleEditClick = () => {
    setIsEditable(true); // Enable edit mode
  };

  const handleCancelClick = () => {
    setIsEditable(false);
    fetchUser();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName) {
      toast.error("First Name is required");
      return;
    }

    if (selectedPermissions.length === 0) {
      toast.error("At least one permission must be selected");
      return;
    }

    if (!image) {
      toast.error("Image is required");
      return;
    }
    setEditLoading(true);
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    selectedPermissions.forEach((permission) => {
      formData.append("permissions", permission);
    });

    if (user?.image) {
      formData.append("oldImageUrl", user.image);
    }

    if (image && image instanceof File) {
      formData.append("newImage", image);
    }

    try {
      const response = await api.updateRole(id, formData, state?.token);
      toast.success(response?.message);
      setIsEditable(false);
      setEditLoading(false);
      navigate("/rolesandpermission");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error:", error);
      setEditLoading(false);
    }
  };

  const permissionsList = [
    { key: "dashboard", label: "Dashboard" },
    { key: "plots", label: "Plots" },
    // { key: "locations", label: "Location" },
    { key: "additionalItems", label: "Additional Items" },
    { key: "salesAgents", label: "Sales Agent" },
    // { key: "orders", label: "Order" },
    { key: "users", label: "Users" },
    { key: "blog", label: "Blog" },
  ];

  const handleCheckboxChange = (permission) => {
    setSelectedPermissions((prevPermissions) =>
      prevPermissions.includes(permission)
        ? prevPermissions.filter((perm) => perm !== permission)
        : [...prevPermissions, permission]
    );
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <div className="add-location-main">
      <LocationHeading
        title={[
          <Link to={"/rolesandpermission"}>
            <i
              style={{
                color: "#000",
                fontSize: "16px",
                marginBottom: "10px",
              }}
              className="fa fa-long-arrow-left me-2"
              aria-hidden="true"></i>
          </Link>,
          "Sub Admin Details",
        ]}
      />
      <div className="blog-info-main">
        <div className="location-info-head general-info-div">
          {isEditable ? (
            <h3>Edit information</h3>
          ) : (
            <h3>General information</h3>
          )}
          <div className="location-edit">
            <ul>
              <li>
                <button
                  onClick={handleEditClick}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}>
                  <i
                    className="ri-pencil-line me-2"
                    style={{
                      color: "#BDBDBD",
                      fontSize: "24px",
                      border: "1px solid #E0E0E0",
                      width: "50px",
                      height: "50px",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                    aria-hidden="true"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <Container className="p-2 main_container" fluid>
          <Row className="mt-5">
            <Col md="3">
              <div className="agent-photo">
                <div className="agent-upload">
                  {!image ? ( // Conditional rendering based on image state
                    <div>
                      <input
                        type="file"
                        onChange={(e) => setImage(e.target.files[0])}
                        disabled={!isEditable} // Disable when not editable
                      />
                      <div className="upload-icon">
                        <i
                          className="fa fa-cloud-upload"
                          aria-hidden="true"></i>
                        <span>Upload photo</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={
                          image instanceof File
                            ? URL.createObjectURL(image) // Show newly uploaded image
                            : image // Show existing image URL
                        }
                        alt="Selected"
                        className="selected-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          padding: "5px",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                      {isEditable && (
                        <span
                          className="delete-icon-image"
                          onClick={() => setImage(null)} // Clear image on click
                          style={{ cursor: "pointer" }}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md="9">
              <Row>
                <Col>
                  <div className="agent_input">
                    <label htmlFor="">First Name</label>
                    <input
                      type="text"
                      value={firstName}
                      placeholder="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                      readOnly={!isEditable} // Make read-only when not editable
                    />
                  </div>
                </Col>
                <Col>
                  <div className="agent_input">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      value={lastName}
                      placeholder="Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                      readOnly={!isEditable}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="agent_input">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      value={user?.email}
                      readOnly
                      placeholder="Email"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="agent_input">
                    <label htmlFor="">Permissions</label>
                    <Table size="lg" className="location_list-table">
                      <thead>
                        <tr>
                          {permissionsList.map((permission) => (
                            <th key={permission.key}>{permission.label}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {permissionsList.map((permission) => (
                            <td key={permission.key}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedPermissions.includes(
                                  permission.key
                                )}
                                onChange={() =>
                                  handleCheckboxChange(permission.key)
                                }
                                disabled={!isEditable} // Disable when not editable
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {isEditable && (
        <div class="location-save-btn">
          <Link>
            <button onClick={handleCancelClick} class="cancle-btn">
              Cancel
            </button>
          </Link>
          <button
            onClick={handleSubmit}
            class="save-btn user-edit-button"
            disabled={editLoading}>
            {editLoading ? <div className="edit_loader-one"></div> : "Edit"}
          </button>
        </div>
      )}
    </div>
  );
}

export default RoleDetails;
