import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import successImage from "../assets/Images/Sticker.png"

Modal.setAppElement("#root"); // Set the root element for the modal accessibility

const LocationSuccessModal = ({
  isOpen,
  onClose,
  SuccessMessage,
  ModalMessage,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Location Added Successfully"
      className="modal-contentmodal-content"
      overlayClassName="modal-overlay">
      {/* Apply overlay here */}
      <div className="modal-body">
        <img src={successImage} alt="" />
        <h2>{SuccessMessage}</h2>
        <p>{ModalMessage}</p>
        <button className="modal-close-btn" onClick={onClose}>
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default LocationSuccessModal;
