import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import sticker from "../assets/Images/Sticker.png";

// Set app element for react-modal
Modal.setAppElement("#root");

function OtpConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  SuccessMessage,
  ModalMessage,
  ModalEmail,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-contentmodal-content"
      contentLabel="OTP Sent Modal"
      overlayClassName="modal-overlay">
      <div>
        <img className="mt-2" src={sticker} alt="" />
        <h4 className="mt-3">{SuccessMessage}</h4>
        <p className="mt-2 mb-0">{ModalMessage}</p>
        <p className="m-0 p-0">{ModalEmail}</p>
        <button className="modal-confirm-btn ms-2" onClick={onConfirm}>
          Confirm
        </button>
      </div>
    </Modal>
  );
}

export default OtpConfirmationModal;
