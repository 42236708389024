import React from "react";
import { FaFileCircleExclamation } from "react-icons/fa6";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import LocationHeading from "../../../shared/LocationHeading";

export default function AddBlog() {
  const iconStyle = {
    width: "80px",
    height: "80px",
    color: "#d4d4d4",
    textAlign: "center",
  };
  return (
    <>
      <LocationHeading title={"Blog"} />
      <div className="addlocation_section">
        <FaFileCircleExclamation style={iconStyle} />
        <p>Blogs not added yet!</p>
        <Link to={"/blog/addBlogDetails"} className="location_button">
          <button>
            <MdAdd className="me-1" />
            Add Blog
          </button>
        </Link>
      </div>
    </>
  );
}
