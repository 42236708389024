import { useUserContext } from "../components/context/UserContext";
import client from "./Client";

const handleTokenExpiration = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.href = "/";
};

const token = localStorage.getItem("token");
client.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      handleTokenExpiration();
    }
    return Promise.reject(error);
  }
);

const api = {
  login: (email, password) => client.post(`admin/login`, { email, password }),
  forgotPassword: (email) => client.post("admin/forgot-password", { email }),
  verifyOTP: (email, otp) => client.post(`admin/verify-otp`, { email, otp }),
  confirmPassword: (confirmPassword, email, password) =>
    client.put(`admin/reset-password`, {
      confirmPassword,
      email,
      password,
    }),

  getLocation: async (token) => {
    try {
      const res = await client.get("admin/locations", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error; // Rethrow the error to be caught at a higher level if needed
    }
  },

  getRoles: async (token) => {
    try {
      const res = await client.get("admin/subadmin/get-subadmins", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error;
    }
  },

  addLocation: async (
    {
      biodiversity,
      createdAt,
      description,
      location,
      nearbyAttractions,
      nearestAccomodation,
      nearestBustop,
      nearestChurch,
      nearestPubFood,
      nearestTrainStation,
      placeId,
      siteName,
      sortName,
      surroundingArea,
      text,
      transport,
      updatedAt,
    },
    images,
    token
  ) => {
    const form = new FormData();

    form.append("biodiversity", biodiversity);
    form.append("createdAt", createdAt);
    form.append("description", description);
    form.append("location", location);
    form.append("nearbyAttractions", nearbyAttractions);
    form.append("nearestAccomodation", nearestAccomodation);
    form.append("nearestBustop", nearestBustop);
    form.append("nearestChurch", nearestChurch);
    form.append("nearestPubFood", nearestPubFood);
    form.append("nearestTrainStation", nearestTrainStation);
    form.append("placeId", placeId);
    form.append("siteName", siteName);
    form.append("sortName", sortName);
    form.append("surroundingArea", surroundingArea);
    form.append("text", text);
    form.append("transport", transport);
    form.append("updatedAt", updatedAt);

    images.forEach((img) => {
      form.append("images", img);
    });

    const res = await client.post("admin/add-location", form, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  },

  updateLocation: async (id, formData, token) => {
    try {
      const res = await client.put(`admin/edit-location/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error; // Rethrow the error to be caught at a higher level if needed
    }
  },

  updateRole: async (id, formData, token) => {
    try {
      const res = await client.put(
        `admin/subadmin/update-subadmin/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error; // Rethrow the error to be caught at a higher level if needed
    }
  },

  addPlot: async (
    {
      bottomLeftLatitude,
      bottomLeftLongitude,
      bottomRightLatitude,
      bottomRightLongitude,
      category,
      createdAt,
      description,
      locationId,
      phase,
      plotNumber,
      plotSize,
      price,
      siteName,
      subPhase,
      topLeftLatitude,
      topLeftLongitude,
      topRightLatitude,
      topRightLongitude,
      type,
      updatedAt,
    },
    images,
    token
  ) => {
    const form = new FormData();

    form.append("bottomLeftLatitude", bottomLeftLatitude);
    form.append("bottomLeftLongitude", bottomLeftLongitude);
    form.append("bottomRightLatitude", bottomRightLatitude);
    form.append("bottomRightLongitude", bottomRightLongitude);
    form.append("category", category);
    form.append("createdAt", createdAt);
    form.append("description", description);
    form.append("locationId", locationId);
    form.append("phase", phase);
    form.append("plotNumber", plotNumber);
    form.append("plotSize", plotSize);
    form.append("price", price);
    form.append("siteName", siteName);
    form.append("subPhase", subPhase);
    form.append("topLeftLatitude", topLeftLatitude);
    form.append("topLeftLongitude", topLeftLongitude);
    form.append("topRightLatitude", topRightLatitude);
    form.append("topRightLongitude", topRightLongitude);
    form.append("type", type);
    form.append("updatedAt", updatedAt);

    images.forEach((img) => {
      form.append("images", img);
    });

    const res = await client.post("admin/add-plot", form, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  },
  updatePlot: async (id, formData, token) => {
    try {
      const res = await client.put(`admin/edit-plot/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error;
    }
  },
  addItems: async (
    { createdAt, description, itemType, price, title, updatedAt },
    images,
    token
  ) => {
    const form = new FormData();

    form.append("createdAt", createdAt);
    form.append("description", description);
    form.append("itemType", itemType);
    form.append("price", price);
    form.append("title", title);
    form.append("updatedAt", updatedAt);
    images.forEach((img) => {
      form.append("images", img);
    });

    const res = await client.post("admin/add-additionalItem", form, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  },
  updateItem: async (id, formData, token) => {
    try {
      const res = await client.put(
        `admin/update-additionalItem/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error; // Rethrow the error to be caught at a higher level if needed
    }
  },
  addAgent: async (
    {
      calendlyLink,
      calendlyPassword,
      calendlyUserName,
      city,
      country,
      createdAt,
      email,
      expendedBio,
      firstName,
      lastName,
      location,
      shortBio,
      state,
      updatedAt,
      zoomLink,
      zoomPassword,
      zoomUserName,
    },
    image,
    token
  ) => {
    const form = new FormData();

    form.append("calendlyLink", calendlyLink);
    form.append("calendlyPassword", calendlyPassword);
    form.append("calendlyUserName", calendlyUserName);
    form.append("city", city);
    form.append("country", country);
    form.append("createdAt", createdAt);
    form.append("email", email);
    form.append("expendedBio", expendedBio);
    form.append("firstName", firstName);
    form.append("lastName", lastName);
    form.append("location", location);
    form.append("shortBio", shortBio);
    form.append("state", state);
    form.append("updatedAt", updatedAt);
    form.append("zoomLink", zoomLink);
    form.append("zoomPassword", zoomPassword);
    form.append("zoomUserName", zoomUserName);

    form.append("image", image);

    try {
      const res = await client.post(`admin/add-sales-agent`, form, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data; // Return the response data
    } catch (error) {
      console.error("API error:", error);
      throw error; // Rethrow the error to be caught at a higher level if needed
    }
  },
  updateAgent: async (id, formData, token) => {
    try {
      const res = await client.put(`admin/update-sales-agent/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error; // Rethrow the error to be caught at a higher level if needed
    }
  },

  addBlog: async (formData, token) => {
    try {
      const response = await client.post(`admin/add-blog`, formData, token, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addSubAdmin: async (formData, token) => {
    if (!token) {
      throw new Error("Token is missing. Please login again.");
    }
    try {
      const response = await client.post(
        `admin/subadmin/add-subadmin`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateBlog: async (id, formData, token) => {
    try {
      const response = await client.put(`admin/edit-blog/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addDocuments: async (id, formData, token) => {
    try {
      const response = await client.post(
        `admin/add-documents/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateAdmin: async (id, formData, token) => {
    try {
      const res = await client.put(`admin/update-admin-data/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error; // Rethrow the error to be caught at a higher level if needed
    }
  },
  updatePassword: async (id, confirmPassword, currentPassword, newPassword) =>
    await client.put(`admin/change-password/${id}`, {
      confirmPassword,
      currentPassword,
      newPassword,
    }),

  mergePlots: async (formData, token) => {
    try {
      const response = await client.post(`admin/merge-plots`, formData, token, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getPostCodeSuggestion: (postCode) =>
    client.get(`get-postCode-address-suggestions/${postCode}`),
  getAddress: (id) => client.get(`get-address/${id}`),
  addOrder: (order) => client.post("customer/start-payment", order),

buyCompanyPlot: async (data) => client.post(`admin/purchase-plot-for-company`,data),
};

export default api;
