import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonSection from "../../shared/CommonSection";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/loginModule.css";
import LogoImage from "../../shared/LogoImage";
import HomeImage from "../../shared/HomeImage";
import api from "../../services/api";
import { useEmailContext } from "../../components/context/EmailContext";
import Loading from "../../shared/Loading";
import OtpConfirmationModal from "../../shared/OtpConfirmationModal";
import { toast } from "react-toastify";
// import client from "../../services/Client";
import { useUserContext } from "../../components/context/UserContext";
// import { useEffect } from "react";
// import { useUserContext } from "../../components/context/UserContext";

export default function ForgotPassword() {
  const { setEmail } = useEmailContext();
  const [emailInput, setEmailInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  // const [newEmail, setNewEmail] = useState("");
  // const { state } = useUserContext();

  const navigate = useNavigate();

  // const id = useParams();

  // const fetchAdmin = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await client.get(`admin/get-admin-data/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${state.token}`,
  //       },
  //     });
  //     const adminData = response.data.admin.email;
  //     setNewEmail(adminData);

  //     console.log("new email", response.data.admin);

  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log("Error fetching admin in forgot password", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   fetchAdmin();
  // }, []);

  // console.log("email is", newEmail);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setEmailInput(setEmail);
    setIsLoading(true);

    if (!emailInput) {
      toast.error("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.forgotPassword(emailInput);

      if (response.data) {
        setShowOtpModal(true);
      } else {
        navigate("/forgotpassword");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const closeOtpModal = () => {
    setShowOtpModal(false);
  };

  const navigateToOtpPage = () => {
    closeOtpModal();
    navigate("/verifyotp"); // Replace with your OTP page route
  };
  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col
            lg="6"
            className="d-flex align-items-center justify-content-center">
            <div className="homepage_left">
              <div className="mb-5">
                <LogoImage />
              </div>
              <CommonSection title={"Forgot Password?"} />
              <div className="login-input-details">
                <p>No worries, we'll send you reset instructions</p>
                <form>
                  <div className="mb-3 input-group">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="youremail@example.com"
                      value={emailInput}
                      onChange={(e) => setEmailInput(e.target.value)}
                    />
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  </div>
                  <button
                    onClick={handleForgotPassword}
                    type="submit"
                    class="btn login-btn">
                    <Link className="reset_link">Send Code</Link>
                  </button>
                </form>
                <div className="mt-3">
                  <Link className="back_login-page" to={"/"}>
                    <i
                      class="fa fa-long-arrow-left me-2"
                      aria-hidden="true"></i>
                    Back To Login
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6" style={{ padding: "0px" }}>
            <HomeImage />
          </Col>
        </Row>

        <OtpConfirmationModal
          isOpen={showOtpModal}
          onClose={closeOtpModal}
          SuccessMessage="OTP Sent!"
          ModalMessage="We have sent an otp to your email."
          // ModalEmail={state.user.email}
          onConfirm={navigateToOtpPage}
        />
        {showOtpModal && <div className="overlay"></div>}
      </Container>
    </>
  );
}
