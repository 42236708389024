import React, { useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";

export default function AddAdditionalItemDetails() {
  const [images, setImages] = useState([]);
  const [imagesFiles, setImageFiles] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // Initialize with false
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useUserContext();

  const token = state.token;

  const navigate = useNavigate();

  const [addItems, setAddItems] = useState({
    createdAt: new Date().toISOString(),
    description: "",
    itemType: "",
    price: "",
    title: "",
    updatedAt: new Date().toISOString(),
  });

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/additionalItems/itemList"); // Provide the complete absolute path
  };

  const handleAddLItems = async (e) => {
    e.preventDefault();

    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }
    if (!selectedItemType) {
      // Check if an item type is selected
      displayError("Please select an item type.");
      return;
    }
    if (!addItems.title) {
      displayError("Title is required.");
      return;
    }
    if (!addItems.price) {
      displayError("Price is required.");
      return;
    }
    if (!addItems.description) {
      displayError("Please enter a valid Description.");
      return;
    }
    if (images.length === 0) {
      toast.error("Please upload at least one image.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    try {
      setIsSaving(true); // Show loading animation
      const res = await api.addItems(
        {
          ...addItems,
          itemType: selectedItemType,
        },
        imagesFiles,
        token
      );
      setAddItems({
        ...addItems,
      });
      console.log("Item response is :", res);
      if (res.data) {
        setIsSuccessModalOpen(true);
        openSuccessModal(true);
      } else {
        alert("Error adding item");
      }
    } catch (err) {
      console.error(err);
      alert("Something went wrong");
    } finally {
      setIsLoading(false);
      setIsSaving(false); // Hide loading animation
    }
  };

  const handleImageUpload = (event, index) => {
    const newImages = [...images];
    newImages[index] = URL.createObjectURL(event.target.files[0]);
    setImages(newImages);
    setImageFiles((prevVal) => {
      if (prevVal.length === 3) return prevVal;

      const newVal = [...prevVal];
      newVal.push(event.target.files[0]);
      return newVal;
    });
  };

  const handleImageDelete = (index) => {
    const input = document.getElementById("fileInput");
    if (input) {
      input.value = "";
    }

    const newImages = images.filter((image, i) => i !== index);
    setImages(newImages);
  };

  const renderImages = () => {
    if (images && images.length > 0) {
      return images.map((image, index) => (
        <div className="upload-img" key={index}>
          <div className="image-container">
            <img src={image} alt={`Uploaded ${index}`} />
            <span
              className="delete-icon-image"
              onClick={() => handleImageDelete(index)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      ));
    } else {
      return null; // No images to render
    }
  };

  return (
    <>
      {" "}
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/additionalItems  "}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Add Item",
          ]}
        />
        <div class="add-info">
          <div class="info-heading">
            <h3>General information</h3>
          </div>
          <div class="info-all-details">
            <h5>Item Type:</h5>
            <div class="item-type my-4 d-flex gap-5 align-items-center">
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Flower"
                  checked={selectedItemType === "Flower"}
                  onChange={(e) => setSelectedItemType(e.target.value)}
                />{" "}
                Flower
              </div>
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Tree"
                  checked={selectedItemType === "Tree"}
                  onChange={(e) => setSelectedItemType(e.target.value)}
                />{" "}
                Tree
              </div>
              {/*  <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Stone"
                  checked={selectedItemType === "Stone"}
                  onChange={(e) => setSelectedItemType(e.target.value)}
                />{" "}
                Stone
              </div>
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Urn"
                  checked={selectedItemType === "Urn"}
                  onChange={(e) => setSelectedItemType(e.target.value)}
                />{" "}
                Urn
              </div>
              <div>
                <input
                  type="radio"
                  name="itemType"
                  value="Casket"
                  checked={selectedItemType === "Casket"}
                  onChange={(e) => setSelectedItemType(e.target.value)}
                />{" "}
                Casket
              </div> */}
            </div>
            <div class="site-location item- title">
              <div class="input-field">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter title"
                  value={addItems.title}
                  onChange={(e) =>
                    setAddItems({
                      ...addItems,
                      title: e.target.value,
                    })
                  }
                />
              </div>
              <div class="input-field for-euro">
                <label>Price</label>
                <div className="price_box">
                  <input
                    type="number"
                    placeholder="Enter price"
                    value={addItems.price}
                    onChange={(e) =>
                      setAddItems({
                        ...addItems,
                        price: e.target.value,
                      })
                    }
                  />
                  <i class="fa fa-pound-sign" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="discription">
              <label>Description</label>
              <textarea
                placeholder="Type something..."
                value={addItems.description}
                onChange={(e) =>
                  setAddItems({
                    ...addItems,
                    description: e.target.value,
                  })
                }></textarea>
            </div>
            <div class="upload-img-main">
              <div class="img-head">
                <label>
                  Add images <span>( Max 3 images )</span>
                </label>
              </div>
              <div className="image-uploader">
                {images.length < 3 && (
                  <div className="upload-img">
                    <input
                      type="file"
                      id="fileInput"
                      onChange={(e) => handleImageUpload(e, images.length)}
                    />
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                )}
                {renderImages()}
              </div>
            </div>
          </div>
        </div>
        <div class="location-save-btn">
          <Link to={"/additionalItems"}>
            <button class="cancle-btn">Cancel</button>
          </Link>
          <button onClick={handleAddLItems} class="save-btn">
            Save
          </button>
        </div>
      </div>
      {isSaving && (
        <div className="overlay">
          <Loading />
        </div>
      )}{" "}
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Your Additional item has been added successfully"}
      />
    </>
  );
}
