import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_MEADOWS_API_BASE_URL;
// const API_BASE_URL = "https://bhthusjmgr.eu-west-1.awsapprunner.com/";
// const API_BASE_URL = "https://zn2j5663-5051.inc1.devtunnels.ms/";

const client = axios.create({
  baseURL: API_BASE_URL,
});

export default client;
