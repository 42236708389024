import React, { createRef, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeImage from "../../shared/HomeImage";
import { Col, Container, Row } from "react-bootstrap";
import CommonSection from "../../shared/CommonSection";
import LogoImage from "../../shared/LogoImage";
import api from "../../services/api";
// import { useEmailContext } from "../../components/context/EmailContext";
import Loading from "../../shared/Loading";
import { useUserContext } from "../../components/context/UserContext";
import OtpConfirmationModal from "../../shared/OtpConfirmationModal";
import { useEmailContext } from "../../components/context/EmailContext";
import { toast } from "react-toastify";

export default function VerifyOTP() {
  const { email } = useEmailContext();
  const [emailInput, setEmailInput] = useState("");
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  console.log(email, emailInput);

  const navigate = useNavigate();

  const otpInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleOtpChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);

    // Move focus to the previous input if backspace is pressed or input is cleared
    if (value === "" && index > 0) {
      otpInputRefs[index - 1].current.focus();
    } else if (value && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");

    // Filter out non-digit characters and take the first 6 digits
    const pastedDigits = pastedData.match(/\d/g).slice(0, 6);

    // Fill the OTP input fields with pasted digits
    for (let i = 0; i < otpInputRefs.length && i < pastedDigits.length; i++) {
      handleOtpChange(i, pastedDigits[i]);
    }
  };

  const handleBackspaceOrClear = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();

      if (e.key === "Backspace" && index > 0) {
        // Move focus to the previous input if possible
        otpInputRefs[index - 1].current.focus();
      }

      const newOtpDigits = [...otpDigits];
      newOtpDigits[index] = "";
      setOtpDigits(newOtpDigits);
    }
  };

  const handleVerifyOTP = async (e) => {
    if (otpDigits.some((digit) => !digit.trim())) {
      toast.error("OTP is required");
      return;
    }
    e.preventDefault();
    const otp = otpDigits.join("");
    try {
      setIsLoading(true);
      const response = await api.verifyOTP(email, otp);
      console.log(response.data);
      if (response.data) {
        setVerificationStatus("OTP Verified!");
        navigate("/confirmpassword");
      } else {
        setVerificationStatus("Invalid OTP");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setEmailInput(email);
    setIsLoading(true);

    try {
      const response = await api.forgotPassword(email);
      // Handle the response here

      if (response.data) {
        setShowOtpModal(true);
      } else {
        navigate("/forgotpassword");
      }
      // setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      alert("Invalid email address");
    } finally {
      setIsLoading(false); // Loading complete, whether successful or not
    }
  };

  const closeOtpModal = () => {
    setShowOtpModal(false);
  };
  const navigateToOtpPage = () => {
    closeOtpModal();
    navigate("/verifyotp"); // Replace with your OTP page route
  };

  return (
    <>
      <Container fluid>
        {isLoading ? ( // Render the loader if isLoading is true
          <div className="overlay">
            <Loading />
          </div>
        ) : (
          <Row>
            <Col
              lg="6"
              className="d-flex align-items-center justify-content-center">
              <div className={`homepage_left ${isLoading ? "loading" : ""}`}>
                <div className="mb-5">
                  <LogoImage />
                </div>
                <CommonSection title={"Verify Your Email"} />
                <div className="login-input-details">
                  <p>We sent a code to {email}</p>
                  <form>
                    <div className="otp-input">
                      {otpDigits.map((digit, index) => (
                        <input
                          type="text"
                          id={`digit${index + 1}`}
                          maxLength="1"
                          pattern="[0-9]"
                          required
                          value={digit}
                          onChange={(e) =>
                            handleOtpChange(index, e.target.value)
                          }
                          onKeyDown={(e) => handleBackspaceOrClear(e, index)}
                          ref={otpInputRefs[index]}
                          key={index}
                          onPaste={(e) => handlePaste(e)}
                        />
                      ))}
                    </div>
                    <button
                      onClick={handleVerifyOTP}
                      type="submit"
                      class="btn login-btn">
                      Confirm
                    </button>
                  </form>
                  <p>{verificationStatus}</p>
                  <div class="resend-otp mt-4">
                    <p className="d-flex align-items-center">
                      Didn't receive the email?{"  "}
                      <button
                        style={{ color: "#219653", fontWeight: "bold" }}
                        onClick={handleForgotPassword}
                        type="submit"
                        className="btn px-0 ms-1">
                        Click to resend
                      </button>
                    </p>
                  </div>
                  <div className="mt-3">
                    <Link className="back_login-page" to={"/"}>
                      <i
                        class="fa fa-long-arrow-left me-2"
                        aria-hidden="true"></i>
                      Back To Login
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6" style={{ padding: "0px" }}>
              <HomeImage />
            </Col>
          </Row>
        )}
        <OtpConfirmationModal
          isOpen={showOtpModal}
          onClose={closeOtpModal}
          SuccessMessage="OTP Sent!"
          ModalMessage="We have sent an otp to your email."
          onConfirm={navigateToOtpPage}
        />
        {showOtpModal && <div className="overlay"></div>}
      </Container>
    </>
  );
}
