import React, { useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import LocationHeading from "../../../shared/LocationHeading";
import { WithContext as ReactTags } from "react-tag-input";
import addPhoto from "../../../assets/Images/add_photo_alternate.png";
import addText from "../../../assets/Images/insert_text.png";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import logoOne from "../../../assets/Images/logoOne.png";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EditBlog() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [bannerImage, setBannerImage] = useState({});
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [categories, setCategories] = useState("");
  const [tags, setTags] = useState([]);
  const [metaList, setMetaList] = useState([{ metaName: "", metaContent: "" }]);
  const [content, setContent] = useState([]);
  const { state } = useUserContext();

  const token = state.token;

  const navigate = useNavigate();

  const addMetaBlock = () => {
    setMetaList([...metaList, { metaName: "", metaContent: "" }]);
  };

  const removeMetaBlock = (index) => {
    const updatedMetaList = metaList.filter((_, i) => i !== index);
    setMetaList(updatedMetaList);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMetaList = [...metaList];
    updatedMetaList[index][field] = value;
    setMetaList(updatedMetaList);
  };

  const { id } = useParams();

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/blog");
  };

  const handleAddDivClick = () => {
    setContent((prevContent) => [
      ...prevContent,
      { addContentText: "", imageFile: null, imageUrl: "" },
    ]);
  };

  const handleDeleteClick = (index) => {
    const updatedContent = [...content];
    updatedContent.splice(index, 1);
    setContent(updatedContent);
  };

  const handleImageChange = (event, index) => {
    const updatedContent = [...content];
    if (!updatedContent) {
      return;
    }
    updatedContent[index] = {
      ...updatedContent[index],
      imageUrl: URL.createObjectURL(event.target.files[0]),
      imageFile: event.target.files[0],
    };
    setContent(updatedContent);
  };

  const handleContentTextChange = (text, index) => {
    const updatedContent = [...content];
    updatedContent[index] = {
      ...updatedContent[index],
      addContentText: text,
    };
    setContent(updatedContent);
  };

  const onBannerImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    const imageUrl = URL.createObjectURL(selectedFile);
    setBannerImage({
      url: imageUrl,
      file: selectedFile,
    });
  };

  const handleDeleteImage = (index) => {
    const updatedContent = [...content];
    updatedContent[index].imageUrl = "";
    updatedContent[index].imageFile = null;
    setContent(updatedContent);
  };

  const clearImage = () => {
    setBannerImage(null);
  };

  const loadBlog = async () => {
    try {
      setIsLoading(true);
      const response = await client.get(`admin/get-blog/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTitle(response.data.blogs.title);
      setSlug(response.data.blogs.customSlug);
      const blogData = response.data.blogs;
      setCategories(blogData.categories);
      setPageTitle(blogData.pageTitle);
      setMetaList(
        blogData.metaTags.map((metaTag) => ({
          metaName: metaTag.metaName,
          metaContent: metaTag.metaContent,
        }))
      );

      const loadBannerImage = response.data.blogs.bannerImage;
      if (loadBannerImage) {
        setBannerImage({
          url: loadBannerImage,
        });
      }

      const blogContentData = response.data.blogs.addContent;

      setContent(
        blogContentData.map((contentItem) => ({
          addContentText: contentItem.text, // Populate the text
          imageUrl: contentItem.images, // Populate the image URL
          imageFile: null,
        }))
      );
      setIsLoading(false);

      console.log("res is:", response);
    } catch (error) {
      console.log("Error loading blog data:", error);
    }
  };
  useEffect(() => {
    loadBlog();
  }, []);

  const onSubmit = async (e, status) => {
    e.preventDefault();
    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }
    if (!title) {
      displayError("Blog title is required.");
      return;
    }
    if (!slug) {
      displayError("Custom slug is required.");
      return;
    }
    if (!pageTitle) {
      displayError("Page title is required.");
      return;
    }

    // const hasDescriptionMeta = metaList.some(
    //   (meta) =>
    //     meta.metaName.toLowerCase() === "description" &&
    //     meta.metaContent.trim() !== ""
    // );

    // if (!hasDescriptionMeta) {
    //   displayError(
    //     "Add at least one meta tag with the name 'description' and its content."
    //   );
    //   return;
    // }

    if (!metaList.length === 0) {
      displayError("Add at least one meta name and it's content.");
      return;
    }
    if (!bannerImage) {
      displayError("Add banner Image.");
      return;
    }
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("status", status);
      formData.append("customSlug", slug);
      formData.append("categories", categories);
      formData.append("pageTitle", pageTitle);
      formData.append("metaTags", JSON.stringify(metaList));

      if (bannerImage) {
        if (bannerImage.file) {
          formData.append("bannerImage", bannerImage.file);
        } else if (bannerImage.url) {
          formData.append("bannerImage", bannerImage.url);
        }
      }

      for (let i = 0; i < content.length; i++) {
        formData.append("addContentText", content[i].addContentText);

        if (content[i].imageFile) {
          formData.append(`addContentImage#${i}`, content[i].imageFile);
        } else if (content[i].imageUrl) {
          // If there's an existing image URL, append it to addedContentImageUrl
          formData.append(`addedContentImageUrl#${i}`, content[i].imageUrl);
        }
      }
      // console.log("Form Data:", formData);
      await api.updateBlog(id, formData, token); // Pass formData here
      setIsSuccessModalOpen(true);
      openSuccessModal(true);
      // console.log("edited response :", response);/
    } catch (error) {
      console.log("Error updating blog :", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false); // Hide loading animation
    }
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/blog"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"
              ></i>
            </Link>,
            "Edit Blog Details",
          ]}
        />
        <div className="blog-info-main">
          <div className="blog-head">
            <h3>Blog information</h3>
          </div>
          <div className="blog-info">
            <div className="title">
              <label>Blog Title</label>
              <input
                type="text"
                placeholder="Kendle"
                value={title} // Set the value from state
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="category-slug mt-4">
              <div className="input-group-button">
                <label>Categories</label>
                <input
                  type="text"
                  placeholder="Enter Category"
                  value={categories}
                  onChange={(e) => setCategories(e.target.value)}
                />
              </div>
              <div className="input-group-button ">
                <label>
                  Custom Slug{" "}
                  <span>
                    ( The slug must not contain spaces; use hyphens to separate
                    words )
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
            </div>
            <div className="blog-option">
              <div className="input-group-button-two">
                <label>Page Title</label>
                <div>
                  <input
                    type="text"
                    placeholder="Page Title"
                    value={pageTitle}
                    onChange={(e) => setPageTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="input-group-button-three">
              <label>
                Meta Description{" "}
                <span>
                  (Only use 'description' and 'keywords' etc. as meta names)
                </span>
              </label>
              {metaList.map((meta, index) => (
                <div key={index} className="meta-content-description">
                  <div className="first-div">
                    <label>Meta Name </label>
                    <input
                      type="text"
                      placeholder="name"
                      value={meta.metaName}
                      onChange={(e) =>
                        handleInputChange(index, "metaName", e.target.value)
                      }
                    />
                  </div>
                  <div className="second-div">
                    <label>Meta Content</label>
                    <input
                      type="text"
                      placeholder="content"
                      value={meta.metaContent}
                      onChange={(e) =>
                        handleInputChange(index, "metaContent", e.target.value)
                      }
                    />
                  </div>
                  <div className="third-div">
                    {/* Conditionally render buttons */}
                    {index === metaList.length - 1 ? (
                      <>
                        {/* Add Button for the last meta block */}
                        <button onClick={addMetaBlock}>Add New</button>
                        {/* Remove Button for the last meta block (optional based on your needs) */}
                        {metaList.length > 1 && (
                          <button onClick={() => removeMetaBlock(index)}>
                            Remove
                          </button>
                        )}
                      </>
                    ) : (
                      // Remove Button for all but the last meta block
                      <button onClick={() => removeMetaBlock(index)}>
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="blog-banner">
              <span>Banner image</span>
              <div className="blog_banner-image">
                <div className="img-upload">
                  {!bannerImage ? (
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={onBannerImageChange}
                      />
                      <div className="upload-text">
                        <i
                          className="fa fa-cloud-upload"
                          aria-hidden="true"
                        ></i>
                        <span>Upload photo</span>
                        <p>(Recommended size1920x350)</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={bannerImage.url}
                        alt="Selected"
                        className="selected-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          padding: "5px",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                      <span className="delete-icon-image" onClick={clearImage}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h4 className="content_tag">Add Content</h4>
            {content.length > 0 ? (
              content.map((item, index) => (
                <div key={index} className="blog-content">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="blog-delete_one">
                      <button onClick={() => handleDeleteClick(index)}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </div>
                    {index === content.length - 1 && (
                      <div className="blog-delete_two">
                        <button onClick={handleAddDivClick}>
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="add-content-nextBlog">
                    <Container fluid>
                      <Row
                        className={`add_content-row ${
                          index % 2 === 0 ? "row-right" : "row-left"
                        }`}
                      >
                        <Col lg="3">
                          {content[index].imageUrl !== "" ? (
                            <div className="uploaded-image">
                              <img
                                src={content[index].imageUrl || logoOne}
                                alt="Selected"
                                className="selected_image"
                              />
                              <button
                                className="delete-icon"
                                onClick={() => handleDeleteImage(index)}
                              >
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          ) : (
                            <div class="img-upload">
                              <input
                                type="file"
                                id={`file-input-${index}`}
                                name={`file-input-${index}`}
                                accept="image/*"
                                onChange={(event) =>
                                  handleImageChange(event, index)
                                } // Make sure this line is correct
                              />
                              <div class="upload-text">
                                <i
                                  class="fa fa-cloud-upload"
                                  aria-hidden="true"
                                ></i>
                                <span>Upload photo</span>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col lg="9">
                          <ReactQuill
                            theme="snow"
                            value={item.addContentText || ""} // Provide a default value if addContentText is undefined
                            onChange={(content) =>
                              handleContentTextChange(content, index)
                            }
                          />

                          {/* <textarea
                            name=""
                            id=""
                            rows="6"
                            value={item.addContentText} // Use the correct value from the content array
                            onChange={(event) =>
                              handleContentTextChange(event.target.value, index)
                            }></textarea>  */}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              ))
            ) : (
              <div className="blog-content">
                <div className="add-content">
                  <div className="add-img-text">
                    <span onClick={handleAddDivClick}>
                      <img src={addPhoto} alt="add" />
                    </span>
                    <label>Image</label>
                  </div>
                  <div className="add-img-text">
                    <span onClick={handleAddDivClick}>
                      <img src={addText} alt="add text" />
                    </span>
                    <label>Text</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="blog-btn">
          <div className="blog-cancel">
            <Link to={"/blog"}>
              <button className="cancle-btn my-5">Cancel</button>
            </Link>
          </div>
          <div className="blog-save">
            {/* <button
              onClick={(e) => handleAddBlog(e, "drafts")}
              className="draft-btn">
              Save as draft
            </button>  */}

            <button onClick={(e) => onSubmit(e, "published")}>Save</button>
          </div>
        </div>
      </div>
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Your Blog has been updated successfully"}
      />
    </>
  );
}

export default EditBlog;
