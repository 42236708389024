import React, { useEffect, useState } from "react";
import CommonSection from "../../shared/CommonSection";
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/loginModule.css";
import { Link, useNavigate } from "react-router-dom";
import LogoImage from "../../shared/LogoImage";
import HomeImage from "../../shared/HomeImage";
import api from "../../services/api";
import { useUserContext } from "../../components/context/UserContext";
import Loading from "../../shared/Loading";
import { toast } from "react-toastify";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");

    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }

    if (savedPassword) {
      setPassword(savedPassword);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);

    if (!rememberMe) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (rememberMe) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }

    if (!email) {
      toast.error("Please enter a valid email.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!password) {
      toast.error("Please enter the password.");
      return;
    }
    try {
      setIsLoading(true);

      const response = await api.login(email, password);

      // console.log("admin resp", response);
      const user = response.data.admin;
      const token = response.data.token;
      localStorage.setItem("token", token);
      dispatch({ type: "SET_USER", payload: { user, token } });

      if (response.data) {
        dispatch({ type: "SET_USER", payload: { user, token } });
        navigate("/settings");
      } else {
        navigate("/");
        toast.error("Email not found");
      }
      setIsLoading(false);
      toast.success("Successfully logged in");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col
            lg="6"
            className="d-flex align-items-center justify-content-center">
            <div className="homepage_left">
              <div className="mb-5">
                <LogoImage />
              </div>
              <div className="title-wrapper">
                <h2>Login</h2>
              </div>
              <CommonSection></CommonSection>
              <div className="login-input-details">
                <p>Enter your details to login to your account</p>
                <form>
                  <div className="mb-3 input-group">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="youremail@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  </div>
                  <div className="mb-3 input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${
                        showPassword ? "text-input" : "password-input"
                      }`}
                      placeholder="Password"
                      id="exampleInputPassword1"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <i class="fa fa-lock" aria-hidden="true"></i>
                    <div
                      class="password-hide"
                      onClick={togglePasswordVisibility}>
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                    <label className="form-check-label" for="exampleCheck1">
                      Remember me
                    </label>
                  </div>
                  <button
                    onClick={handleLogin}
                    type="submit"
                    class="btn login-btn">
                    Login
                  </button>
                </form>
                <div className="mt-3">
                  <Link className="forgot-password" to={"/forgotpassword"}>
                    Forgot your Password?
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6" style={{ padding: "0px" }}>
            <HomeImage />
          </Col>
        </Row>
      </Container>
    </>
  );
}
