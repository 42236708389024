import { Link, NavLink, useLocation } from "react-router-dom";
import { FaBars, FaUserCog } from "react-icons/fa";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./navbar.css";
import LogoImage from "../../shared/LogoImage";
import AdditionalIcon from "../../assets/Images/Additional_icon.png";
import {
  MdSpaceDashboard,
  MdLocationOn,
  MdAreaChart,
  MdSettings,
} from "react-icons/md";
import { FaUserFriends, FaUserCircle, FaListAlt } from "react-icons/fa";
import { HiMiniShoppingBag } from "react-icons/hi2";
import SidebarMenu from "./SidebarMenu";
import { useUserContext } from "../context/UserContext";

const Navbar = ({ children }) => {
  const { state } = useUserContext();
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [permission, setPermission] = useState([]);
  const location = useLocation();
  const routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <MdSpaceDashboard />,
      permission: "dashboard",
    },
    {
      path: "/plots",
      name: "Plots",
      icon: <MdAreaChart />,
      permission: "plots",
    },
    {
      path: "/plotsManagement",
      name: "Plots Management",
      icon: <MdAreaChart />,
      permission: "plots",
    },
    {
      path: "/additionalItems",
      name: "Additional Items",
      icon: <img src={AdditionalIcon} alt="Additional Items" />,
      permission: "additionalItems",
    },
    // {
    //   path: "/locations",
    //   name: "Locations",
    //   icon: <MdLocationOn />,
    // },
    {
      path: "/salesAgent",
      name: "Sales Agent",
      icon: <FaUserFriends />,
      permission: "salesAgents",
    },
    // {
    //   path: "/order",
    //   name: "Order",
    //   icon: <HiMiniShoppingBag />,
    // },
    {
      path: "/rolesandpermission",
      name: "Role & Permissions",
      icon: <FaUserCog />,
      // permission: "dashboard",
    },
    {
      path: "/users",
      name: "Users",
      icon: <FaUserCircle />,
      permission: "users",
    },
    {
      path: "/blog",
      name: "Blog",
      icon: <FaListAlt />,
      permission: "blog",
    },
    {
      path: "/settings",
      name: "Settings",
      icon: <MdSettings />,
    },
  ];

  useEffect(() => {
    setPermission(state?.adminData?.permissions || []);
  }, [state]);

  const filteredRoutes = routes.filter((route) => {
    if(
      route.name === "Settings" ||
      (route.name === "Role & Permissions" && state?.user?.role === "admin")
    )  {
      return true;
    }
    return permission.includes(route.permission);
  });

  const hiddenRoutes = [
    "/login",
    "/forgotpassword",
    "/verifyotp",
    "/confirmpassword",
    "/resetcomplete",
  ];
  const showNavbar = !hiddenRoutes.includes(location.pathname);

  if (!showNavbar) {
    return null;
  }

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const navbarClass = isOpen ? "navbar-expanded" : "navbar-collapsed";

  return (
    <>
      <div
        className={`main-container ${navbarClass}`}
        style={{ backgroundColor: "white", position: "sticky", top: 0 }}>
        <motion.div
          animate={{
            width: isOpen ? "250px" : "",
          }}
          className={`sidebar ${isOpen ? "" : "sidebar-closed"}`}>
          <div className="top_section">
            {isOpen && (
              <motion.h1
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="logo">
                <Link to={"/"}>
                  <LogoImage />
                </Link>
              </motion.h1>
            )}
            <div className="bars">
              {isOpen ? (
                <FaBars style={{ margin: "0px" }} onClick={toggle} />
              ) : (
                <div className="bars-closed">
                  <FaBars onClick={toggle} />
                </div>
              )}
            </div>
          </div>
          <section className="routes">
            {filteredRoutes?.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="activeLogo">
                  <div
                    className={`icon ${!isOpen ? "icon-closed" : ""}`}
                    style={{ width: "20px", height: "20px" }}>
                    {route.icon}
                  </div>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text">
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default Navbar;
