import React from "react";
import Modal from "react-modal";
import "../../../shared/Modal.css";
import error from "../../../assets/Images/warning 1.png";

Modal.setAppElement("#root");

const MergePlotModal = ({
  isOpen,
  onClose,
  SuccessMessage,
  ModalMessage,
  onConfirmMerge,
  selectedPlotsData,
  isLoading,
}) => {
  console.log("isLoading", isLoading);
  const comparePlotNumbers = (plotA, plotB) => {
    // Extracting plot numbers and converting to integers for comparison
    const plotNumberA = parseInt(plotA.plots[0].plotNumber.slice(1));
    const plotNumberB = parseInt(plotB.plots[0].plotNumber.slice(1));

    // Compare plot numbers
    return plotNumberA - plotNumberB;
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Plots merged successfully"
      className="modal-contentmodal-content"
      overlayClassName="modal-overlay">
      <div className="modal-body  mt-0">
        <img src={error} alt="" />
        <h2>{SuccessMessage}</h2>
        <p>{ModalMessage}</p>
        <h6>
          Plot No. ={" "}
          {selectedPlotsData
            .slice() // Create a copy of the array to avoid modifying the original array
            .sort(comparePlotNumbers) // Sort the array using the compare function
            .map((plot) => plot.plots[0].plotNumber)
            .join(", ")}
        </h6>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="modal-close-btn"
            style={{ marginTop: "0px" }}
            onClick={onClose}>
            No
          </button>
          <button
            className="modal-confirm-btn modal-confirm-btn-one ms-2"
            style={{ marginTop: "0px" }}
            onClick={onConfirmMerge}
            disabled={isLoading}>
            {isLoading ? (
              <div className="upload_loader-two"></div>
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MergePlotModal;
