import React from "react";
import Modal from "react-modal";
import error from "../assets/Images/warning 1.png";

Modal.setAppElement("#root"); // Set the root element for the modal accessibility

const ErrorModal = ({ isOpen, closeModal, SuccessMessage, errorMessage }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Error Modal"
      className="modal-contentmodal-content"
      overlayClassName="modal-overlay">
      <div className="modal-body">
        <img src={error} alt="" />
        <h2>{SuccessMessage}</h2>
        <p>{errorMessage}</p>
        <button className="modal-close-btn" onClick={closeModal}>
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
