import loaderClasses from "./Loader.module.css";

const Loader = () => {
  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#00000050",
        zIndex: 1,
      }}
    >
      <div class={loaderClasses.loadingioSpinnerRolling}>
        <div class={loaderClasses.ldio}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
