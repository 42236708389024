import React, { useEffect, useState } from "react";
import $ from "jquery";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
// import zoomLogo from "../../../assets/Images/company_logo.png";
import calendlyLogo from "../../../assets/Images/company.png";
import calendlyLogoOne from "../../../assets/Images/company_one.png";
// import zoomLogoOne from "../../../assets/Images/zoom.png";
import api from "../../../services/api";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";
import { toast } from "react-toastify";

function EditSalesAgent() {
  const [image, setImage] = useState({});
  const [activeTab, setActiveTab] = useState("tab1"); // Set the initial active tab
  const [isSaving, setIsSaving] = useState(false); // Initialize with false
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const { state } = useUserContext();

  const token = state.token;

  let navigate = useNavigate();

  useEffect(() => {
    $(".agent-intregate ul li").on("click", function () {
      $(".agent-intregate ul li").removeClass("company-active");
      $(".company-logo-first, .company-logo-second").addClass("company-active");
      $(this).addClass("company-active");
    });
  });

  const onImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    const imageUrl = URL.createObjectURL(selectedFile);

    setImage({
      url: imageUrl,
      file: selectedFile,
    });
  };

  const clearImage = () => {
    setImage(null); // Reset image state
  };

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const { id } = useParams();

  const [editAgent, setEditAgent] = useState({
    createdAt: new Date().toISOString(),
    calendlyLink: "",
    calendlyPassword: "",
    calendlyUserName: "",
    city: "",
    country: "",
    email: "",
    expendedBio: "",
    firstName: "",
    image: [],
    lastName: "",
    location: "",
    shortBio: "",
    state: "",
    zoomLink: "",
    zoomPassword: "",
    zoomUserName: "",
  });

  const handleShortBioChange = (e) => {
    const text = e.target.value;
    setEditAgent((prevAgent) => ({
      ...prevAgent,
      shortBio: text,
    }));
  };

  const handleExpandedBioChange = (e) => {
    const text = e.target.value;
    setEditAgent((prevAgent) => ({
      ...prevAgent,
      expendedBio: text,
    }));
  };

  const loadAgent = async () => {
    setIsLoading(true);
    const response = await client.get(`admin/sales-agent/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const agentData = response.data.salesAgent;

    setEditAgent(() => ({
      createdAt: new Date().toISOString(),
      calendlyLink: agentData.integrate.calendly.link,
      calendlyPassword: agentData.integrate.calendly.password,
      calendlyUserName: agentData.integrate.calendly.userName,
      city: agentData.city,
      country: agentData.country,
      email: agentData.email,
      expendedBio: agentData.expendedBio,
      firstName: agentData.firstName,
      lastName: agentData.lastName,
      location: agentData.location,
      shortBio: agentData.shortBio,
      state: agentData.state,
      zoomLink: agentData.integrate.zoom.link,
      zoomPassword: agentData.integrate.zoom.password,
      zoomUserName: agentData.integrate.zoom.userName,
    }));

    const loadImage = agentData.image;

    if (loadImage) {
      setImage({
        url: loadImage,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadAgent();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;

    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }

    if (!editAgent.firstName) {
      displayError("First name is required.");
      return;
    }
    if (!editAgent.email) {
      displayError("email is required.");
      return;
    }
    if (!editAgent.location) {
      displayError("Location is required.");
      return;
    }
    if (!editAgent.shortBio) {
      displayError("Bio is required.");
      return;
    }

    if (
      !editAgent.calendlyUserName ||
      !emailRegex.test(editAgent.calendlyUserName)
    ) {
      displayError("Please enter a valid email address for calendly.");
      return;
    }
    if (!editAgent.calendlyPassword) {
      displayError("Calendly Password is required.");
      return;
    }
    if (!editAgent.calendlyLink) {
      displayError("Calendly Link is required.");
      return;
    }

    if (!image) {
      toast.error("Sales agent image is required.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    try {
      setIsSaving(true);
      const formData = new FormData();

      for (const key in editAgent) {
        formData.append(key, editAgent[key]);
      }

      const previousImageUrl = [];
      if (image.file != null) {
        formData.append("image", image.file);
      } else {
        previousImageUrl.push(image.url);
      }

      formData.append("image", previousImageUrl);
      const updatedAgent = {
        ...editAgent,
        image: previousImageUrl,
      };

      await api.updateAgent(id, formData, token);
      setIsSuccessModalOpen(true);
      openSuccessModal(true);
      setEditAgent(updatedAgent);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsSaving(false);
    }
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/salesAgent/salesAgentList");
  };

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/salesAgent"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Edit Agent Details",
          ]}
        />
        <div className="blog-info-main">
          <div className="location-info-head">
            <h3>Agent information</h3>
          </div>
          <Container className="p-2 main_container" fluid>
            <Row className="mt-5">
              <Col md="3">
                <div class="agent-photo">
                  <div className="agent-upload">
                    {!image ? (
                      <div>
                        <input type="file" onChange={onImageChange} />
                        <div className="upload-icon">
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"></i>
                          <span>Upload photo</span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={image.url}
                          alt="Selected"
                          className="selected-image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            padding: "5px",
                            position: "absolute",
                            top: "0",
                            left: "0",
                          }}
                        />
                        <span
                          className="delete-icon-image"
                          onClick={clearImage}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="9">
                <Row>
                  <Col>
                    <div className="agent_input">
                      <label htmlFor="">First Name</label>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        value={editAgent.firstName}
                        onChange={(e) =>
                          setEditAgent({
                            ...editAgent,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="agent_input">
                      <label htmlFor="">Last Name</label>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        value={editAgent.lastName}
                        onChange={(e) =>
                          setEditAgent({
                            ...editAgent,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="agent_input">
                      <label htmlFor="">Email</label>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        value={editAgent.email}
                        onChange={(e) =>
                          setEditAgent({
                            ...editAgent,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <div className="agent_input">
                      <label htmlFor="">Location</label>
                      <input
                        type="text"
                        placeholder="Enter Location"
                        value={editAgent.location}
                        onChange={(e) =>
                          setEditAgent({
                            ...editAgent,
                            location: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Col>
                  <Col className="mt-4">
                    <div className="agent_input">
                      <label htmlFor="">Short Bio</label>
                      <textarea
                        className="agent_bio"
                        rows={3}
                        placeholder="Type something..."
                        value={editAgent.shortBio}
                        onChange={handleShortBioChange}
                      />
                    </div>
                  </Col>
                  <Col className="mt-4">
                    <div className="agent_input">
                      <label htmlFor="">Expanded Bio</label>
                      <textarea
                        className="agent_bio"
                        rows={3}
                        placeholder="Type something..."
                        value={editAgent.expendedBio}
                        onChange={handleExpandedBioChange}
                      />
                    </div>
                  </Col>
                </Col>

                <div class="agent-intregate mt-5">
                  <h3>Integrate with:</h3>
                  <Tab.Container
                    activeKey={activeTab}
                    onSelect={handleTabSelect}>
                    <Row>
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="tab1"
                            className="nav_item"
                            activeClassName="nav_item">
                            <ul className="ps-0">
                              <li class="company-active">
                                <img src={calendlyLogoOne} alt="" />
                                <img
                                  class="company-logo-first"
                                  style={{ padding: "10px" }}
                                  src={calendlyLogo}
                                  alt=""
                                />
                              </li>
                            </ul>
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="tab2">
                            <ul className="ps-0">
                              <li class="">
                                <img src={zoomLogoOne} alt="" />
                                <img
                                  class="company-logo-first"
                                  src={zoomLogo}
                                  alt=""
                                />
                              </li>
                            </ul>
                          </Nav.Link>
                        </Nav.Item> */}
                        {/* Add more Nav.Items for other tabs */}
                      </Nav>
                    </Row>
                    <div
                      div
                      style={{
                        // display: "block",
                        marginTop: "100px",
                        marginLeft: "-198px",
                      }}>
                      <Tab.Content>
                        <Tab.Pane eventKey="tab1">
                          <Container
                            fluid
                            style={{ margin: "0px", padding: "0px" }}>
                            <Row className="mt-3">
                              <Col
                                style={{ background: "#ffffff" }}
                                className="agent_col"
                                activeClassName="nav_item">
                                <div className="agent_input">
                                  <label htmlFor="">Email</label>
                                  <input
                                    type="text"
                                    placeholder="abc@gmail.com"
                                    value={editAgent.calendlyUserName}
                                    onChange={(e) =>
                                      setEditAgent({
                                        ...editAgent,
                                        calendlyUserName: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                              <Col style={{ background: "#ffffff" }}>
                                <div className="agent_input">
                                  <label
                                    htmlFor=""
                                    style={{ background: "none !important" }}>
                                    Password
                                  </label>
                                  <input
                                    type="text"
                                    value={editAgent.calendlyPassword}
                                    onChange={(e) =>
                                      setEditAgent({
                                        ...editAgent,
                                        calendlyPassword: e.target.value,
                                      })
                                    }
                                    placeholder="**********"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <div class="zoom-link mt-3 mb-5">
                                <label>Link</label>
                                <input
                                  type="text"
                                  value={editAgent.calendlyLink}
                                  onChange={(e) =>
                                    setEditAgent({
                                      ...editAgent,
                                      calendlyLink: e.target.value,
                                    })
                                  }
                                  placeholder="zoom.us/signin#/958/evertwineinterviews"
                                />
                              </div>
                            </Row>
                          </Container>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="tab2">
                          <Container
                            fluid
                            style={{ margin: "0px", padding: "0px" }}>
                            <Row className="mt-3">
                              <Col style={{ background: "#ffffff" }}>
                                <div className="agent_input">
                                  <label htmlFor="">Email</label>
                                  <input
                                    type="text"
                                    placeholder="abc@gmail.com"
                                    value={editAgent.zoomUserName}
                                    onChange={(e) =>
                                      setEditAgent({
                                        ...editAgent,
                                        zoomUserName: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                              <Col style={{ background: "#ffffff" }}>
                                <div className="agent_input">
                                  <label htmlFor="">Password</label>
                                  <input
                                    type="text"
                                    value={editAgent.zoomPassword}
                                    onChange={(e) =>
                                      setEditAgent({
                                        ...editAgent,
                                        zoomPassword: e.target.value,
                                      })
                                    }
                                    placeholder="********"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <div class="zoom-link mt-3 mb-5">
                                <label>Link</label>
                                <input
                                  type="text"
                                  value={editAgent.zoomLink}
                                  onChange={(e) =>
                                    setEditAgent({
                                      ...editAgent,
                                      zoomLink: e.target.value,
                                    })
                                  }
                                  placeholder="zoom.us/signin#/958/evertwineinterviews"
                                />
                              </div>
                            </Row>
                          </Container>
                        </Tab.Pane> */}
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="location-save-btn agent_save-button">
          <Link to={"/salesAgent/salesAgentList"}>
            <button className="cancle-btn">Cancel</button>
          </Link>
          <button onClick={onSubmit} className="save-btn">
            Save
          </button>
        </div>
        {isSaving && (
          <div class="overlay">
            <Loading />
          </div>
        )}{" "}
      </div>
      {isSuccessModalOpen && <div className="overlay"></div>}

      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage="Success!"
        ModalMessage="The Agent details have been successfully updated."
      />
    </>
  );
}

export default EditSalesAgent;
