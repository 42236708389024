import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LocationHeading from "../../../shared/LocationHeading";
import Loading from "../../../shared/Loading";
import { Table } from "react-bootstrap";
import { useUserContext } from "../../../components/context/UserContext";
import placeholderImage from "../../../assets/Images/userone.jpg";
import UnBlockModal from "../../../shared/UnBlockModal";
import BlockModal from "../../../shared/BlocakModal";
import client from "../../../services/Client";

function UserDetails() {
  const { id } = useParams(); // Access the "id" parameter from the URL
  const [showOverlay, setShowOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null); // Initialize as an object
  const [recentOrders, setRecentOrders] = useState([]);
  const [userToBlock, setUserToBlock] = useState(null);
  const [userToUnBlock, setUserToUnBlock] = useState(null);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showUnBlockModal, setShowUnBlockModal] = useState(false);
  const { state } = useUserContext();
  const currentPage = state.currentPage;

  const navigate = useNavigate();

  const token = state.token;

  // console.log("-=-", userDetails.firstName);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      try {
        const response = await client.get(`admin/get-user-order/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data.user;
        const orders = response.data.orders;
        setUserDetails(userData);
        setRecentOrders(orders);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setIsLoading(false);
      }
    };
    fetchUserDetails();
  }, [id]);

  // Block User

  const handleBlock = (userId) => {
    setUserToBlock(userDetails);
    setShowBlockModal(true);
    setShowOverlay(true);
  };

  const handleUnBlock = (userId) => {
    setUserToUnBlock(userDetails);
    setShowUnBlockModal(true);
    setShowOverlay(true);
  };

  const confirmBlock = async (userId) => {
    try {
      setIsLoading(true);
      await client.put(
        `admin/update-user-status/${userId}?isBlocked=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowBlockModal(false);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
      navigate("/users");
    } catch (error) {
      console.error("Error Blocking user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmUnBlock = async (userId) => {
    setIsLoading(true);
    try {
      await client.put(
        `admin/update-user-status/${userId}?isBlocked=false`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowUnBlockModal(true);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
      navigate(`/users?page=${currentPage}`);
    } catch (error) {
      console.error("Error Un-blocking user:", error);
    }
  };

  const cancelDelete = () => {
    setShowBlockModal(false);
    setShowUnBlockModal(false);
    setShowOverlay(false); // Hide the overlay
  };

  const getInitials = (firstName, lastName) => {
    const initials = `${firstName.charAt(0)}${lastName.charAt(
      0
    )}`.toUpperCase();
    return initials;
  };

  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading
          title={[
            <Link to={`/users?page=${currentPage}`}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "User Details",
          ]}
        />
        {isLoading ? ( // Display loading animation while data is being fetched
          <div className="overlay">
            <Loading />
          </div>
        ) : userDetails ? (
          <div class="agent-details-main">
            <div class="details-img">
              <div style={{ width: "200px", height: "200px" }}>
                {userDetails.image ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={userDetails.image}
                    alt=""
                  />
                ) : (
                  <div className="user-placeholder">
                    {getInitials(userDetails.firstName, userDetails.lastName)}
                  </div>
                )}
              </div>
            </div>

            <div class="agent-details">
              <div class="agent-head">
                <div class="about-heading">
                  <h3>
                    {userDetails.firstName} {userDetails.lastName}
                  </h3>
                  <span>
                    Added on:{" "}
                    {new Date(userDetails.createdAt).toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    )}{" "}
                    <span className="time">
                      {new Date(userDetails.createdAt).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </span>
                  </span>
                </div>
                <div class="user-block">
                  <div
                    style={{
                      background: "none",
                      border: "none",
                      color: "#828282",
                      cursor: "pointer", // Add cursor style to indicate clickable element
                    }}
                    onClick={() =>
                      userDetails.isBlocked
                        ? handleUnBlock(userDetails.id)
                        : handleBlock(userDetails.id)
                    }>
                    <span
                      className="ms-1"
                      style={{ textDecoration: "underline" }}>
                      {userDetails.isBlocked === true ? (
                        <>
                          <i
                            className="fa fa-user-plus me-2"
                            style={{ color: "#219653" }}
                            aria-hidden="true"
                          />
                          Un-Block
                        </>
                      ) : (
                        <>
                          <i
                            className="fa fa-ban me-2"
                            style={{ color: "#bdbdbd" }}
                            aria-hidden="true"
                          />
                          Block
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div class="agent-contact user-details">
                <ul className="p-0">
                  <li>
                    <label>Email</label>
                    <p>{userDetails.email}</p>
                  </li>
                  <li style={{ marginLeft: "120px" }}>
                    <label>Phone Number</label>
                    <p>
                      {" "}
                      {userDetails.phoneNumber.dialCode}-
                      {userDetails.phoneNumber.number}
                    </p>
                  </li>
                </ul>
              </div>
              {/* <div class="recent-orders">
                <h3 style={{ fontSize: "20px" }}>
                  Recent Orders ({recentOrders.length})
                </h3>
                <Table size="lg" className="location_list-table">
                  <tbody>
                    {recentOrders.map((order, index) => (
                      <tr
                        key={index}
                        className="order-row"
                        style={{
                          border: "1px solid #e0e0e0",
                        }}>
                        <td style={{ padding: "0 0 0 15px" }}>
                          {order.orderNumber}
                        </td>
                        <td>
                          {order.plot ? (
                            <table>
                              <tr>
                                <td
                                  className="product-img"
                                  style={{ border: "none" }}>
                                  <img
                                    src={order.plot.image}
                                    alt=""
                                    className="me-2"
                                  />
                                </td>
                                <td
                                  className="rating"
                                  style={{ border: "none" }}>
                                  {order.plot.itemType==="Single Plot"? order.productType.slice(0, -1)
                                  : order.productType}
                                  <p>{order.plot.sietName}</p>
                                </td>
                              </tr>
                            </table>
                          ) : (
                            <div>
                              <table>
                                <tr>
                                  <td
                                    className="product-img"
                                    style={{ border: "none" }}>
                                    <img
                                      src={order.additionalItem.image}
                                      alt=""
                                      className="me-2"
                                    />
                                  </td>
                                  <td
                                    className="rating"
                                    style={{ border: "none" }}>
                                    {order.productType}
                                    <p>IM-KN-PI-PM-0001-U</p>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          )}
                        </td>
                        <td>£{order.totalPriceWithTax}</td>

                        <td>
                          {order.documentation.status ? (
                            <button className="status_button-order green">
                              Completed
                            </button>
                          ) : (
                            <button className="status_button-order yellow">
                              Pending
                            </button>
                          )}
                        </td>
                        <td>
                          <Link
                            to={`/order/details/${order.id}`}
                            onClick={(rowData) => {
                              handleClick(rowData);
                            }}>
                            <i
                              className="fa fa-angle-right"
                              style={{
                                color: "#BDBDBD",
                                cursor: "pointer",
                                fontSize: "25px",
                              }}
                              aria-hidden="true"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div> */}
            </div>
          </div>
        ) : (
          <div>No user details found.</div>
        )}
      </div>
      {showUnBlockModal && <div className="overlay"></div>}
      <UnBlockModal
        isOpen={showUnBlockModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to Un-Block?"
        onConfirmUnBlock={() => confirmUnBlock(userToUnBlock.id)}
      />
      <BlockModal
        isOpen={showBlockModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to Block?"
        onConfirmBlock={() => confirmBlock(userToBlock.id)}
      />
    </>
  );
}

export default UserDetails;
