import React, { useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
import AddLocation from "./AddLocation";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomModal from "../../../shared/CustomModal";
import noData from "../../../assets/Images/NoData.svg";
import {
  useUserContext,
  setCurrentPage,
} from "../../../components/context/UserContext";
import client from "../../../services/Client";

function ParentLocation() {
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [total, setTotal] = useState("");
  // const [currentPage, setCurrentPage] = useState(1); // Initialize current page to 1
  const [perPage] = useState(15); // Set the items per page
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [debouncedSiteName, setDebouncedSiteName] = useState("");
  const { state, dispatch } = useUserContext();
  const currentPage = state.currentPage;
  const navigate = useNavigate();
  const location = useLocation();

  const token = state.token;

  // DELETE API IMPLEMENTATION

  const handleDelete = (locationId) => {
    const location = locations.find((location) => location.id === locationId);
    setLocationToDelete(location);
    setShowDeleteModal(true);
    setShowOverlay(true); // Show the overlay
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false); // Hide the overlay
  };

  const confirmDelete = async (locationId) => {
    if (!locationToDelete) {
      return;
    }
    try {
      setIsLoading(true);

      await client.put(
        `admin/delete-location/${locationId}?isDeleted=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setLocations((prevLocations) =>
        prevLocations.filter((location) => location.id !== locationId)
      );
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
      fetchLocations();
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };

  const fetchLocations = async () => {
    setIsLoading(true);
    const queryParams = [];

    if (siteName) {
      queryParams.push(`siteName=${encodeURIComponent(siteName)}`);
      dispatch(setCurrentPage(1));
    }

    queryParams.push(`page=${currentPage}`);

    const queryString = queryParams.join("&");
    try {
      const response = await client.get(
        `admin/locations?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        },
        {
          params: {
            siteName: debouncedSiteName,
            page: currentPage,
            perPage: perPage,
          },
        }
      );

      const locationsData = response.data.data.locations;

      console.log(response);

      setTotal(response.data.data.total);
      setCurrentPage(response.data.data.currentPage);

      if (locationsData) {
        locationsData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setLocations(locationsData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching locations:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSiteName(siteName); // Update the debounced value after the delay
    }, 1000); // Adjust the delay time as needed (e.g., 300ms)

    // Clear the timer if siteName changes again before the delay completes
    return () => {
      clearTimeout(timerId);
    };
  }, [siteName]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1;

    // Check if the current page is different from the one in the URL
    if (page !== currentPage) {
      dispatch(setCurrentPage(1));
    }
    fetchLocations();
  }, [debouncedSiteName, location.search, currentPage, dispatch]);
  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  const handleClearInput = () => {
    setSiteName(""); // Clear the input value
    setLocations(); // Clear the locations when clearing input
    setIsLoading(true);
    navigate(`/locations?page=${currentPage}`);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      // Dispatch the action to set the current page
      dispatch({ type: "SET_CURRENT_PAGE", payload: newPage });
      navigate(`/locations?page=${newPage}`);
    }
  };

  if (locations && locations.length > 0) {
    return (
      <>
        <div className="location_list">
          <LocationHeading title={"Location"} />
          <div class="loaction-list-head">
            <div class="search-bar">
              <input
                type="text"
                value={siteName}
                placeholder="Search by name"
                onChange={(e) => setSiteName(e.target.value)}
              />
              {siteName && (
                <i
                  class="fa fa-times-circle clear-icon"
                  aria-hidden="true"
                  onClick={handleClearInput}
                  style={{ cursor: "pointer" }}></i>
              )}
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            {/* <div class="add-location">
              <Link to={"/locations/add-location-details"}>
                <button>
                  <i class="fa fa-plus me-2" aria-hidden="true"></i>Add Location
                </button> 
              </Link>
            </div>*/}
          </div>
          {isLoading ? ( // Render the loader if isLoading is true
            <div className="overlay">
              <Loading />
            </div>
          ) : (
            <div>
              <Table size="lg" className="location_list-table">
                <thead>
                  <tr>
                    <th>Location Name</th>
                    <th>Sort Name</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {locations.length === 0 ||
                  (debouncedSiteName &&
                    locations.every(
                      (location) =>
                        !location.siteName
                          .toLowerCase()
                          .includes(debouncedSiteName.toLowerCase())
                    )) ? (
                    <tr>
                      <td colSpan="4" className="text-center no_data-image">
                        {debouncedSiteName ? (
                          <img
                            src={noData}
                            alt="No Data"
                            width={"100%"}
                            height="100%"
                          />
                        ) : (
                          "No location found"
                        )}
                      </td>
                    </tr>
                  ) : (
                    locations.map((location) => (
                      <tr key={location.id}>
                        <td style={{ textTransform: "capitalize" }}>
                          <img
                            src={location.images[0]}
                            alt=""
                            className="me-2"
                          />
                          {location.siteName}
                        </td>
                        <td>{location.sortName}</td>
                        <td className="datetime-cell">
                          {new Date(location.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                          <br />
                          <span className="time text-muted">
                            {new Date(location.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </span>
                        </td>
                        <td>
                          <Dropdown className="details_dropdown">
                            <Dropdown.Toggle id="dropdown-basic">
                              <button>
                                <i
                                  class="fa fa-ellipsis-h"
                                  aria-hidden="true"></i>
                              </button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <Link
                                  to={`/locations/locationDetails/${location.id}`}>
                                  <i
                                    className="fa fa-eye me-2"
                                    style={{ color: "yellow" }}
                                    aria-hidden="true"></i>
                                  View Details
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link
                                  to={`/locations/editLocation/${location.id}`}>
                                  <i
                                    class="ri-pencil-line me-2"
                                    style={{ color: "green" }}
                                    aria-hidden="true"></i>
                                  Edit
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link onClick={() => handleDelete(location.id)}>
                                  <i
                                    className="fa fa-trash me-2"
                                    style={{ color: "red" }}
                                    aria-hidden="true"></i>
                                  Delete
                                </Link>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              <div className="next-previous-slide">
                <div className="slides-heading">
                  <span>
                    Showing {(currentPage - 1) * perPage + 1} to{" "}
                    {Math.min(currentPage * perPage, total)} out of {total}
                  </span>
                </div>

                <div className="slides-button">
                  <ul className="d-flex justify-center align-items-center">
                    <li>
                      <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}>
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                          style={{ cursor: "pointer", color: "#333" }}></i>
                      </button>
                    </li>
                    <li className="d-flex justify-center align-items-center">
                      {Array.from({ length: Math.ceil(total / perPage) }).map(
                        (_, index) => {
                          // Add logic to display ellipsis (...) if there are many pages
                          if (
                            index < 2 ||
                            index > Math.ceil(total / perPage) - 3 ||
                            (index >= currentPage - 2 &&
                              index <= currentPage + 1)
                          ) {
                            return (
                              <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={
                                  currentPage === index + 1 ? "active" : ""
                                }>
                                {index + 1}
                              </button>
                            );
                          } else if (
                            index === 2 ||
                            index === Math.ceil(total / perPage) - 3
                          ) {
                            // Display ellipsis (...) for the third and third-to-last position
                            return <span key={index}>...</span>;
                          }
                          return null;
                        }
                      )}
                    </li>
                    <li>
                      <button
                        disabled={currentPage === Math.ceil(total / perPage)}
                        onClick={() => handlePageChange(currentPage + 1)}>
                        <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                          style={{ cursor: "pointer" }}></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        {showDeleteModal && <div className="overlay"></div>}
        <CustomModal
          isOpen={showDeleteModal}
          onClose={cancelDelete}
          SuccessMessage="Please confirm!"
          ModalMessage="Are you sure you want to delete this Location?"
          onConfirmDelete={() => confirmDelete(locationToDelete.id)}
        />
      </>
    );
  } else {
    return <AddLocation />;
  }
}

export default ParentLocation;
