import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Dropdown, Table } from "react-bootstrap";
import Loading from "../../../shared/Loading";
import { Link, useNavigate, useLocation } from "react-router-dom";
import noData from "../../../assets/Images/NoData.svg";
import {
  useUserContext,
  setCurrentPage,
} from "../../../components/context/UserContext";
import UnBlockModal from "../../../shared/UnBlockModal";
import client from "../../../services/Client";
import BlockModal from "../../../shared/BlocakModal";

function UserList() {
  const [users, setUsers] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState("");
  const [perPage] = useState(15);
  const [userToBlock, setUserToBlock] = useState(null);
  const [userToUnBlock, setUserToUnBlock] = useState(null);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showUnBlockModal, setShowUnBlockModal] = useState(false);
  const [name, setName] = useState("");
  const [debouncedName, setDebouncedName] = useState("");
  const { state, dispatch } = useUserContext();
  const currentPage = state.currentPage;

  const token = state.token;

  const navigate = useNavigate();
  const location = useLocation();

  const fetchUser = async () => {
    const queryParams = [];

    if (name) {
      queryParams.push(`name=${encodeURIComponent(name)}`);
      dispatch(setCurrentPage(1));
    }

    queryParams.push(`page=${currentPage}`);

    const queryString = queryParams.join("&");

    try {
      setIsLoading(true);
      const response = await client.get(
        `admin/get-users?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          params: {
            name: debouncedName,
            perPage: perPage,
          },
        }
      );
      const userData = response.data.data.users;
      console.log("Users are :", userData);
      setTotal(response.data.data.total);
      setCurrentPage(response.data.data.currentPage);
      if (userData) {
        userData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setUsers(userData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedName(name);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [name]);
  // Function to get current page from local storage
  const getCurrentPageFromStorage = () => {
    const storedPage = localStorage.getItem("currentPage");
    return storedPage ? parseInt(storedPage, 10) : 1;
  };

  const setCurrentPageInStorage = (page) => {
    localStorage.setItem("currentPage", page.toString());
  };

  useEffect(() => {
    const storedPage = getCurrentPageFromStorage();
    dispatch({ type: "SET_CURRENT_PAGE", payload: storedPage });
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1;

    if (page !== currentPage) {
      dispatch(setCurrentPage(1));
    }
    fetchUser();
  }, [debouncedName, location.search, currentPage, dispatch]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      dispatch({ type: "SET_CURRENT_PAGE", payload: newPage });
      setCurrentPageInStorage(newPage); // Store current page in local storage
      navigate(`/users?page=${newPage}`);
    }
  };

  const handleClearInput = () => {
    setName("");
    setIsLoading(true);
    navigate(`/users?page=${currentPage}`);
  };

  // Block User

  const handleBlock = (userId) => {
    const user = users.find((user) => user.id === userId);
    setUserToBlock(user);
    setShowBlockModal(true);
    setShowOverlay(true);
  };
  const handleUnBlock = (userId) => {
    const user = users.find((user) => user.id === userId);
    setUserToUnBlock(user);
    setShowUnBlockModal(true);
    setShowOverlay(true);
  };

  const confirmBlock = async (userId) => {
    try {
      setIsLoading(true);
      await client.put(
        `admin/update-user-status/${userId}?isBlocked=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers((prevUser) =>
        prevUser.map((user) =>
          user.id === userId ? { ...user, isBlocked: true } : user
        )
      );
      setShowBlockModal(false);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
    } catch (error) {
      console.error("Error Blocking user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmUnBlock = async (userId) => {
    setIsLoading(true);
    try {
      await client.put(
        `admin/update-user-status/${userId}?isBlocked=false`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers((prevUser) =>
        prevUser.map((user) =>
          user.id === userId ? { ...user, isBlocked: false } : user
        )
      );
      setShowUnBlockModal(false);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
    } catch (error) {
      console.error("Error Un-blocking user:", error);
    }
  };

  const cancelDelete = () => {
    setShowBlockModal(false);
    setShowUnBlockModal(false);
    setShowOverlay(false);
  };

  const getInitials = (firstName, lastName) => {
    const initials = `${firstName.charAt(0)}${lastName.charAt(
      0
    )}`.toUpperCase();
    return initials;
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>de
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading title={"Users"} />
        <div class="loaction-list-main">
          <div class="loaction-list-head">
            <div class="search-bar">
              <input
                type="text"
                value={name}
                placeholder="Search by name"
                onChange={(e) => setName(e.target.value)}
              />

              {name && (
                <i
                  class="fa fa-times-circle clear-icon"
                  aria-hidden="true"
                  onClick={handleClearInput}
                  style={{ cursor: "pointer" }}></i>
              )}
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
          </div>
        </div>

        <Table size="lg" className="location_list-table">
          <thead
            style={{
              backgroundColor: "#FDFDFD",
              buser: "1px solid #EDEDED",
            }}>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Registered On</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 ||
            (debouncedName &&
              users.every(
                (user) =>
                  !user.firstName
                    ?.toLowerCase()
                    .includes(debouncedName.toLowerCase())
              )) ? (
              <tr>
                <td colSpan="6" className="text-center no_data-image">
                  {debouncedName ? (
                    <img
                      src={noData}
                      alt="No Data"
                      width={"100%"}
                      height="100%"
                    />
                  ) : (
                    "No user found"
                  )}
                </td>
              </tr>
            ) : (
              users.map((user) => (
                <tr>
                  <td>
                    <table>
                      <tr>
                        <td className="product-img" style={{ border: "none" }}>
                          {user.image ? (
                            <img src={user.image} alt="agent" />
                          ) : (
                            <div className="placeholder-name">
                              {getInitials(user.firstName, user.lastName)}
                            </div>
                          )}
                        </td>
                        <td class="rating" style={{ buser: "none" }}>
                          {user.firstName} {user.lastName}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>{user.email}</td>
                  <td>
                    {user.phoneNumber.dialCode}-{user.phoneNumber.number}
                  </td>
                  <td>
                    <td className="datetime-cell">
                      {new Date(user.createdAt).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                      <br />
                      <span className="time text-muted">
                        {new Date(user.createdAt).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </td>
                  </td>
                  <td>
                    <span
                      className={`available-btn ${
                        user.isBlocked ? "blocked" : "active"
                      }`}>
                      {user.isBlocked ? "Blocked" : "Active"}
                    </span>
                  </td>
                  <td>
                    <Dropdown className="details_dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        <button>
                          <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                        </button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to={`/users/userDetails/${user.id}`}>
                            <i
                              className="fa fa-eye me-2"
                              style={{ color: "yellow" }}
                              aria-hidden="true"></i>
                            View Details
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          {user.isBlocked === true ? (
                            <Link onClick={() => handleUnBlock(user.id)}>
                              <i
                                className="fa fa-user-plus me-2"
                                style={{ color: "#219653" }}
                                aria-hidden="true"></i>
                              Un-Block
                            </Link>
                          ) : (
                            <Link onClick={() => handleBlock(user.id)}>
                              <i
                                className="fa fa-ban me-2"
                                style={{ color: "#bdbdbd" }}
                                aria-hidden="true"></i>
                              Block
                            </Link>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                      {showUnBlockModal && <div className="overlay"></div>}
                      <BlockModal
                        isOpen={showBlockModal}
                        onClose={cancelDelete}
                        SuccessMessage="Please confirm!"
                        ModalMessage="Are you sure you want to Block?"
                        onConfirmBlock={() => confirmBlock(userToBlock.id)}
                      />
                      {showUnBlockModal && <div className="overlay"></div>}
                      <UnBlockModal
                        isOpen={showUnBlockModal}
                        onClose={cancelDelete}
                        SuccessMessage="Please confirm!"
                        ModalMessage="Are you sure you want to Un-Block?"
                        onConfirmUnBlock={() =>
                          confirmUnBlock(userToUnBlock.id)
                        }
                      />
                    </Dropdown>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <div className="next-previous-slide">
          <div className="slides-heading">
            <span>
              Showing {(currentPage - 1) * perPage + 1} to{" "}
              {Math.min(currentPage * perPage, total)} out of {total}
            </span>
          </div>

          <div className="slides-button">
            <ul className="d-flex justify-center align-items-center">
              <li>
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}>
                  <i
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ cursor: "pointer", color: "#333" }}></i>
                </button>
              </li>
              <li className="d-flex justify-center align-items-center">
                {Array.from({ length: Math.ceil(total / perPage) }).map(
                  (_, index) => {
                    // Add logic to display ellipsis (...) if there are many pages
                    if (
                      index < 2 ||
                      index > Math.ceil(total / perPage) - 3 ||
                      (index >= currentPage - 2 && index <= currentPage + 1)
                    ) {
                      return (
                        <button
                          key={index + 1}
                          onClick={() => handlePageChange(index + 1)}
                          className={currentPage === index + 1 ? "active" : ""}>
                          {index + 1}
                        </button>
                      );
                    } else if (
                      index === 2 ||
                      index === Math.ceil(total / perPage) - 3
                    ) {
                      // Display ellipsis (...) for the third and third-to-last position
                      return <span key={index}>...</span>;
                    }
                    return null;
                  }
                )}
              </li>
              <li>
                <button
                  disabled={currentPage === Math.ceil(total / perPage)}
                  onClick={() => handlePageChange(currentPage + 1)}>
                  <i
                    className="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserList;
