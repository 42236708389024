import React from "react";
import { Link } from "react-router-dom";
import HomeImage from "../../shared/HomeImage";
import { Col, Container, Row } from "react-bootstrap";
import CommonSection from "../../shared/CommonSection";
import LogoImage from "../../shared/LogoImage";

export default function ResetComplete() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col
            lg="6"
            className="d-flex align-items-center justify-content-center">
            <div className="homepage_left">
              <div className="mb-5">
                <LogoImage />
              </div>
              <CommonSection title={"Reset complete 🎉"} />
              <div className="login-input-details">
                <p>
                  Your password has been reset. You can now use your new
                  password to log in to your account.
                </p>
                <button type="submit" class="btn login-btn">
                  <Link className="reset_link" to="/">
                    Return to Login
                  </Link>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="6" style={{ padding: "0px" }}>
            <HomeImage />
          </Col>
        </Row>
      </Container>
    </>
  );
}
