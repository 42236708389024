import React, { useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
import AddAdditionalItems from "./AddAdditionalItems";
import { Dropdown, Modal, Table } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LocationHeading from "../../../shared/LocationHeading";
import CustomModal from "../../../shared/CustomModal";
import noData from "../../../assets/Images/NoData.svg";
import {
  useUserContext,
  setCurrentPage,
} from "../../../components/context/UserContext";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import client from "../../../services/Client";

function ParentItem() {
  const [items, setItems] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState("");
  const [perPage] = useState(15); // Set the items per page
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fromDate, setFromDate] = useState(""); // Filter value for from date
  const [toDate, setToDate] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [title, setTitle] = useState("");
  const [debouncedTitle, setDebouncedTitle] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [itemTypeFilters, setItemTypeFilters] = useState("");
  const [filtersCleared, setFiltersCleared] = useState(false);
  const currentDate = new Date();
  const { state, dispatch } = useUserContext();
  const currentPage = state.currentPage;
  const navigate = useNavigate();
  const location = useLocation();

  const openModal = () => {
    setShowModal(true);
    setShowOverlay(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowOverlay(false);
    document.body.style.overflow = "auto";
  };

  const fetchItems = async () => {
    const queryParams = [];

    if (title) {
      queryParams.push(`title=${encodeURIComponent(title)}`);
      dispatch(setCurrentPage(1));
    }

    if (itemTypeFilters.length > 0) {
      queryParams.push(
        `itemType=${encodeURIComponent(itemTypeFilters.join(","))}`
      );
    }

    const formattedFromDate = fromDate
      ? format(new Date(fromDate), "MM-dd-yyyy")
      : "";
    const formattedToDate = toDate
      ? format(new Date(toDate), "MM-dd-yyyy")
      : "";

    if (formattedFromDate) {
      queryParams.push(`fromDate=${encodeURIComponent(formattedFromDate)}`);
    }

    if (formattedToDate) {
      queryParams.push(`toDate=${encodeURIComponent(formattedToDate)}`);
    }

    queryParams.push(`page=${currentPage}`);

    const queryString = queryParams.join("&");

    try {
      setIsLoading(true);
      const response = await client.get(
        `admin/get-additionalItems?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        },
        {
          params: {
            title: debouncedTitle,
            perPage: perPage,
          },
        }
      );

      const itemData = response.data.data.ItemsRes;
      setCurrentPage(response.data.data.currentPage);

      if (itemData) {
        itemData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setItems(itemData);
      }
      setNewItems(itemData);
      setTotal(response.data.data.total);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching locations:", error);
      setIsLoading(false);
    }
  };

  // DELETE API IMPLEMENTATION

  const handleDelete = (itemId) => {
    const itemToDelete = items.find((item) => item.id === itemId);
    setItemToDelete(itemToDelete);
    setShowDeleteModal(true);
    setShowOverlay(true); // Show the overlay
  };

  const confirmDelete = async (itemId) => {
    try {
      setIsLoading(true);
      await client.put(
        `admin/delete-additional-item/${itemId}?isDeleted=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay
      setIsLoading(false);
      fetchItems();

      // No need to call fetchPlots() here, as setPlots already updated the plots state
    } catch (error) {
      console.error("Error deleting items", error);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false); // Hide the overlay
  };

  const handleClearInput = () => {
    setTitle("");
    setIsLoading(true);
    navigate(`/additionalItems?page=${currentPage}`);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      dispatch({ type: "SET_CURRENT_PAGE", payload: newPage });
      navigate(`/additionalItems?page=${newPage}`);
    }
  };

  const handleItemChange = (itemValue) => {
    setItemTypeFilters((prevFilters) => {
      if (prevFilters.includes(itemValue)) {
        return prevFilters.filter((filter) => filter !== itemValue);
      } else {
        return [...prevFilters, itemValue];
      }
    });
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTitle(title);
    }, 1000); // Adjust the delay time as needed (e.g., 300ms)

    // Clear the timer if siteName changes again before the delay completes
    return () => {
      clearTimeout(timerId);
    };
  }, [title]);

  const applyFilters = () => {
    if (fromDate > toDate) {
      toast.error("From date can not be greater than to date.");
      openModal();
    }

    dispatch(setCurrentPage(1));
    navigate(`/additionalItems?page=${currentPage}`);
    const anyFilterApplied = itemTypeFilters.length > 0 || fromDate || toDate;

    setFiltersApplied(anyFilterApplied);
    fetchItems();
    closeModal();
  };

  const resetFilters = () => {
    setFromDate("");
    setToDate("");
    setItemTypeFilters([]);
    setFiltersCleared(true);
    setFiltersApplied(false);
    dispatch(setCurrentPage(1));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1;
    if (page !== currentPage) {
      dispatch(setCurrentPage(1)); // Reset to page 1
    }
    fetchItems();
  }, [location.search, debouncedTitle, currentPage, dispatch]);

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  if (items && items.length > 0) {
    return (
      <>
        <div className="location_list">
          {showOverlay && <div className="overlay"></div>}
          <LocationHeading title={"Additional Items"} />
          <div class="loapction-list-main">
            <div class="loaction-list-head">
              <div class="search-bar">
                <input
                  type="text"
                  value={title}
                  placeholder="Search by Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
                {title && (
                  <i
                    class="fa fa-times-circle clear-icon"
                    aria-hidden="true"
                    onClick={handleClearInput}
                    style={{ cursor: "pointer" }}></i>
                )}
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <div class="add-location">
                {filtersApplied ? <div className="red-dot"></div> : null}
                <i
                  className="ri-filter-3-line me-2 filter_icon"
                  onClick={openModal}></i>
                <button>
                  <Link to={"/additionalItems/details"}>
                    <i class="fa fa-plus me-2" aria-hidden="true"></i>
                    Add Item
                  </Link>
                </button>
              </div>
            </div>
          </div>

          <div>
            <Table size="lg" className="location_list-table">
              <thead>
                <tr>
                  <th>Item Title</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th>Created On</th>
                  {/* <th>Status</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!newItems ? (
                  <tr>
                    <td colSpan="5" className="text-center no_data-image">
                      <img
                        src={noData}
                        alt="No Data"
                        width={"100%"}
                        height="100%"
                      />
                    </td>
                  </tr>
                ) : (
                  items.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <td>
                          <img src={item.images[0]} alt="" className="me-2" />
                          {item.title}
                        </td>
                      </td>
                      <td>{item.itemType}</td>
                      <td>£{item.price.toFixed(2)}</td>
                      <td>
                        {" "}
                        {new Date(item.createdAt).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                        <br />
                        <span className="time text-muted">
                          {new Date(item.createdAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </span>
                      </td>
                      {/* <td>
                          {status ? (
                            <button className="status_button available">
                              Available
                            </button> // Check the status of the plot
                          ) : (
                            <button className="status_button sold">Sold</button> // Red button for sold
                          )}
                        </td> */}
                      <td>
                        <Dropdown className="details_dropdown">
                          <Dropdown.Toggle id="dropdown-basic">
                            <button>
                              <i
                                class="fa fa-ellipsis-h"
                                aria-hidden="true"></i>
                            </button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={`/additionalItems/itemDetails/${item.id}?page=${currentPage}`}>
                                <i
                                  className="fa fa-eye me-2"
                                  style={{ color: "yellow" }}
                                  aria-hidden="true"></i>
                                View Details
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={`/additionalItems/edititem/${item.id}`}
                                style={{ color: "#000000" }}>
                                <i
                                  class="ri-pencil-line me-2"
                                  style={{ color: "green" }}
                                  aria-hidden="true"></i>
                                Edit
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link onClick={() => handleDelete(item.id)}>
                                <i
                                  className="fa fa-trash me-2"
                                  style={{ color: "red" }}
                                  aria-hidden="true"></i>
                                Delete
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <div className="next-previous-slide">
              <div className="slides-heading">
                <span>
                  Showing {(currentPage - 1) * perPage + 1} to{" "}
                  {Math.min(currentPage * perPage, total)} out of {total}
                </span>
              </div>

              <div className="slides-button">
                <ul className="d-flex justify-center align-items-center">
                  <li>
                    <button
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}>
                      <i
                        className="fa fa-angle-left"
                        aria-hidden="true"
                        style={{ cursor: "pointer", color: "#333" }}></i>
                    </button>
                  </li>
                  <li className="d-flex justify-center align-items-center">
                    {Array.from({ length: Math.ceil(total / perPage) }).map(
                      (_, index) => {
                        // Add logic to display ellipsis (...) if there are many pages
                        if (
                          index < 2 ||
                          index > Math.ceil(total / perPage) - 3 ||
                          (index >= currentPage - 2 && index <= currentPage + 1)
                        ) {
                          return (
                            <button
                              key={index + 1}
                              onClick={() => handlePageChange(index + 1)}
                              className={
                                currentPage === index + 1 ? "active" : ""
                              }>
                              {index + 1}
                            </button>
                          );
                        } else if (
                          index === 2 ||
                          index === Math.ceil(total / perPage) - 3
                        ) {
                          // Display ellipsis (...) for the third and third-to-last position
                          return <span key={index}>...</span>;
                        }
                        return null;
                      }
                    )}
                  </li>
                  <li>
                    <button
                      disabled={currentPage === Math.ceil(total / perPage)}
                      onClick={() => handlePageChange(currentPage + 1)}>
                      <i
                        className="fa fa-angle-right"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <Modal
            show={showModal}
            onHide={closeModal}
            dialogClassName="modal-right" // Custom CSS class for styling the modal
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div class="filter-status">
                    <span>Status</span>
                    <div class="input-group">
                      <input
                        type="checkbox"
                        className="me-2"
                        checked={statusFilters.available}
                        onChange={() =>
                          setStatusFilters((prevFilters) => ({
                            ...prevFilters,
                            available: !prevFilters.available,
                          }))
                        }
                      />
                      <label>Available</label>
                    </div>
                    <div class="input-group">
                      <input
                        type="checkbox"
                        className="me-2"
                        checked={statusFilters.sold}
                        onChange={() =>
                          setStatusFilters((prevFilters) => ({
                            ...prevFilters,
                            sold: !prevFilters.sold,
                          }))
                        }
                      />
                      <label>Sold</label>
                    </div>
                  </div> */}
              <div class="filter-status_item">
                <span>Item type</span>
                <div class="input-group">
                  <input
                    type="checkbox"
                    className="me-2"
                    checked={itemTypeFilters.includes("Tree")}
                    onChange={() => handleItemChange("Tree")}
                  />
                  <label>Tree</label>
                </div>
                <div class="input-group">
                  <input
                    type="checkbox"
                    className="me-2"
                    checked={itemTypeFilters.includes("Flower")}
                    onChange={() => handleItemChange("Flower")}
                  />
                  <label>Flower</label>
                </div>
                {/* <div class="input-group">
                  <input
                    type="checkbox"
                    className="me-2"
                    checked={itemTypeFilters.includes("Stone")}
                    onChange={() => handleItemChange("Stone")}
                  />
                  <label>Stone</label>
                </div>
                <div class="input-group">
                  <input
                    type="checkbox"
                    className="me-2"
                    checked={itemTypeFilters.includes("Urn")}
                    onChange={() => handleItemChange("Urn")}
                  />
                  <label>Urn</label>
                </div>
                <div class="input-group mt-3">
                  <input
                    type="checkbox"
                    className="me-2"
                    checked={itemTypeFilters.includes("Casket")}
                    onChange={() => handleItemChange("Casket")}
                  />
                  <label>Casket</label>
                </div>*/}
              </div>
              <div class="filter-status pb-0">
                <span>Created At</span>
              </div>
              <div class="filter-date">
                <div class="input-group">
                  <label>From</label>
                  <DatePicker
                    selected={fromDate}
                    value={fromDate}
                    onChange={(fromDate) => setFromDate(fromDate)}
                    placeholderText="MM-DD-YYYY"
                    dateFormat="MM-dd-yyyy"
                    maxDate={currentDate}
                  />
                </div>
                <div class="input-group">
                  <label>To</label>
                  <DatePicker
                    selected={toDate}
                    value={toDate}
                    onChange={(toDate) => setToDate(toDate)}
                    placeholderText="MM-DD-YYYY"
                    dateFormat="MM-dd-yyyy"
                    maxDate={currentDate}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="model_button">
                <button className="reset-btn" onClick={resetFilters}>
                  Reset
                </button>
                <button className="close-btn" onClick={applyFilters}>
                  Apply
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          {showDeleteModal && <div className="overlay"></div>}
          <CustomModal
            isOpen={showDeleteModal}
            onClose={cancelDelete}
            SuccessMessage="Please confirm!"
            ModalMessage="Are you sure you want to delete this Item?"
            onConfirmDelete={() => confirmDelete(itemToDelete.id)}
          />
        </div>
      </>
    );
  } else {
    return <AddAdditionalItems />;
  }
}

export default ParentItem;
