import React from "react";
import "../../../styles/dashboard.css";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaFileCircleExclamation } from "react-icons/fa6";
import LocationHeading from "../../../shared/LocationHeading";

export default function AddLocation() {
  const iconStyle = {
    width: "80px",
    height: "80px",
    color: "#d4d4d4",
    textAlign: "center",
  };
  return (
    <>
      <LocationHeading title={"Location"} />
      <div className="addlocation_section">
        <FaFileCircleExclamation style={iconStyle} />
        <p>Location not added yet!</p>
        <Link
          to={"/locations/addLocationDetails"}
          className="location_button">
          <button>
            <MdAdd />
            Add Location
          </button>
        </Link>
      </div>
    </>
  );
}
