import React from "react";
import logo from "../assets/Images/logo.png";

export default function LogoImage() {
  return (
    <div>
      <img src={logo} alt="" />
    </div>
  );
}
