import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LocationHeading from "../../../shared/LocationHeading";
import "../../../styles/dashboard.css";
import api from "../../../services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";

const AddPlotDetails = () => {
  const [images, setImages] = useState([]);
  const [imagesFiles, setImageFiles] = useState([]);
  const [location, setLocation] = useState([]);
  const [data, setData] = useState({
    categories: [],
    phases: [],
    subPhases: [],
    types: [],
  });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // Initialize with false
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useUserContext();

  const navigate = useNavigate();
  const token = state.token;

  const handleImageUpload = (event, index) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return;
    }

    const newImages = [...images];
    newImages[index] = URL.createObjectURL(selectedFile);

    const newImageFiles = [...imagesFiles];
    newImageFiles[index] = selectedFile;

    setImages(newImages);
    setImageFiles(newImageFiles);
  };

  const handleImageDelete = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);

    const newImageFiles = [...imagesFiles];
    newImageFiles.splice(index, 1);

    setImages(newImages);
    setImageFiles(newImageFiles);
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div className="upload-img" key={index}>
        <div className="image-container">
          <img src={image} alt={`Uploaded ${index}`} />
          <span
            className="delete-icon-image"
            onClick={() => handleImageDelete(index)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    ));
  };

  // API IMPLEMENTATION

  // FETCH MISCELLANEOUS DATA TO DISPLAY
  useEffect(() => {
    const miscData = async () => {
      try {
        const response = await client.get(`admin/get-data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response);
        setData(response.data.data);
        const locations = response.data.data.locations;
        setLocation(locations);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };
    miscData();
  }, []);

  // ADD PLOT API IMPLEMENTATION

  const [addPlot, setAddPlot] = useState({
    bottomLeftLatitude: "",
    bottomLeftLongitude: "",
    bottomRightLatitude: "",
    bottomRightLongitude: "",
    category: "",
    createdAt: new Date().toISOString(),
    description: "",
    locationId: "",
    phase: "",
    plotNumber: "",
    plotSize: "",
    price: "",
    siteName: "",
    subPhase: "",
    topLeftLatitude: "",
    topLeftLongitude: "",
    topRightLatitude: "",
    topRightLongitude: "",
    type: "",
    updatedAt: new Date().toISOString(),
  });

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/plots/plotList");
  };

  const handleAddPlot = async (e) => {
    e.preventDefault();

    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }

    const trimmedAddPlot = {
      ...addPlot,
      siteName: addPlot.siteName.trim(),
      phase: addPlot.phase.trim(),
      subPhase: addPlot.subPhase.trim(),
      plotNumber: addPlot.plotNumber.trim(),
      category: addPlot.category.trim(),
      type: addPlot.type.trim(),
      plotSize: addPlot.plotSize.trim(),
      price: addPlot.price.trim(),
      description: addPlot.description.trim(),
      topLeftLatitude: addPlot.topLeftLatitude.trim(),
      topLeftLongitude: addPlot.topLeftLongitude.trim(),
      topRightLatitude: addPlot.topRightLatitude.trim(),
      topRightLongitude: addPlot.topRightLongitude.trim(),
      bottomLeftLatitude: addPlot.bottomLeftLatitude.trim(),
      bottomLeftLongitude: addPlot.bottomLeftLongitude.trim(),
      bottomRightLatitude: addPlot.bottomRightLatitude.trim(),
      bottomRightLongitude: addPlot.bottomRightLongitude.trim(),
    };

    if (!trimmedAddPlot.siteName) {
      displayError("Site name is required.");
      return;
    }
    if (!trimmedAddPlot.phase) {
      displayError("Phase is required.");
      return;
    }
    if (!trimmedAddPlot.subPhase) {
      displayError("Subphase is required.");
      return;
    }
    if (!trimmedAddPlot.plotNumber) {
      displayError("Enter a valid plot number.");
      return;
    }
    if (!trimmedAddPlot.category) {
      displayError("Category is required.");
      return;
    }
    if (!trimmedAddPlot.type) {
      displayError("Type is required.");
      return;
    }
    if (!trimmedAddPlot.plotSize) {
      displayError("Enter a valid plot size.");
      return;
    }

    if (!trimmedAddPlot.price) {
      displayError("Price is required.");
      return;
    }
    if (!trimmedAddPlot.description) {
      displayError("Description is required.");
      return;
    }
    if (
      !trimmedAddPlot.bottomLeftLatitude ||
      !trimmedAddPlot.bottomLeftLongitude ||
      !trimmedAddPlot.bottomRightLatitude ||
      !trimmedAddPlot.bottomRightLongitude ||
      !trimmedAddPlot.topLeftLatitude ||
      !trimmedAddPlot.topLeftLongitude ||
      !trimmedAddPlot.topRightLatitude ||
      !trimmedAddPlot.topRightLongitude
    ) {
      displayError("Please enter all coordinates are required.");
      return;
    }
    if (images.length === 0) {
      toast.error("Please upload at least one image.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    const topLeft = `${addPlot.topLeftLatitude}.${addPlot.topLeftLongitude}`;
    const topRight = `${addPlot.topRightLatitude}.${addPlot.topRightLongitude}`;
    const bottomLeft = `${addPlot.bottomLeftLatitude}.${addPlot.bottomLeftLongitude}`;
    const bottomRight = `${addPlot.bottomRightLatitude}.${addPlot.bottomRightLongitude}`;

    setAddPlot({
      ...addPlot,
      topLeftLatitude: topLeft,
      topRightLatitude: topRight,
      bottomLeftLatitude: bottomLeft,
      bottomRightLatitude: bottomRight,
    });
    try {
      setIsSaving(true); // Show loading animation
      const res = await api.addPlot(trimmedAddPlot, imagesFiles, token);
      setAddPlot({ ...trimmedAddPlot });
      if (res.data) {
        setIsSuccessModalOpen(true);
        openSuccessModal(true);
      } else {
        alert("Error adding location");
      }
    } catch (err) {
      console.error(err);
      alert("Something went wrong");
    } finally {
      setIsLoading(false);
      setIsSaving(false); // Hide loading animation
    }
  };

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/plots"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Add Plot",
          ]}
        />
        <div className="add-info">
          <div className="info-heading">
            <h3>Plot information</h3>
          </div>
          <div className="info-all-details">
            <div className="site-location">
              <div className="input-field">
                <label>Site Location/Name</label>
                <select
                  id="mySelect"
                  required
                  value={addPlot.siteName}
                  onChange={(e) => {
                    const selectedSiteName = e.target.value;
                    const selectedLocation = location.find(
                      (loc) => loc.siteName === selectedSiteName
                    );

                    setAddPlot({
                      ...addPlot,
                      siteName: selectedSiteName,
                      locationId: selectedLocation ? selectedLocation.id : "",
                    });
                  }}>
                  <option value="" selected="" disabled="" hidden="">
                    select
                  </option>
                  {location.map((loc, index) => (
                    <option key={index} value={loc.siteName}>
                      {loc.siteName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Phase</label>
                <select
                  id="mySelect"
                  required
                  value={addPlot.phase}
                  onChange={(e) =>
                    setAddPlot({
                      ...addPlot,
                      phase: e.target.value,
                    })
                  }>
                  <option value="" selected="" disabled="" hidden="">
                    Select a phase
                  </option>
                  {data.phases.map((phase, index) => (
                    <option key={index} value={phase}>
                      {phase}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Subphase</label>
                <select
                  id="mySelect"
                  required
                  value={addPlot.subPhase}
                  onChange={(e) =>
                    setAddPlot({
                      ...addPlot,
                      subPhase: e.target.value,
                    })
                  }>
                  <option value="">Select a sub-phase</option>
                  {data.subPhases.map((subPhase, index) => (
                    <option key={index} value={subPhase}>
                      {subPhase}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Plot Number</label>
                <input
                  type="text"
                  placeholder="Enter  number"
                  value={addPlot.plotNumber}
                  onChange={(e) =>
                    setAddPlot({
                      ...addPlot,
                      plotNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="site-location">
              <div className="input-field">
                <label>Category</label>
                <select
                  id="mySelect"
                  required
                  value={addPlot.category}
                  onChange={(e) =>
                    setAddPlot({
                      ...addPlot,
                      category: e.target.value,
                    })
                  }>
                  <option value="">Select a category</option>
                  {data.categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Type</label>
                <select
                  id="mySelect"
                  required
                  value={addPlot.type}
                  onChange={(e) =>
                    setAddPlot({
                      ...addPlot,
                      type: e.target.value,
                    })
                  }>
                  <option value="">Select a type</option>
                  {data.types.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Plot Size</label>
                <input
                  type="text"
                  placeholder="Enter size"
                  value={addPlot.plotSize}
                  onChange={(e) =>
                    setAddPlot({
                      ...addPlot,
                      plotSize: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input-field">
                <label>Price</label>
                <div className="for-euro">
                  <input
                    type="number"
                    placeholder="Enter  price"
                    value={addPlot.price}
                    onChange={(e) =>
                      setAddPlot({
                        ...addPlot,
                        price: e.target.value,
                      })
                    }
                  />
                  <i className="fa fa-pound-sign" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div className="discription">
              <label>Description</label>
              <textarea
                placeholder="Type something..."
                value={addPlot.description}
                onChange={(e) =>
                  setAddPlot({
                    ...addPlot,
                    description: e.target.value,
                  })
                }></textarea>
            </div>
            <div className="plot-address-main">
              <div className="plot-gps">
                <div className="plot-gps-head">
                  <span>GPS Coordinates</span>
                </div>
                <div className="gps_boxes">
                  <div className="gps-box">
                    <label>Top Left</label>

                    <input
                      type="number"
                      placeholder="Long"
                      value={addPlot.topLeftLongitude}
                      onChange={(e) =>
                        setAddPlot({
                          ...addPlot,
                          topLeftLongitude: e.target.value,
                        })
                      }
                    />
                    <input
                      type="number"
                      placeholder="Lat"
                      value={addPlot.topLeftLatitude}
                      onChange={(e) =>
                        setAddPlot({
                          ...addPlot,
                          topLeftLatitude: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="gps-box2">
                    <label>Top Right</label>
                    <input
                      type="number"
                      className="m-0"
                      placeholder="Long"
                      value={addPlot.topRightLongitude}
                      onChange={(e) =>
                        setAddPlot({
                          ...addPlot,
                          topRightLongitude: e.target.value,
                        })
                      }
                    />
                    <input
                      type="number"
                      placeholder="Lat"
                      value={addPlot.topRightLatitude}
                      onChange={(e) =>
                        setAddPlot({
                          ...addPlot,
                          topRightLatitude: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="gps_boxes mt-4">
                  <div className="gps-box">
                    <label>Bottom Left</label>

                    <input
                      type="number"
                      placeholder="Long"
                      value={addPlot.bottomLeftLongitude}
                      onChange={(e) =>
                        setAddPlot({
                          ...addPlot,
                          bottomLeftLongitude: e.target.value,
                        })
                      }
                    />
                    <input
                      type="number"
                      placeholder="Lat"
                      value={addPlot.bottomLeftLatitude}
                      onChange={(e) =>
                        setAddPlot({
                          ...addPlot,
                          bottomLeftLatitude: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="gps-box2">
                    <label>Bottom Right</label>

                    <input
                      type="number"
                      className="m-0"
                      placeholder="Long"
                      value={addPlot.bottomRightLongitude}
                      onChange={(e) =>
                        setAddPlot({
                          ...addPlot,
                          bottomRightLongitude: e.target.value,
                        })
                      }
                    />
                    <input
                      type="number"
                      placeholder="Lat"
                      value={addPlot.bottomRightLatitude}
                      onChange={(e) =>
                        setAddPlot({
                          ...addPlot,
                          bottomRightLatitude: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="upload-img-main">
              <div className="img-head">
                <label>
                  Plot images <span>( Max 5 images )</span>
                </label>
              </div>
              <div className="image-uploader">
                {images.length < 5 && (
                  <div className="upload-img">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, images.length)}
                    />
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                )}
                {renderImages()}
              </div>
            </div>
          </div>
        </div>
        <div className="location-save-btn mb-5">
          <Link to={"/plots"}>
            <button className="cancle-btn my-5">Cancel</button>
          </Link>
          <button className="save-btn my-5" onClick={handleAddPlot}>
            Save
          </button>
        </div>
      </div>
      {isSaving && (
        <div className="overlay">
          <Loading />
        </div>
      )}{" "}
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Your Plot has been added successfully"}
      />
    </>
  );
};

export default AddPlotDetails;
