import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LocationHeading from "../../../shared/LocationHeading";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Loading from "../../../shared/Loading";
import { BiSolidError } from "react-icons/bi";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";

export default function ItemDetails() {
  const { id } = useParams(); // Access the "id" parameter from the URL
  const [itemDetails, setItemDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { state } = useUserContext();

  const currentPage = state.currentPage;

  const token = state.token;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItemDetails = async () => {
      setIsLoading(true);
      try {
        const response = await client.get(`admin/get-additionalItem/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("one data : ", response);
        setItemDetails(response.data.aditionalItems);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching location details:", error);
        setIsLoading(false);
      }
    };
    fetchItemDetails();
  }, [id]);

  const handleDelete = () => {
    setShowDeleteModal(true);
    setShowOverlay(true); // Show the overlay
  };

  const confirmDelete = async () => {
    try {
      await client.put(
        `admin/delete-additional-item/${id}?isDeleted=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay
      navigate(`/additionalItems?page=${currentPage}`); // Navigate back to the list page after deletion
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false); // Hide the overlay
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <div className="location_list">
      {showOverlay && <div className="overlay"></div>}
      <LocationHeading
        title={[
          <Link to={`/additionalItems?page=${currentPage}`}>
            <i
              style={{ color: "#000", fontSize: "16px", marginBottom: "10px" }}
              className="fa fa-long-arrow-left me-2"
              aria-hidden="true"></i>
          </Link>,
          "Item Details",
        ]}
      />

      <div className="blog-info-main p-2">
        <Container className="mt-5">
          {itemDetails && (
            <Row>
              <Col lg="4">
                <div className="details-img">
                  {itemDetails.images ? (
                    <div class="view-img">
                      <div className="main_image">
                        <img
                          src={itemDetails.images[selectedImageIndex]}
                          alt=""
                        />
                      </div>
                      <div class="selectable-img">
                        <ul>
                          {itemDetails.images.map((image, index) => (
                            <li
                              key={index}
                              onClick={() => setSelectedImageIndex(index)}
                              className={
                                selectedImageIndex === index ? "selected" : ""
                              }>
                              <img src={image} alt={`Location ${index + 1}`} />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <div>No image available</div>
                  )}
                </div>
              </Col>

              <Col lg="8">
                <div class="about-location">
                  <div class="about-location-head">
                    <div class="about-heading">
                      <h3>{itemDetails.title}</h3>
                      <span>
                        Added on:{" "}
                        {new Date(itemDetails.updatedAt).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                        <span className="mx-1">,</span>
                        <span className="time">
                          {new Date(itemDetails.updatedAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </span>
                      </span>
                    </div>
                    <div class="location-edit">
                      <ul>
                        <li>
                          <Link
                            to={`/additionalItems/edititem/${itemDetails.id}`}
                            style={{ textDecoration: "none" }}>
                            <i
                              class="ri-pencil-line me-2"
                              style={{
                                color: "#BDBDBD",
                                fontSize: "24px",
                                border: "1px solid #E0E0E0",
                                width: "50px",
                                height: "50px",
                                padding: "10px",
                                borderRadius: "50%",
                                textDecoration: "none",
                              }}
                              aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <i
                            onClick={() => handleDelete(itemDetails.id)}
                            class="fa fa-trash-o me-2"
                            style={{
                              color: "#BDBDBD",
                              fontSize: "22px",
                              textAlign: "center",
                              border: "1px solid #E0E0E0",
                              padding: "10px",
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            aria-hidden="true"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="about-price">
                    <span>£{itemDetails.price.toFixed(2)}</span>
                  </div>
                  <div class="about-discription mt-3">
                    <h4>Description</h4>
                    <p>{itemDetails.description}</p>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {showDeleteModal && (
        <Modal
          show={showDeleteModal}
          onHide={() => cancelDelete()}
          className="custom-modal">
          <div className="text-center mt-3">
            <BiSolidError style={{ color: "red", fontSize: "40px" }} />
          </div>
          <Modal.Header
            style={{
              textAlign: "center",
              display: "block",
              border: "none",
              paddingBottom: "0px",
            }}>
            <Modal.Title
              style={{
                fontSize: "18px",
                fontWeight: "700",
                textAlign: "center",
              }}>
              Please confirm!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="text-center p-0 flex-0"
            style={{ color: "#4F4F4F", flex: "0" }}>
            Are you sure you want to delete this Item?
          </Modal.Body>
          <Modal.Footer
            style={{
              border: "0px",
              padding: "0px",
              flex: "none",
            }}
            className="d-flex justify-content-center mt-3">
            <Button
              style={{
                color: "#828282",
                background: "#E0E0E0",
                borderRadius: "0px ",
                border: "none",
                fontSize: "14px",
                fontWeight: "600",
                width: "80px",
              }}
              onClick={() => cancelDelete()}>
              No
            </Button>
            <Button
              style={{
                color: "#fff",
                background: "#219653",
                borderRadius: "0px ",
                border: "none",
                fontSize: "14px",
                fontWeight: "600",
                width: "80px",
              }}
              onClick={() => confirmDelete(itemDetails.id)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
