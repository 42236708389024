import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../../styles/dashboard.css";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { MdOutlineChurch } from "react-icons/md";
import { PiHamburgerDuotone } from "react-icons/pi";
import { BsBuildings } from "react-icons/bs";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import CustomModal from "../../../shared/CustomModal";
import client from "../../../services/Client";

const LocationDeatils = () => {
  const { id } = useParams(); // Access the "id" parameter from the URL
  const [locationDetails, setLocationDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const { state } = useUserContext();
  const navigate = useNavigate();
  const currentPage = state.currentPage;

  // const token = state.token;

  useEffect(() => {
    const fetchLocationDetails = async () => {
      setIsLoading(true);
      try {
        const response = await client.get(`admin/location/${id}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        console.log(response.data.data);
        setLocationDetails(response.data.data); // Assuming the API response contains the location details
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching location details:", error);
        setIsLoading(false); // Stop loading even in case of an error
      }
    };
    fetchLocationDetails();
  }, [id]);

  // DELETE API IMPLEMENTATION

  const handleDelete = () => {
    setShowDeleteModal(true);
    setShowOverlay(true);
  };

  const confirmDelete = async () => {
    try {
      await client.put(`admin/delete-location/${id}?isDeleted=true`, null, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay

      navigate(`/locations?page=${currentPage}`); // Navigate back to the list page after deletion
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false); // Hide the overlay
  };

  const iconStyle = {
    fontWeight: "bold",
    fontSize: "22px",
    color: "#219653",
  };
  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading
          title={[
            <Link to={`/locations?page=${currentPage}`}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Location Detail",
          ]}
        />
        {isLoading ? ( // Display loading animation while data is being fetched
          <div className="overlay">
            <Loading />
          </div>
        ) : (
          <div className="blog-info-main p-2">
            <Container className="mt-5">
              {locationDetails && (
                <Row>
                  <Col lg="4">
                    <div className="details-img">
                      {locationDetails.images ? (
                        <div class="view-img">
                          <div className="main_image">
                            <img
                              src={locationDetails.images[selectedImageIndex]}
                              alt=""
                            />
                          </div>
                          <div class="selectable-img">
                            <ul>
                              {locationDetails.images.map((image, index) => (
                                <li
                                  key={index}
                                  onClick={() => setSelectedImageIndex(index)}
                                  className={
                                    selectedImageIndex === index
                                      ? "selected"
                                      : ""
                                  }>
                                  <img
                                    src={image}
                                    alt={`Location ${index + 1}`}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div>No image available</div>
                      )}
                    </div>
                  </Col>

                  <Col lg="8">
                    <div class="about-location">
                      <div class="about-location-head">
                        <div class="about-heading">
                          <h3>
                            {locationDetails.siteName} (
                            {locationDetails.sortName})
                          </h3>
                          <span>
                            Added on:{" "}
                            {new Date(
                              locationDetails.createdAt
                            ).toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}{" "}
                            <span className="time">
                              {new Date(
                                locationDetails.createdAt
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </span>
                        </div>
                        <div class="location-edit">
                          <ul>
                            <li>
                              <Link
                                to={`/locations/editLocation/${locationDetails.id}`}
                                style={{ textDecoration: "none" }}>
                                <i
                                  class="ri-pencil-line me-2"
                                  style={{
                                    color: "#BDBDBD",
                                    fontSize: "24px",
                                    border: "1px solid #E0E0E0",
                                    width: "50px",
                                    height: "50px",
                                    padding: "10px",
                                    borderRadius: "50%",
                                    textDecoration: "none",
                                  }}
                                  aria-hidden="true"></i>
                              </Link>
                            </li>
                            <li>
                              <i
                                onClick={() => handleDelete(locationDetails.id)}
                                class="fa fa-trash-o me-2"
                                style={{
                                  color: "#BDBDBD",
                                  fontSize: "22px",
                                  textAlign: "center",
                                  border: "1px solid #E0E0E0",
                                  padding: "10px",
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                aria-hidden="true"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="about-discription mt-5">
                        <h4>Description</h4>
                        <p>{locationDetails.description}</p>
                      </div>
                      <div class="about-phases mt-5">
                        <h4>Phases:</h4>
                        <ul>
                          {locationDetails.phases.map((phase, index) => (
                            <li key={index}>
                              <Link>{phase}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div class="about-phases">
                        <h4>Sub phases:</h4>
                        <ul>
                          {locationDetails.subPhases.map((subPhase, index) => (
                            <li key={index}>
                              <Link>{subPhase}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div class="address">
                        <span>Address:</span>
                        <p>{locationDetails.locationAddress.address.address}</p>
                      </div>
                      <div class="area">
                        <span>Details :</span>
                        <div class="amenities-details mb-2 w-100">
                          <div class="amenities-head mt-3">
                            <label style={{ fontSize: "16px" }}>Location</label>
                            <p style={{ fontSize: "16px" }}>
                              {locationDetails.locationAddress.location}
                            </p>
                          </div>
                        </div>
                        <div class="amenities-details w-100">
                          <div class="amenities-head">
                            <label style={{ fontSize: "16px" }}>
                              Biodiversity
                            </label>
                            <p style={{ fontSize: "16px" }}>
                              {locationDetails.locationAddress.biodiversity}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="area">
                        <span>Access and Surroundings :</span>
                        <div class="amenities-details mb-2  w-100">
                          <div class="amenities-head mt-3">
                            <label style={{ fontSize: "16px" }}>
                              Transport
                            </label>
                            <p style={{ fontSize: "16px" }}>
                              {locationDetails.locationAddress.transport}
                            </p>
                          </div>
                        </div>
                        <div class="amenities-details w-100">
                          <div class="amenities-head">
                            <label style={{ fontSize: "16px" }}>
                              Nearby Attractions
                            </label>
                            <p style={{ fontSize: "16px" }}>
                              {
                                locationDetails.locationAddress
                                  .nearbyAttractions
                              }
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="Local-amenities">
                        <span>Local Amenities</span>
                        <div class="amenities-details">
                          <div class="amenities-img">
                            <span>
                              <i class="fa fa-bus" aria-hidden="true"></i>
                            </span>
                          </div>
                          <div class="amenities-head">
                            <label>Nearest bus stop:</label>
                            <p>
                              {locationDetails.localAmenities.nearestBustop}
                            </p>
                          </div>
                        </div>
                        <div class="amenities-details">
                          <div class="amenities-img">
                            <span>
                              <i class="fa fa-train" aria-hidden="true"></i>
                            </span>
                          </div>
                          <div class="amenities-head">
                            <label>Nearest train station:</label>
                            <p>
                              {
                                locationDetails.localAmenities
                                  .nearestTrainStation
                              }
                            </p>
                          </div>
                        </div>
                        <div class="amenities-details">
                          <div class="amenities-img">
                            <span style={iconStyle}>
                              <MdOutlineChurch />
                            </span>
                          </div>
                          <div class="amenities-head">
                            <label>Nearest church:</label>
                            <p>
                              {locationDetails.localAmenities.nearestChurch}
                            </p>
                          </div>
                        </div>
                        <div class="amenities-details">
                          <div class="amenities-img">
                            <span style={iconStyle}>
                              <PiHamburgerDuotone />
                            </span>
                          </div>
                          <div class="amenities-head">
                            <label>Nearest pub for food:</label>
                            <p>
                              {locationDetails.localAmenities.nearestPubFood}
                            </p>
                          </div>
                        </div>
                        <div class="amenities-details">
                          <div class="amenities-img">
                            <span style={iconStyle}>
                              <BsBuildings />
                            </span>
                          </div>
                          <div class="amenities-head">
                            <label>Nearby accommodation:</label>
                            <p>
                              {
                                locationDetails.localAmenities
                                  .nearestAccomodation
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        )}
      </div>
      {showDeleteModal && <div className="overlay"></div>}
      <CustomModal
        isOpen={showDeleteModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to delete this Location?"
        onConfirmDelete={() => confirmDelete(locationDetails.id)}
      />
    </>
  );
};

export default LocationDeatils;
