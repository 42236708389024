import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { PiHamburgerDuotone } from "react-icons/pi";
import { MdOutlineChurch } from "react-icons/md";
import { BsBuildings } from "react-icons/bs";
import axios from "axios";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import Loading from "../../../shared/Loading";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";
import { toast } from "react-toastify";

function EditLocation() {
  const [images, setImages] = useState([]);
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const { state } = useUserContext();

  let navigate = useNavigate();
  const token = state.token;

  const { id } = useParams();

  const [editLocation, setEditLocation] = useState({
    biodiversity: "",
    createdAt: new Date().toISOString(),
    description: "",
    images: [],
    location: "",
    nearbyAttractions: "",
    nearestAccomodation: "",
    nearestBustop: "",
    nearestChurch: "",
    nearestPubFood: "",
    nearestTrainStation: "",
    placeId: "",
    siteName: "",
    sortName: "",
    surroundingArea: "",
    text: "",
    transport: "",
    updatedAt: new Date().toISOString(),
  });
  useEffect(() => {
    loadLocation();
  }, []);

  const loadLocation = async () => {
    setIsLoading(true);
    const response = await client.get(`admin/location/${id}`, {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    const loadedLocation = response.data;

    setEditLocation(() => ({
      biodiversity: loadedLocation.data.locationAddress.biodiversity,
      createdAt: loadedLocation.data.createdAt,
      description: loadedLocation.data.description,
      location: loadedLocation.data.locationAddress.location,
      nearbyAttractions: loadedLocation.data.locationAddress.nearbyAttractions,
      nearestAccomodation:
        loadedLocation.data.localAmenities.nearestAccomodation === "--"
          ? ""
          : loadedLocation.data.localAmenities.nearestAccomodation,
      nearestBustop:
        loadedLocation.data.localAmenities.nearestBustop === "--"
          ? ""
          : loadedLocation.data.localAmenities.nearestBustop,
      nearestChurch:
        loadedLocation.data.localAmenities.nearestChurch === "--"
          ? ""
          : loadedLocation.data.localAmenities.nearestChurch,
      nearestPubFood:
        loadedLocation.data.localAmenities.nearestPubFood === "--"
          ? ""
          : loadedLocation.data.localAmenities.nearestPubFood,
      nearestTrainStation:
        loadedLocation.data.localAmenities.nearestTrainStation === "--"
          ? ""
          : loadedLocation.data.localAmenities.nearestTrainStation,
      placeId: null,
      siteName: loadedLocation.data.siteName,
      sortName: loadedLocation.data.sortName,
      surroundingArea: loadedLocation.data.locationAddress.surroundingArea,
      text: loadedLocation.data.locationAddress.address.address,
      transport: loadedLocation.data.locationAddress.transport,
      updatedAt: loadedLocation.data.updatedAt,
    }));

    setSelectedLocationName({
      value: loadedLocation.data.locationAddress.address.address,
      label: loadedLocation.data.locationAddress.address.address,
    });
    setIsLoading(false);

    const loadedImages = loadedLocation.data.images; // Assuming an array of base64 image strings
    // {"url":"","file":""}
    const imageArray = loadedImages.map((img) => ({ url: img }));

    setImages(imageArray);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!editLocation.siteName) {
      toast.error("Please enter the Site Location/Name.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!editLocation.sortName) {
      toast.error("Please enter the Sort Name.");
      return;
    }
    if (!editLocation.description) {
      toast.error("Description can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!editLocation.text) {
      toast.error("Address can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!editLocation.location) {
      toast.error("Location can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!editLocation.biodiversity) {
      toast.error("Biodiversity can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!editLocation.transport) {
      toast.error("Transportation can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!editLocation.nearbyAttractions) {
      toast.error("Nearby Attractions can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
    }
    // if (!addLocation.surroundingArea) {
    //   toast.error("Surrounding area can not be empty.", {
    //     toastClassName: "my-toast-body-light",
    //   });
    //   return;
    // }
    if (images.length === 0) {
      toast.error("Please upload at least one image.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();

      for (const key in editLocation) {
        formData.append(
          key,
          editLocation[key] === "" ? "--" : editLocation[key]
        );
      }

      const previousImagesUrl = [];
      images.forEach((img) => {
        if (img.file != null) {
          formData.append("images", img.file);
        } else {
          formData.append("images", img.url);
        }
      });

      // formData.append("images", previousImagesUrl);

      const updatedLocation = {
        ...editLocation,
        images: previousImagesUrl,
      };

      await api.updateLocation(id, formData, token);
      setIsSuccessModalOpen(true);
      setIsLoading(true); // Hide loading animation
      openSuccessModal(true);
      setEditLocation(updatedLocation); // Update local state after successful upload
    } catch (error) { 
      console.log(error);
    }
  };

  const handleImageUpload = (event, index) => {
    const newImages = [...images];
    newImages[index] = {
      url: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    };
    setImages(newImages);
  };

  const handleImageDelete = async (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const renderImages = () => {
    if (images && images.length > 0) {
      return images.map((image, index) => (
        <div className="upload-img" key={index}>
          <div className="image-container">
            <img src={image.url} alt={`Uploaded ${index}`} />
            <span
              className="delete-icon-image"
              onClick={() => handleImageDelete(index)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      ));
    } else {
      return null;
    }
  };

  const fetchLocationOptions = async (inputValue) => {
    if (!inputValue) {
      return;
    }
    try {
      const response = await client.get(`placeId`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "ngrok-skip-browser-warning": "69420",
        },
        params: {
          input: inputValue,
        },
      });
      const options = response.data.data.map((location) => ({
        value: location.place_id,
        label: location.description,
      }));
      return options;
    } catch (error) {
      console.error("Error fetching location options:", error);
    }
  };
  const handleLocationSelect = (selectedOption) => {
    setSelectedLocationName(selectedOption);
    if (selectedOption) {
      setEditLocation({
        ...editLocation,
        text: selectedOption.label,
        placeId: selectedOption.value,
      });
    } else {
      setEditLocation({
        ...editLocation,
        text: "",
        placeId: null,
      });
    }
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/locations");
  };

  const customComponents = {
    DropdownIndicator: (props) => {
      return (
        <components.DropdownIndicator {...props}>
          {isLoadingLocations ? (
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          )}
        </components.DropdownIndicator>
      );
    },
  };

  const handleInputChange = (inputValue) => {
    // setHasUserTyped(true);
    setIsLoadingLocations(true);
    // Set a new timeout to fetch location options after a short delay
    setTimeout(() => {
      fetchLocationOptions(inputValue)
        .then((options) => {
          setLocationOptions(options);
        })
        .catch((error) =>
          console.error("Error fetching location options:", error)
        )
        .finally(() => {
          setIsLoadingLocations(false); // Clear loading state
        });
    }, 1000); // Reduced timeout value
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 500,
      paddingLeft: 0,
      height: 50,
    }),
  };

  const iconStyle = {
    fontWeight: "bold",
    fontSize: "22px",
    color: "#bdbdbd",
  };

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/locations"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Edit Location Details",
          ]}
        />
        <div className="location-info-head">
          <h3>Location information</h3>
        </div>
        {isLoading ? (
          <div className="overlay">
            <Loading />
          </div> // Show loading animation while loading
        ) : (
          <form action="">
            <div className="location-all-details">
              <div className="site-location">
                <div className="input-field">
                  <label>Site Location/Name</label>
                  <input
                    type="text"
                    placeholder="Add your site Name..."
                    name="name"
                    value={editLocation.siteName}
                    onChange={(e) =>
                      setEditLocation({
                        ...editLocation,
                        siteName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-field">
                  <label>Sort Name</label>
                  <input
                    type="text"
                    placeholder="Add a short name fro site..."
                    value={editLocation.sortName}
                    onChange={(e) =>
                      setEditLocation({
                        ...editLocation,
                        sortName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="location-discription">
                <label>Description</label>
                <textarea
                  placeholder="Type something..."
                  value={editLocation.description}
                  onChange={(e) =>
                    setEditLocation({
                      ...editLocation,
                      description: e.target.value,
                    })
                  }></textarea>
              </div>
              <div class="amenities-address">
                <span>Location address</span>
                <div class="input-group-Location">
                  <label>Address</label>
                  <Select
                    className="location_search-select"
                    value={selectedLocationName}
                    onChange={handleLocationSelect}
                    onInputChange={handleInputChange}
                    styles={customStyles}
                    options={locationOptions}
                    placeholder="Search for a location name..."
                    isSearchable
                    isClearable
                    components={customComponents}
                  />
                </div>
              </div>
              <div class="amenities-address mt-3">
                <span>Details</span>
                <div className="site-location_one">
                <div className="input-textarea">
                  <label>Location</label>
                  <textarea
                    type="text"
                    placeholder="Add your Location"
                    value={editLocation.location}
                    onChange={(e) =>
                      setEditLocation({
                        ...editLocation,
                        location: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-textarea">
                  <label>Biodiversity</label>
                  <textarea
                    type="text"
                    placeholder="Add a Biodiversity name"
                    value={editLocation.biodiversity}
                    onChange={(e) =>
                      setEditLocation({
                        ...editLocation,
                        biodiversity: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              </div>
              <div class="amenities-address my-5">
              <span>Access and Surroundings</span>
              <div className="site-location_one">
                <div className="input-textarea">
                  <label>Transport</label>
                  <textarea
                    type="text"
                    placeholder="Transportation.."
                    value={editLocation.transport}
                    onChange={(e) =>
                      setEditLocation({
                        ...editLocation,
                        transport: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-textarea">
                  <label>Nearby Attractions</label>
                  <textarea
                    type="text"
                    placeholder="Nearby attraction"
                    value={editLocation.nearbyAttractions}
                    onChange={(e) =>
                      setEditLocation({
                        ...editLocation,
                        nearbyAttractions: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
              <div class="amenities-address">
                <span>Local Amenities</span>
                <div class="input-group">
                  <label>Nearest Bus Stop</label>
                  <div class="amenities-img">
                    <span>
                      <i class="fa fa-bus" aria-hidden="true"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Enter bus stop"
                      value={editLocation.nearestBustop}
                      onChange={(e) =>
                        setEditLocation({
                          ...editLocation,
                          nearestBustop: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div class="input-group">
                  <label>Nearest Train Station</label>
                  <div class="amenities-img">
                    <span>
                      <i class="fa fa-train" aria-hidden="true"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Enter train stop"
                      value={editLocation.nearestTrainStation}
                      onChange={(e) =>
                        setEditLocation({
                          ...editLocation,
                          nearestTrainStation: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div class="input-group">
                  <label>Nearest Church</label>
                  <div class="amenities-img">
                    <span style={iconStyle}>
                      <MdOutlineChurch />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter Church"
                      value={editLocation.nearestChurch}
                      onChange={(e) =>
                        setEditLocation({
                          ...editLocation,
                          nearestChurch: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div class="input-group">
                  <label>Nearby Pub for Food</label>
                  <div class="amenities-img">
                    <span style={iconStyle}>
                      <PiHamburgerDuotone />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter Pub for Food"
                      value={editLocation.nearestPubFood}
                      onChange={(e) =>
                        setEditLocation({
                          ...editLocation,
                          nearestPubFood: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div class="input-group">
                  <label>Nearest Accommodation</label>
                  <div class="amenities-img">
                    <span style={iconStyle}>
                      <BsBuildings />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter Accommodation"
                      value={editLocation.nearestAccomodation}
                      onChange={(e) =>
                        setEditLocation({
                          ...editLocation,
                          nearestAccomodation: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div class="upload-img-main">
                <div class="img-head">
                  <label>
                    Location images <span>( Max 5 images )</span>
                  </label>
                </div>
                <div className="image-uploader">
                  {images.length < 5 && (
                    <div className="upload-img">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, images.length)}
                      />
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </div>
                  )}
                  {renderImages()}
                </div>
              </div>
              <div class="location-save-btn">
                <Link to={"/locations"}>
                  <button class="cancle-btn">Cancel</button>
                </Link>
                <button onClick={onSubmit} type="submit" class="save-btn">
                  Save
                </button>
              </div>
              {isLoading && (
                <div className="overlay">
                  <Loading />
                </div>
              )}{" "}
            </div>
          </form>
        )}
      </div>
      {isSuccessModalOpen && <div className="overlay"></div>}

      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage="Success!"
        ModalMessage="The location details have been successfully updated."
      />
    </>
  );
}

export default EditLocation;
