import React, { useEffect, useState } from "react";
import LocationHeading from "../../shared/LocationHeading";
// import growUp from "../../assets/Images/grow_up.svg";
// import pendingIcon from "../../assets/Images/pending_icon.svg";
// import downIcon from "../../assets/Images/down.svg";
// import orderIcon from "../../assets/Images/order_.svg";
import dashAgent from "../../assets/Images/dash_agent_icon.svg";
import dashUser from "../../assets/Images/Dash_user_icon.svg";
// import rightIcon from "../../assets/Images/angle_right_icno.svg";
import noMeeting from "../../assets/Images/nomeeting.svg";
// import { Link } from "react-router-dom";
import videoIcon from "../../assets/Images/video_icon.svg";
import watchIcon from "../../assets/Images/watch_icon.svg";
import axios from "axios";
import { format } from "date-fns";

import "react-calendar/dist/Calendar.css";
import "../../styles/dashboard.css";
import Chart from "react-apexcharts";
import Loading from "../../shared/Loading";
import { useUserContext } from "../../components/context/UserContext";
import client from "../../services/Client";
import Calendar from "react-calendar";

export default function Dashboard() {
  const [counts, setCounts] = useState({});
  const [events, setEvents] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [earningLoading, setEarningLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const { state } = useUserContext();
  const [selectedYear, setSelectedYear] = useState("");
  const [chartData, setChartData] = useState([]);
  const [eventDate, setEventDate] = useState(new Date()); // Filter value for from date

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 3 }, (_, index) => currentYear - index);

  const token = state.token;

  const options = {
    chart: {
      id: "scrolling-chart",
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
      pan: {
        enabled: true,
        mode: "x",
      },
      toolbar: {
        show: true,
      },
    },
    xaxis: {
      categories: chartData.monthlyEarnings?.map((entry) => entry.month) || [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return formatValue(val);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `£${val.toFixed(2)}`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      offsetY: -10,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
        colors: ["#fff"],
      },
      formatter: function (val) {
        return formatValue(val);
      },
    },
  };

  const series = [
    {
      name: "Earning",
      data: chartData.monthlyEarnings
        ? chartData.monthlyEarnings.map((entry) => entry.earning)
        : [],
    },
  ];

  function formatValue(value) {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "m";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "k";
    } else {
      return value.toFixed(1);
    }
  }

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);
    fetchEarning(selectedYear);
  };

  useEffect(() => {
    setSelectedYear(new Date().getFullYear().toString());
  }, []);

  const fetchEarning = async (
    selectedYear = new Date().getFullYear().toString()
  ) => {
    setEarningLoading(true);
    try {
      const response = await client.get(`admin/monthly-earnings`, {
        params: {
          year: selectedYear,
        },
      });
      const chartData = response.data;
      const totalEarnings = chartData.monthlyEarnings.reduce(
        (total, month) => total + month.earning,
        0
      );
      setChartData(totalEarnings);
    } catch (error) {
      console.error("Error fetching chart data:", error);
      setEarningLoading(false);
    } finally {
      setEarningLoading(false);
    }
  };

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${state.token}`;
  }, [state.token]);

  const fetchOrder = async () => {
    setIsLoading(true);
    try {
      const response = await client.get("admin/all-orders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const orderData = response.data.data.orders;
      console.log("orders are :", orderData);
      if (orderData) {
        orderData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setOrders(orderData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching order:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchEarning(selectedYear);
  }, [selectedYear]);

  const fetchCountData = async () => {
    setIsLoading(true);
    try {
      const response = await client.get("admin/get-counts", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const orderData = response.data.data;
      console.log("counts are :", orderData);
      setCounts(orderData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching counts:", error);
      setIsLoading(false);
    }
  };

  const fetchEvent = async () => {
    setEventLoading(true);
    try {
      const formattedEventDate = format(new Date(eventDate), "MM-dd-yyyy");
      const response = await client.get(`admin/get-events`, {
        params: {
          eventDate: formattedEventDate,
        },
      });
      const eventData = response.data.data;
      setEvents(eventData);
      setEventLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      setEventLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
    fetchCountData();
  }, []);

  useEffect(() => {
    if (eventDate) {
      fetchEvent();
    }
  }, [eventDate]);

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading title={"Dashboard"} />
        <div class="dashboard-main">
          <div class="dashboard-left">
            <div class="dashboard-main-details">
              <div class="dashboard-details-left">
                <div class="dashboard-main-col">
                  {/* <div class="dashboard-details-col">
                    <div class="dashboard-details-heading p-spacing">
                      <div class="main-heading">
                        <span>Pending orders</span>
                        <h3>{counts.orderWithoutDocCount}</h3>
                        <p>
                          {counts.orderWithoutDocPercentage <= 0 ? (
                            <img src={downIcon} alt="" />
                          ) : (
                            <img src={growUp} alt="" />
                          )}
                          <span
                            className={
                              counts.orderWithoutDocPercentage <= 0
                                ? "red-text"
                                : "green-text"
                            }>
                            {parseFloat(
                              counts.orderWithoutDocPercentage
                            ).toFixed(1)}
                            %
                          </span>
                          <span className="block-content">Since last week</span>
                        </p>
                      </div>
                      <div class="details-img">
                        <span>
                          <img src={pendingIcon} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dashboard-details-col">
                    <div class="dashboard-details-heading p-spacing">
                      <div class="main-heading">
                        <span>Completed orders</span>
                        <h3>{counts.orderWithDocCount}</h3>
                        <p>
                          {counts.orderWithDocPercentage <= 0 ? (
                            <img src={downIcon} alt="" />
                          ) : (
                            <img src={growUp} alt="" />
                          )}
                          <span
                            className={
                              counts.orderWithDocPercentage <= 0
                                ? "red-text"
                                : "green-text"
                            }>
                            {parseFloat(counts.orderWithDocPercentage).toFixed(
                              1
                            )}
                            %
                          </span>
                          <span className="block-content">Since last week</span>
                        </p>
                      </div>
                      <div class="details-img">
                        <span>
                          <img src={orderIcon} alt="" />
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div class="dashboard-details-col">
                    <div class="dashboard-details-heading">
                      <div class="main-heading">
                        <span>Sales Agent</span>
                        <h3>{counts.salesAgentCount}</h3>
                        {/* <p>
                          {counts.salesAgentPercentage <= 0 ? (
                            <img src={downIcon} alt="" />
                          ) : (
                            <img src={growUp} alt="" />
                          )}
                            <span
                            className={
                              counts.salesAgentPercentage <= 0
                                ? "red-text"
                                : "green-text"
                            }>
                            {parseFloat(counts.salesAgentPercentage).toFixed(1)}
                            %
                          </span>
                          <span className="block-content">Since last week</span>
                        </p>  */}
                      </div>
                      <div class="details-img">
                        <span>
                          <img src={dashAgent} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dashboard-details-col">
                    <div class="dashboard-details-heading">
                      <div class="main-heading">
                        <span>Users</span>
                        <h3>{counts.userCount}</h3>
                        {/* <p>
                          {counts.userActivePercentage <= 0 ? (
                            <img src={downIcon} alt="" />
                          ) : (
                            <img src={growUp} alt="" />
                          )}
                            <span
                            className={
                              counts.userActivePercentage <= 0
                                ? "red-text"
                                : "green-text"
                            }>
                            {parseFloat(counts.userActivePercentage).toFixed(1)}
                            %
                          </span>
                          <span className="block-content">Since last week</span>
                        </p>  */}
                      </div>
                      <div class="details-img">
                        <span>
                          <img src={dashUser} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashboard-details-right">
                <div className="dashboard-graph-main">
                  <div class="graph-head">
                    <h2 style={{ margin: "0px" }}>Total Earnings</h2>
                    <div className="year-dropdown">
                      <select
                        style={{
                          height: "30px",
                          padding:"0 10px",
                          background: "none",
                          textIndent: "0px",
                        }}
                        value={selectedYear}
                        onChange={handleYearChange}
                        placeholder="Select a year">
                        {/* Add an option for all years */}
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {earningLoading ? (
                    <div className="upload_loader"></div>
                  ) : (
                    <h3
                      style={{
                        fontWeight: "700",
                        fontSize: "22px",
                        marginTop: "20px",
                      }}>
                      £{chartData.toLocaleString()}
                    </h3>
                  )}

                  {/* <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={300}
                  /> */}
                </div>
              </div>
            </div>
            {/*   <div class="dashboard-order-details">
              <div class="dashboard-counts d-flex justify-content-between align-items-center">
                <h2 style={{ fontSize: "16px", fontWeight: "600" }}>
                  Recent Orders
                </h2>
                <Link to={"/order"}>
                  View All <img className="ms-1" src={rightIcon} alt="" />
                </Link>
              </div>

              <Table size="lg" className="location_list-table">
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    borderBottom: "1px solid #dcdada",
                  }}>
                  <tr>
                    <th style={{ padding: "-1px 0 0 15px" }}>Order ID</th>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Created On</th>
                    <th>Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr>
                      <td style={{ padding: "0 0 0 15px" }}>
                        {order.orderNumber}
                      </td>
                      <td>
                        <table>
                          <tr>
                            <td class="product-img" style={{ border: "none" }}>
                              <img
                                src={order.imageURL}
                                alt=""
                                className="me-2"
                              />
                            </td>
                            <td class="rating" style={{ border: "none" }}>
                              {order.productType}
                              <p>{order.plotCode}</p>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>£{order.price.toFixed(2)}</td>
                      <td className="datetime-cell">
                        {new Date(order.user.updatedAt).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                        <br />
                        <span className="time text-muted">
                          {new Date(order.user.updatedAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </span>
                      </td>
                      <td>
                        {order.documentation.status ? (
                          <button className="status_button-order green">
                            Completed
                          </button>
                        ) : (
                          <button className="status_button-order yellow">
                            Pending
                          </button>
                        )}
                      </td>
                      <td className="text-center">
                        <Link to={`/order/details/${order.id}`}>
                          <i
                            className="fa fa-eye me-2"
                            style={{ color: "#BDBDBD", cursor: "pointer" }}
                            aria-hidden="true"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div> */}
          </div>
          <div class="dashboard-right">
            <div className="calendar-container">
              <Calendar
                onChange={(eventDate) => setEventDate(eventDate)}
                value={eventDate}
                calendarType="US"
                formatShortWeekday={(locale, date) =>
                  date.toLocaleDateString(locale, { weekday: "short" })[0]
                }
              />
            </div>
            {eventLoading ? (
              <div
                style={{ width: "70%" }}
                className="d-flex justify-content-center align-items-center">
                <div className="upload_loader-one"></div>
              </div>
            ) : (
              <div className="admin-meeting-main">
                {!events || events.length === 0 ? (
                  <div className="text-center w-100 fw-bold">
                    <img src={noMeeting} alt="no-meeting" />
                  </div>
                ) : (
                  events.map((event, index) => {
                    if (event.event.status === "canceled") {
                      return null;
                    }

                    const startTime = new Date(event.event.startTime);
                    const endTime = new Date(event.event.endTime);
                    const currentTime = new Date();

                    let status = "Upcoming";
                    let dotColor = "yellow";
                    if (currentTime >= startTime && currentTime <= endTime) {
                      status = "In-Progress";
                      dotColor = "green";
                    } else if (currentTime > endTime) {
                      status = "Completed";
                      dotColor = "red";
                    }

                    const meetingElement = (
                      <div className="admin-meeting-info" key={event.id}>
                        <div className="vertical-line-green"></div>
                        <span style={{ marginTop: "0px !important" }}>
                          Meeting with {event.invitee.name}
                        </span>
                        <div className="meeting-time">
                          <div>
                            <img src={videoIcon} alt="" />
                            {event.event.integrateType} meeting
                          </div>
                          <div>
                            <img src={watchIcon} alt="" />
                            {new Date(event.event.startTime).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}{" "}
                            -{" "}
                            {new Date(event.event.endTime).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </div>
                        </div>
                        <div className="meeting-profile d-flex justify-content-between mt-2 me-2">
                          <div>
                            <span>
                              <img src={event.salesAgent.image} alt="" />
                            </span>
                          </div>
                          <div>
                            <div className={`status-dot ${dotColor}`}></div>
                            <span
                              style={{ fontSize: "12px" }}
                              className="status-badge fw-bold">
                              {status}
                            </span>
                          </div>
                        </div>
                      </div>
                    );

                    // Wrap every 3 meetings in a div with class "meeting-row"
                    if ((index + 1) % 3 === 0) {
                      return (
                        <div key={index} className="meeting-row">
                          {meetingElement}
                        </div>
                      );
                    } else {
                      return meetingElement;
                    }
                  })
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
