import React from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { FaFileCircleExclamation } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { MdAdd } from "react-icons/md";

export default function AddAdditionalItems() {
  const iconStyle = {
    width: "80px",
    height: "80px",
    color: "#d4d4d4",
    textAlign: "center",
  };
  return (
    <>
      <LocationHeading title={"Additional Items"} />
      <div className="addlocation_section">
        <FaFileCircleExclamation style={iconStyle} />
        <p>Additional Items not added yet!</p>
        <Link
          activeClassName="active"
          to={"/additionalItems/details"}
          className="location_button">
          <button>
            <MdAdd />
            Add Items
          </button>
        </Link>
      </div>
    </>
  );
}
