import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate } from "react-router-dom";
import { PiHamburgerDuotone } from "react-icons/pi";
import { MdOutlineChurch } from "react-icons/md";
import { BsBuildings } from "react-icons/bs";
import api from "../../../services/api";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";

const AddLocationDetails = () => {
  const [images, setImages] = useState([]);
  const [imagesFiles, setImageFiles] = useState([]);
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [locationError, setLocationError] = useState("");

  const { state } = useUserContext();

  // const token = state.token;

  useEffect(() => {
    if (isLocationDropdownOpen) {
      // Fetch and update location options based on user input
      fetchLocationOptions(selectedLocationName);
    }
  }, [isLocationDropdownOpen, selectedLocationName]);

  const navigate = useNavigate();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 500,
      paddingLeft: 0,
      height: 50,
    }),
  };

  const fetchLocationOptions = async (inputValue) => {
    if (!inputValue) {
      return;
    }
    try {
      setIsLoading(true);
      setLocationError("Type to search location");
      const response = await client.get(`placeId`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "ngrok-skip-browser-warning": "69420",
        },
        params: {
          input: inputValue,
        },
      });
      const options = response.data.data.map((location) => ({
        value: location.place_id,
        label: location.description,
      }));
      return options;
    } catch (error) {
      console.error("Error fetching location options:", error);
    } finally {
      setIsLoading(false); // Clear loading state
    }
  };

  const [addLocation, setAddLocation] = useState({
    biodiversity: "",
    createdAt: new Date().toISOString(),
    description: "",
    location: "",
    nearbyAttractions: "",
    nearestAccomodation: "",
    nearestBustop: "",
    nearestChurch: "",
    nearestPubFood: "",
    nearestTrainStation: "",
    placeId: "",
    siteName: "",
    sortName: "",
    surroundingArea: "",
    text: "",
    transport: "",
    updatedAt: new Date().toISOString(),
  });

  const handleLocationSelect = (selectedOption) => {
    setSelectedLocationName(selectedOption);

    if (selectedOption) {
      setAddLocation({
        ...addLocation,
        text: selectedOption.label,
        placeId: selectedOption.value,
      });
    } else {
      setAddLocation({
        ...addLocation,
        text: "",
      });
    }
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/locations");
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();

    const updatedLocation = {
      ...addLocation,
      nearestBustop: addLocation.nearestBustop || "--",
      nearestTrainStation: addLocation.nearestTrainStation || "--",
      nearestChurch: addLocation.nearestChurch || "--",
      nearestPubFood: addLocation.nearestPubFood || "--",
      nearestAccomodation: addLocation.nearestAccomodation || "--",
    };

    if (!addLocation.siteName) {
      toast.error("Please enter the Site Location/Name.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!addLocation.sortName) {
      toast.error("Please enter the Sort Name.");
      return;
    }
    if (!addLocation.description) {
      toast.error("Description can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!addLocation.text) {
      toast.error("Address can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!addLocation.location) {
      toast.error("Location can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!addLocation.biodiversity) {
      toast.error("Biodiversity can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!addLocation.transport) {
      toast.error("Transportation can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (!addLocation.nearbyAttractions) {
      toast.error("Nearby Attractions can not be empty.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    // if (!addLocation.surroundingArea) {
    //   toast.error("Surrounding area can not be empty.", {
    //     toastClassName: "my-toast-body-light",
    //   });
    //   return;
    // }
    if (images.length === 0) {
      toast.error("Please upload at least one image.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    try {
      setIsSaving(true);
      const res = await api.addLocation(updatedLocation, imagesFiles);
      if (res.data) {
        setIsSuccessModalOpen(true);
        openSuccessModal(true);
      } else {
        alert("Error adding location");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      setIsSaving(false); // Hide loading animation
    }
  };

  const handleInputChange = (inputValue) => {
    setIsLoadingLocations(true); // Set loading state to true
    // Set a new timeout to fetch location options after a short delay
    setTimeout(() => {
      fetchLocationOptions(inputValue)
        .then((options) => {
          setLocationOptions(options);
        })
        .catch((error) =>
          console.error("Error fetching location options:", error)
        )
        .finally(() => {
          setIsLoadingLocations(false); // Clear loading state
        });
    }, 1000); // Reduced timeout value
  };

  const handleImageUpload = (event, index) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return;
    }
    if (!selectedFile.type.startsWith("image/")) {
      event.target.value = null;
      return;
    }

    const newImages = [...images];
    newImages[index] = URL.createObjectURL(selectedFile);

    const newImageFiles = [...imagesFiles];
    newImageFiles[index] = selectedFile;

    setImages(newImages);
    setImageFiles(newImageFiles);
  };

  const handleImageDelete = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);

    const newImageFiles = [...imagesFiles];
    newImageFiles.splice(index, 1);

    setImages(newImages);
    setImageFiles(newImageFiles);
  };

  const customComponents = {
    DropdownIndicator: (props) => {
      return (
        <components.DropdownIndicator {...props}>
          {isLoadingLocations ? (
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          )}
        </components.DropdownIndicator>
      );
    },
  };

  const renderImages = () => {
    if (images && images.length > 0) {
      return images.map((image, index) => (
        <div className="upload-img" key={index}>
          <div className="image-container">
            <img src={image} alt={`Uploaded ${index}`} />
            <span
              className="delete-icon-image"
              onClick={() => handleImageDelete(index)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      ));
    } else {
      return null; // No images to render
    }
  };

  const iconStyle = {
    fontWeight: "bold",
    fontSize: "22px",
    color: "#bdbdbd",
  };
  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/locations"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Add Location",
          ]}
        />
        <div className="blog-info-main">
          <div className="location-info-head">
            <h3>Location information</h3>
          </div>
          <div className="location-all-details">
            <div className="site-location">
              <div className="input-field">
                <label>Site Location/Name</label>
                <input
                  type="text"
                  placeholder="Add your site Name..."
                  value={addLocation.siteName}
                  onChange={(e) =>
                    setAddLocation({
                      ...addLocation,
                      siteName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input-field">
                <label>Sort Name</label>
                <input
                  type="text"
                  placeholder="Add a short name for site..."
                  value={addLocation.sortName}
                  onChange={(e) =>
                    setAddLocation({
                      ...addLocation,
                      sortName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="location-discription">
              <label>Description</label>
              <textarea
                placeholder="Type something..."
                value={addLocation.description}
                onChange={(e) =>
                  setAddLocation({
                    ...addLocation,
                    description: e.target.value,
                  })
                }></textarea>
            </div>
            <div class="amenities-address">
              <span>Location address</span>
              <div class="input-group-Location">
                <label>Address</label>
                <Select
                  className="location_search-select"
                  value={selectedLocationName}
                  onChange={handleLocationSelect}
                  onInputChange={handleInputChange}
                  options={locationOptions}
                  styles={customStyles}
                  placeholder={"Search for a location name..."}
                  isSearchable
                  isClearable
                  components={customComponents}
                />
              </div>
            </div>
            <div class="amenities-address mt-3">
              <span>Details</span>
              <div className="site-location_one">
                <div className="input-textarea">
                  <label>Location</label>
                  <textarea
                    type="text"
                    placeholder="Add your Location"
                    value={addLocation.location}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        location: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-textarea">
                  <label>Biodiversity</label>
                  <textarea
                    type="text"
                    placeholder="Add a Biodiversity name"
                    value={addLocation.biodiversity}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        biodiversity: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div class="amenities-address my-5">
              <span>Access and Surroundings</span>
              <div className="site-location_one">
                <div className="input-textarea">
                  <label>Transport</label>
                  <textarea
                    type="text"
                    placeholder="Transportation.."
                    value={addLocation.transport}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        transport: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-textarea">
                  <label>Nearby Attractions</label>
                  <textarea
                    type="text"
                    placeholder="Nearby attraction"
                    value={addLocation.nearbyAttractions}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        nearbyAttractions: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div class="amenities-address">
              <span>Local Amenities</span>
              <div class="input-group">
                <label>Nearest Bus Stop</label>
                <div class="amenities-img">
                  <span>
                    <i class="fa fa-bus" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter bus stop"
                    value={addLocation.nearestBustop}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        nearestBustop: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div class="input-group">
                <label>Nearest Train Station</label>
                <div class="amenities-img">
                  <span>
                    <i class="fa fa-train" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter train stop"
                    value={addLocation.nearestTrainStation}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        nearestTrainStation: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div class="input-group">
                <label>Nearest Church</label>
                <div class="amenities-img">
                  <span style={iconStyle}>
                    <MdOutlineChurch />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter Church"
                    value={addLocation.nearestChurch}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        nearestChurch: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div class="input-group">
                <label>Nearby Pub for Food</label>
                <div class="amenities-img">
                  <span style={iconStyle}>
                    <PiHamburgerDuotone />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter Pub for Food"
                    value={addLocation.nearestPubFood}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        nearestPubFood: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div class="input-group">
                <label>Nearest Accommodation</label>
                <div class="amenities-img">
                  <span style={iconStyle}>
                    <BsBuildings />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter Accommodation"
                    value={addLocation.nearestAccomodation}
                    onChange={(e) =>
                      setAddLocation({
                        ...addLocation,
                        nearestAccomodation: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div class="upload-img-main">
              <div class="img-head">
                <label>
                  Location images <span>( Max 5 images )</span>
                </label>
              </div>
              <div className="image-uploader">
                {images.length < 5 && (
                  <div className="upload-img">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, images.length)}
                    />
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                )}
                {renderImages()}
              </div>
            </div>
          </div>
        </div>
        <div class="location-save-btn">
          <Link to={"/locations"}>
            <button class="cancle-btn">Cancel</button>
          </Link>
          <button onClick={handleAddLocation} class="save-btn">
            Save
          </button>
        </div>
      </div>
      {isSaving && (
        <div className="overlay">
          <Loading />
        </div>
      )}{" "}
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Your Location has been added successfully"}
      />
    </>
  );
};

export default AddLocationDetails;
