import React, { useEffect, useState } from "react";
import $ from "jquery";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import userIcon from "../../../assets/Images/user_icon.png";
import userIconGreen from "../../../assets/Images/user_icon_green.svg";
import passwordIcon from "../../../assets/Images/password_icon.svg";
import passwordIconGreen from "../../../assets/Images/password_icon_green.svg";
import { useUserContext } from "../../../components/context/UserContext";
import api from "../../../services/api";
import Loading from "../../../shared/Loading";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import client from "../../../services/Client";

function Settings() {
  const { state } = useUserContext();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [adminImage, setAdminImage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const navigate = useNavigate();

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/settings");
  };

  const openChangeModal = () => {
    setIsChangeModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeChangeModal = () => {
    setIsChangeModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    // navigate("/login");
  };
  // const image = state.user.image;
  const id = state.user.id;

  const token = state.token;

  useEffect(() => {
    $(".setting-menu ul li a.profile-btn").click(function () {
      $(this).addClass("active-setting");
      $(".change-password, .setting-profile-main").removeClass("change-active");
      $(".setting-menu ul li a.change-btn").removeClass("change-active");
    });
    $(".setting-menu ul li a.change-btn").click(function () {
      $(this).addClass("change-active");
      $(".change-password, .setting-profile-main").addClass("change-active");
      $(".setting-menu ul li a.profile-btn").removeClass("active-setting");
    });
  });
  const onImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    const imageUrl = URL.createObjectURL(selectedFile);

    // Update the image state with the URL of the selected image
    setImage({
      url: imageUrl,
      file: selectedFile,
    });
  };

  const clearImage = () => {
    setImage(null); // Reset image state
  };

  const [editAdmin, setEditAdmin] = useState({
    email: "",
    firstName: "",
    image: "",
    lastName: "",
  });

  const fetchAdmin = async () => {
    setIsLoading(true);
    try {
      const response = await client.get(`admin/get-admin-data/${id}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      const adminData = response.data.admin;

      setEditAdmin(() => ({
        email: adminData.email,
        firstName: adminData.firstName,
        lastName: adminData.lastName,
      }));

      const loadImage = adminData.image;

      if (loadImage) {
        setImage({
          url: loadImage,
        });
      }
      setAdminImage(adminData.image);

      // console.log("admin data in settings", response.data.admin);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching admin", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAdmin();
  }, []);

  const updateAdminData = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const formData = new FormData();

      for (const key in editAdmin) {
        formData.append(key, editAdmin[key]);
      }
      if (image.file != null) {
        formData.append("image", image.file);
      } else {
        formData.append("image", image.url);
      }
      // formData.append("image", previousImageUrl);

      const updatedAdmin = {
        ...editAdmin,
        image: image.url,
        id: id,
      };

      // Make an API request to update the admin's data
      const response = await api.updateAdmin(id, formData, token);
      setIsLoading(false);
      // console.log(updatedAdmin);
      localStorage.setItem("userData", JSON.stringify(updatedAdmin));
      setIsSuccessModalOpen(true);
      openSuccessModal(true);
      setEditAdmin(updatedAdmin);
    } catch (error) {
      // Handle API request errors, e.g., show an error message
      console.error("Error updating admin data:", error);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword.length < 8) {
      toast.error("New password should be at least 8 words", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password does not matched.", {
        toastClassName: "my-toast-body-light",
      });
      return;
    }

    if (!password) {
      toast.error("Please enter the password.");
      return;
    }

    try {
      setIsLoading(true);
      await api.updatePassword(id, confirmPassword, password, newPassword);
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      // navigate("/login");
      setIsSuccessModalOpen(true);
      openChangeModal(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading title={"Settings"} />
        <div className="setting-main">
          <div className="setting-menu">
            <span>Account setting</span>
            <ul className="p-0">
              <li>
                <Link className="profile-btn active-setting" href="#">
                  <img src={userIcon} alt="" />{" "}
                  <img src={userIconGreen} className="active-icon" alt="" /> My
                  Profile
                </Link>
              </li>
              <li>
                <Link className="change-btn" href="#">
                  <img src={passwordIcon} alt="" />
                  <img
                    src={passwordIconGreen}
                    className="active-icon"
                    alt=""
                  />{" "}
                  Change Password
                </Link>
              </li>
            </ul>
            {/*
            <div className="notification">
              <span>Notifications</span>
              <div className="notification-btn">
                <label className="switch">
                  <input type="checkbox" checked id="toggleButton" />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
          */}
          </div>
          <div className="setting-content">
            <div className="setting-profile-main">
              <h2>My Profile</h2>
              <div class="admin-photo">
                <div className="admin-upload">
                  {!image ? ( // Conditional rendering based on image state
                    <div>
                      <input type="file" onChange={onImageChange} />
                      <div className="upload-icon">
                        <i
                          className="fa fa-cloud-upload"
                          aria-hidden="true"></i>
                        <span>Upload photo</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={image.url}
                        alt="Selected"
                        className="selected-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          padding: "5px",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                      <span className="delete-icon-image" onClick={clearImage}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <Container fluid style={{ margin: "0px", marginTop: "55px" }}>
                <Row className="w-100">
                  <Col className="p-0 name_input">
                    <div className="">
                      <label htmlFor="">First Name</label>
                    </div>
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Jacob"
                      value={editAdmin.firstName}
                      onChange={(e) =>
                        setEditAdmin({
                          ...editAdmin,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col className="pe-0 name_input">
                    <div>
                      <label htmlFor="">Last Name</label>
                    </div>
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Jones"
                      value={editAdmin.lastName}
                      onChange={(e) =>
                        setEditAdmin({
                          ...editAdmin,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="w-100 p-0 name_input mt-4">
                  <div className="p-0">
                    <label htmlFor="">Email</label>
                  </div>
                  <input
                    type="text"
                    style={{ color: "grey" }}
                    placeholder="jacobjones@domain.com"
                    value={editAdmin.email}
                    // onChange={(e) =>
                    //   setEditAdmin({
                    //     ...editAdmin,
                    //     email: e.target.value,
                    //   })
                    // }
                  />
                </Row>
              </Container>

              <div className="setting-save-btn">
                <button onClick={updateAdminData} className="save-chnage">
                  Save Changes
                </button>
              </div>
            </div>

            <div className="change-password">
              <h2 className="password-group">Change Password</h2>
              <Container fluid className="p-0">
                <Row>
                  <Col lg="4">
                    <div className="input-field_pass">
                      <label>Current Password</label>
                      <div className="input-group mt-1">
                        <img src="images/password_eye_icon.svg" alt="" />
                        <input
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${
                            showPassword
                              ? "text-input_setting"
                              : "password-input_setting"
                          }`}
                          placeholder="Current Password"
                          id="exampleInputPassword1"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div
                          class="password-hide"
                          onClick={togglePasswordVisibility}>
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="input-field_pass">
                      <label>New Password</label>
                      <div className="input-group mt-1">
                        <input
                          type={showNewPassword ? "text" : "password"}
                          className={`form-control ${
                            showPassword
                              ? "text-input_setting"
                              : "password-input_setting"
                          }`}
                          placeholder="New Password"
                          id="newPasswordInput"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <div
                          class="password-hide"
                          onClick={toggleNewPasswordVisibility}>
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="input-field_pass">
                      <label>Confirm Password</label>
                      <div className="input-group  mt-1">
                        <img src="images/password_eye_icon.svg" alt="" />
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className={`form-control ${
                            showPassword
                              ? "text-input_setting"
                              : "password-input_setting"
                          }`}
                          placeholder="Confirm Password"
                          id="confirmPasswordInput"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div
                          class="password-hide"
                          onClick={toggleConfirmPasswordVisibility}>
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="setting-save-btn">
                <button onClick={handleChangePassword} className="save-chnage">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Your information has been updated successfully"}
      />
      <LocationSuccessModal
        isOpen={isChangeModalOpen}
        onClose={closeChangeModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Your password has been updated successfully"}
      />
    </>
  );
}

export default Settings;
