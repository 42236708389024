import React, { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Loading from "../../../shared/Loading";
import { toast } from "react-toastify";

const NewMap = ({
  plotData,
  openMergePlotModal,
  areaData,
  change,
  selectedSite,
  handleBreak,
  mapRef,
  selectedCategory,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [plots, setPlots] = useState(true);
  const plotLayerSourceLoaded = useRef(false);
  const hoverPolygonId = useRef(null);
  const allPlotsRef = useRef([]);

  useEffect(() => {
    const plotPolygons = [];
    const allPlots = [...plotData];
    allPlots.forEach((area) => {
      if (
        area.category === selectedCategory &&
        area.siteName === selectedSite
      ) {
        const urnPlots = area.plots;

        urnPlots.forEach((plot) => {
          const { topLeft, topRight, bottomLeft, bottomRight } =
            plot.gpsCoordinates;

          const polygonFeature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [topLeft[0], topLeft[1]],
                  [topRight[0], topRight[1]],
                  [bottomRight[0], bottomRight[1]],
                  [bottomLeft[0], bottomLeft[1]],
                  [topLeft[0], topLeft[1]],
                ],
              ],
            },
            properties: {
              id: area.id,
              type: area.type,
              isAvailable: area.isAvailable,
              areaName: area.areaName,
            },
          };

          plotPolygons.push(polygonFeature);
        });
      }
    });

    const featureCollection = {
      type: "FeatureCollection",
      features: plotPolygons,
    };

    if (
      featureCollection &&
      featureCollection.type === "FeatureCollection" &&
      plotLayerSourceLoaded.current
    ) {
      if (map.current && map.current.getSource("plotLayer")) {
        map.current.getSource("plotLayer").setData(featureCollection);
        allPlotsRef.current = allPlots;
        setPlots(false);
      } else {
        console.error("Error: Map or source not initialized.");
      }
    } else {
      console.error("Error: Invalid feature collection or source not loaded.");
    }

    const urnAreaPolygons = [];
    areaData?.forEach((areaPlot) => {
      const areaCoordinates = areaPlot?.areas?.flatMap((area) =>
        area?.ploygon.map((point) => [point.lng, point.lat])
      );
      if (areaCoordinates?.length > 0) {
        const areaPolygonFeature = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: areaCoordinates,
          },
          properties: {
            id: `${areaPlot.id}`,
            name: areaPlot.name,
          },
        };
        urnAreaPolygons.push(areaPolygonFeature);
      }
    });

    const urnFeatureCollection = {
      type: "FeatureCollection",
      features: urnAreaPolygons,
    };

    if (
      urnFeatureCollection &&
      urnFeatureCollection.type === "FeatureCollection" &&
      plotLayerSourceLoaded.current
    ) {
      if (map.current && map.current.getSource("urnLayerSource")) {
        map.current.getSource("urnLayerSource").setData(urnFeatureCollection);
      } else {
        console.error("Error: Map or source not initialized for urn layer.");
      }
    } else {
      console.error(
        "Error: Invalid feature collection or source not loaded for urn layer."
      );
    }
  }, [plotData, selectedCategory, selectedSite, areaData]);

  const initializeMap = useCallback(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/navigation-day-v1",
        center: [-2.712991366, 54.27270724],
        zoom: 18,
        bearing: 77,
      });

      map.current.on("style.load", () => {
        map.current.addLayer({
          id: "plotLayer",
          type: "fill",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          },
          paint: {
            "fill-color": [
              "case",
              ["boolean", ["get", "isAvailable"], true],
              [
                "match",
                ["get", "type"],
                "Double Plot",
                "#C8D400",
                "3 Family Plot",
                "#20519b",
                "4 Family Plot",
                "orange",
                "5 Family Plot",
                "#b6424c",
                "#219653",
              ],
              "red",
            ],
            "fill-opacity": 0.8,
          },
        });

        map.current.addSource("urnLayerSource", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        map.current.addLayer({
          id: "urnLayer",
          type: "line",
          source: "urnLayerSource",
          paint: {
            "line-color": "#999797",
            "line-width": 2,
          },
        });
        map.current.addSource("overlayLayerSource", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        map.current.addLayer({
          id: "overlayLayer",
          type: "fill",
          source: "overlayLayerSource",
          paint: {
            "fill-color": "rgba(0, 0, 0, 0.5)",
            "fill-opacity": 0,
          },
        });

        map.current.on("click", "plotLayer", (e) => {
          const clickedPlotId = e.features[0].properties.id;

          const area = allPlotsRef?.current?.find(
            (a) => a.id === clickedPlotId
          );

          if (area && !area.isAvailable) {
            return;
          }

          if (area && area.type === "Single Plot") {
            let clickedPlotIds = JSON.parse(
              localStorage.getItem("plotsId") || "[]"
            );

            if (clickedPlotIds.includes(clickedPlotId)) {
              clickedPlotIds = clickedPlotIds.filter(
                (id) => id !== clickedPlotId
              );
            } else {
              clickedPlotIds.push(clickedPlotId);
              if (clickedPlotIds.length > 1) {
                openMergePlotModal();
              }
            }
            if (clickedPlotIds.length > 5) {
              toast.error("You can select upto 5 plots");
              return;
            }

            if (clickedPlotIds.length === 0) {
              map.current.setPaintProperty("plotLayer", "fill-color", [
                "case",
                ["boolean", ["get", "isAvailable"], true],
                [
                  "match",
                  ["get", "type"],
                  "Single Plot",
                  "#219653",
                  "Double Plot",
                  "#C8D400",
                  "3 Family Plot",
                  "#20519b",
                  "4 Family Plot",
                  "orange",
                  "5 Family Plot",
                  "#b6424c",
                  "#219653",
                ],
                "red",
              ]);
            } else {
              map.current.setPaintProperty("plotLayer", "fill-color", [
                "case",
                ["boolean", ["get", "isAvailable"], true],
                [
                  "match",
                  ["get", "id"],
                  clickedPlotIds,
                  "#c9b98b",
                  [
                    "match",
                    ["get", "type"],
                    "Single Plot",
                    "#219653",
                    "Double Plot",
                    "#C8D400",
                    "3 Family Plot",
                    "#20519b",
                    "4 Family Plot",
                    "orange",
                    "5 Family Plot",
                    "#b6424c",
                    "#219653",
                  ],
                ],
                "red",
              ]);
            }

            localStorage.setItem("plotsId", JSON.stringify(clickedPlotIds));
          } else {
            handleBreak(clickedPlotId);
          }
        });

        map.current.on("mousemove", "plotLayer", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: ["plotLayer"],
          });

          if (features.length > 0) {
            const hoveredPlotId = features[0].properties.id;

            const hoveredPlotData = allPlotsRef?.current?.find(
              (area) => area.id === hoveredPlotId
            );

            if (hoveredPlotData) {
              const overlayFeatures = {
                type: "FeatureCollection",
                features: hoveredPlotData.plots.map((plot) => ({
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        plot.gpsCoordinates.topLeft,
                        plot.gpsCoordinates.topRight,
                        plot.gpsCoordinates.bottomRight,
                        plot.gpsCoordinates.bottomLeft,
                        plot.gpsCoordinates.topLeft,
                      ],
                    ],
                  },
                  properties: {
                    id: hoveredPlotData.id,
                  },
                })),
              };

              map.current
                .getSource("overlayLayerSource")
                .setData(overlayFeatures);

              map.current.setPaintProperty("overlayLayer", "fill-opacity", 0.9);

              if (hoverPolygonId.current !== hoveredPlotData.id) {
                if (hoverPolygonId.current !== null) {
                  map.current.setFeatureState(
                    { source: "plotLayer", id: hoverPolygonId.current },
                    { hover: false }
                  );
                }

                hoverPolygonId.current = hoveredPlotData.id;
                map.current.setFeatureState(
                  { source: "plotLayer", id: hoverPolygonId.current },
                  { hover: true }
                );
              }
            }
          }
        });

        map.current.on("mouseleave", "plotLayer", () => {
          map.current.getCanvas().style.cursor = "";

          if (hoverPolygonId.current !== null) {
            map.current.setFeatureState(
              { source: "plotLayer", id: hoverPolygonId.current },
              { hover: false }
            );
            map.current.setPaintProperty("plotLayer", "fill-color", [
              "match",
              ["get", "id"],
              hoverPolygonId.current,
              "Double Plot",
              "#C8D400",
              "3 Family Plot",
              "#20519b",
              "4 Family Plot",
              "orange",
              "5 Family Plot",
              "#b6424c",
              "#219653",
            ]);
            hoverPolygonId.current = null;
          }

          map.current.setPaintProperty("plotLayer", "fill-opacity", 0.8);

          map.current.getSource("overlayLayerSource").setData({
            type: "FeatureCollection",
            features: [],
          });

          map.current.setPaintProperty("overlayLayer", "fill-opacity", 0);
        });

        map.current.getSource("plotLayer").on("data", () => {
          plotLayerSourceLoaded.current = true;
        });
      });
      mapRef.current = map.current;
    }
  }, [mapRef]);

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoidGVxZXhwZXJ0IiwiYSI6ImNsbXlqNTN1bjFna3QyanFwbjh3OGtxbDEifQ.pSHT-I2LeYdboSTPkVHpww";
    initializeMap();
  }, [initializeMap]);

  return (
    <>
      {plots && (
        <div className="overlay">
          <Loading />
        </div>
      )}
      <div style={{ width: "100%", height: "600px" }} ref={mapContainer}></div>
    </>
  );
};

export default NewMap;
