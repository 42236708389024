import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LocationHeading from "../../../shared/LocationHeading";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "../../../shared/Loading";
import { useUserContext } from "../../../components/context/UserContext";
import CustomModal from "../../../shared/CustomModal";
import client from "../../../services/Client";

const PlotDetails = () => {
  const { id } = useParams();
  const [plotDetails, setPlotDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { state } = useUserContext();
  const currentPage = state.currentPage;

  const token = state.token;

  const navigate = useNavigate();

  useEffect(() => {
    $(".selectable-img a").on("click", function (event) {
      event.preventDefault(); // Prevent default link behavior
      $(".selectable-img li").removeClass("selected"); // Remove selected class from all items
      $(this).parent().addClass("selected"); // Add selected class to the clicked item's parent (li)

      var imageUrl = $(this).find("img").attr("src");
      $(".view-img img").attr("src", imageUrl);
    });
  });

  useEffect(() => {
    const fetchPlotDetails = async () => {
      setIsLoading(true);
      try {
        const response = await client.get(`admin/get-plot/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });
        setPlotDetails(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching location details:", error);
        setIsLoading(false);
      }
    };
    fetchPlotDetails();
  }, [id]);

  // DELETE API IMPLEMENTATION

  const handleDelete = () => {
    setShowDeleteModal(true);
    setShowOverlay(true); // Show the overlay
  };

  const confirmDelete = async () => {
    try {
      await client.put(`admin/delete-plot/${id}?isDeleted=true`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShowDeleteModal(false);
      setShowOverlay(false); // Hide the overlay

      navigate(`/plots?page=${currentPage}`); // Navigate back to the list page after deletion
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setShowOverlay(false); // Hide the overlay
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
      <div className="location_list">
        {showOverlay && <div className="overlay"></div>}
        <LocationHeading
          title={[
            <Link onClick={handleGoBack}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Plot Details",
          ]}
        />
        {isLoading ? ( // Display loading animation while data is being fetched
          <div className="overlay">
            <Loading />
          </div>
        ) : (
          <div className="blog-info-main p-2">
            <Container className="mt-5">
              {plotDetails && (
                <Row>
                  <Col lg="4">
                    <div className="details-img">
                      {plotDetails.images ? (
                        <div class="view-img">
                          <div className="main_image">
                            <img
                              src={plotDetails.images[selectedImageIndex]}
                              alt=""
                            />
                          </div>
                          <div class="selectable-img">
                            <ul>
                              {plotDetails.images.map((image, index) => (
                                <li
                                  key={index}
                                  onClick={() => setSelectedImageIndex(index)}
                                  className={
                                    selectedImageIndex === index
                                      ? "selected"
                                      : ""
                                  }>
                                  <img
                                    src={image}
                                    alt={`Location ${index + 1}`}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div>No image available</div>
                      )}
                    </div>
                  </Col>

                  <Col lg="8">
                    <div class="about-location">
                      <div class="about-location-head">
                        <div class="about-heading">
                          <h3>{plotDetails.siteName}</h3>
                          <span>Code: {plotDetails.plotCode}</span>
                          <span className="mx-2 dot_span"></span>
                          <span>
                            Added on:{" "}
                            {new Date(plotDetails.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}{" "}
                            <span className="time">
                              {new Date(
                                plotDetails.createdAt
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </span>
                        </div>
                        <div class="location-edit">
                          {plotDetails.isAvailable === true ? (
                            <ul>
                              <li>
                                <Link
                                  to={`/plots/editplot/${plotDetails.id}`}
                                  style={{ textDecoration: "none" }}>
                                  <i
                                    class="ri-pencil-line me-2"
                                    style={{
                                      color: "#BDBDBD",
                                      fontSize: "24px",
                                      border: "1px solid #E0E0E0",
                                      width: "50px",
                                      height: "50px",
                                      padding: "10px",
                                      borderRadius: "50%",
                                      textDecoration: "none",
                                    }}
                                    aria-hidden="true"></i>
                                </Link>
                              </li>

                              <li>
                                <i
                                  onClick={() => handleDelete(plotDetails.id)}
                                  className="fa fa-trash-o me-2"
                                  style={{
                                    color: "#BDBDBD",
                                    fontSize: "22px",
                                    textAlign: "center",
                                    border: "1px solid #E0E0E0",
                                    padding: "10px",
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  aria-hidden="true"></i>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      </div>
                      <div class="about-price">
                        <span>£{plotDetails.price.toFixed(2)}</span>
                      </div>
                      <div class="about-discription mt-5">
                        <h4>Description</h4>
                        <p className="text-muted">{plotDetails.description}</p>
                      </div>
                      <div class="about-sizes">
                        <ul>
                          <li>
                            <p>Plot Size</p>
                            {plotDetails.plotSize}
                          </li>
                          <li>
                            <p>Plot Number</p>
                            {plotDetails.plotNumber}
                          </li>
                          <li>
                            <p>Phase</p>
                            {plotDetails.phase}
                          </li>
                          <li>
                            <p>Subphase</p>
                            {plotDetails.subPhase}
                          </li>
                        </ul>
                        <ul>
                        <li>
                        <p>Category</p>
                        {plotDetails.type === "Single Plot"
                          ? plotDetails.category.slice(0, -1)
                          : plotDetails.category}
                      </li>
                          <li>
                            <p>Type</p>
                            {plotDetails.type}
                          </li>
                        </ul>
                      </div>
                      <div class="about-gps">
                        <span>GPS Coordinates:</span>
                        <ul>
                          <li>
                            <p>Top Left</p>
                            {plotDetails.gpsCoordinates.topLeft[1]}, <br />
                            {plotDetails.gpsCoordinates.topLeft[0]}
                          </li>
                          <li>
                            <p>Top Right</p>
                            {plotDetails.gpsCoordinates.topRight[1]}, <br />
                            {plotDetails.gpsCoordinates.topRight[0]}
                          </li>
                          <li>
                            <p>Bottom Left</p>
                            {plotDetails.gpsCoordinates.bottomLeft[1]}, <br />
                            {plotDetails.gpsCoordinates.bottomLeft[0]}
                          </li>
                          <li>
                            <p>Bottom Right</p>
                            {plotDetails.gpsCoordinates.bottomRight[1]}, <br />
                            {plotDetails.gpsCoordinates.bottomRight[0]}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        )}
      </div>
      {showDeleteModal && <div className="overlay"></div>}
      <CustomModal
        isOpen={showDeleteModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to delete this Plot?"
        onConfirmDelete={() => confirmDelete(plotDetails.id)}
      />
    </>
  );
};

export default PlotDetails;
