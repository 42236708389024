import React, { useEffect, useState } from "react";
import LocationHeading from "../../../shared/LocationHeading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Table } from "react-bootstrap";
import noData from "../../../assets/Images/NoData.svg";
import {
  setCurrentPage,
  useUserContext,
} from "../../../components/context/UserContext";
import client from "../../../services/Client";
import Loading from "../../../shared/Loading";
import BlockModal from "../../../shared/BlocakModal";
import UnBlockModal from "../../../shared/UnBlockModal";
import NewUnBlockModal from "../../../shared/NewUnBlockModel";
import NewBlockModal from "../../../shared/NewBlockModal";
import CustomModal from "../../../shared/CustomModal";
import DeleteModal from "../../../shared/DeleteModal";

function Roles() {
  const [roles, setRoles] = useState([]);
  const [perPage] = useState(15);
  const [blockLoading, setBlockLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [total, setTotal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useUserContext();
  const [search, setSearch] = useState("");
  const [debouncedTitle, setDebouncedTitle] = useState("");
  const currentPage = state.currentPage;
  const [userToBlock, setUserToBlock] = useState(null);
  const [userToUnBlock, setUserToUnBlock] = useState(null);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showUnBlockModal, setShowUnBlockModal] = useState(false);
  const navigate = useNavigate();
  const [blogToDelete, setBlogToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getCurrentPageFromStorage = () => {
    const storedPage = localStorage.getItem("currentPage");
    return storedPage ? parseInt(storedPage, 10) : 1;
  };

  const setCurrentPageInStorage = (page) => {
    localStorage.setItem("currentPage", page.toString());
  };

  useEffect(() => {
    const storedPage = getCurrentPageFromStorage();
    dispatch({ type: "SET_CURRENT_PAGE", payload: storedPage });
  }, []);

  const fetchBlogs = async () => {
    setIsLoading(true);

    const queryParams = [];

    if (search) {
      queryParams.push(`firstName=${encodeURIComponent(search)}`);
      dispatch(setCurrentPage(1));
    }

    queryParams.push(`page=${currentPage}`);

    const queryString = queryParams.join("&");

    try {
      const response = await client.get(
        `admin/subadmin/get-subadmins?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        },
        {
          params: {
            firstName: debouncedTitle,
            perPage: perPage,
          },
        }
      );

      const blogData = response?.data?.data?.subadminsRes;
      setRoles(blogData);
      setTotal(response.data.data.total);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [currentPage, debouncedTitle]);

  const handleClearInput = () => {
    setSearch("");
    setIsLoading(true);
    navigate(`/rolesandpermission?page=${currentPage}`);
  };

  const getInitials = (firstName, lastName) => {
    const initials = `${firstName.charAt(0)}`.toUpperCase();
    return initials;
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      dispatch({ type: "SET_CURRENT_PAGE", payload: newPage });
      setCurrentPageInStorage(newPage); // Store current page in local storage
      navigate(`/rolesandpermission?page=${newPage}`);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTitle(search);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  const handleBlock = (userId) => {
    const user = roles?.find((user) => user.id === userId);
    setUserToBlock(user);
    setShowBlockModal(true);
    setShowOverlay(true);
  };
  const handleUnBlock = (userId) => {
    const user = roles?.find((user) => user.id === userId);
    setUserToUnBlock(user);
    setShowUnBlockModal(true);
    setShowOverlay(true);
  };

  const confirmBlock = async (userId) => {
    setBlockLoading(true);
    try {
      await client.put(
        `admin/subadmin/block-unblock-subadmin/${userId}?isBlocked=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      setRoles((prevUser) =>
        prevUser.map((user) =>
          user.id === userId ? { ...user, isBlocked: true } : user
        )
      );
      setShowBlockModal(false);
      setShowOverlay(false); // Hide the overlay
      setBlockLoading(false);
    } catch (error) {
      console.error("Error Blocking user:", error);
    } finally {
      setBlockLoading(false);
    }
  };

  const confirmUnBlock = async (userId) => {
    setBlockLoading(true);
    try {
      await client.put(
        `admin/subadmin/block-unblock-subadmin/${userId}?isBlocked=false`,
        null,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      setRoles((prevUser) =>
        prevUser.map((user) =>
          user.id === userId ? { ...user, isBlocked: false } : user
        )
      );
      setShowUnBlockModal(false);
      setShowOverlay(false);
      setBlockLoading(false);
    } catch (error) {
      console.error("Error Un-blocking user:", error);
    } finally {
      setBlockLoading(false);
    }
  };

  const handleDelete = (blogId) => {
    const blog = roles.find((blog) => blog.id === blogId);
    setBlogToDelete(blog);
    setShowDeleteModal(true);
    setShowOverlay(true);
  };

  const confirmDelete = async (blogId) => {
    setBlockLoading(true);
    try {
      await client.delete(`/admin/subadmin/delete-subadmin/${blogId}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setRoles((prevBlogs) => prevBlogs.filter((blog) => blog.id !== blogId));
      setShowDeleteModal(false);
      setShowOverlay(false);
      setBlockLoading(false);
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog:", error);
      setBlockLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowBlockModal(false);
    setShowDeleteModal(false);
    setShowUnBlockModal(false);
    setShowOverlay(false);
  };

  return (
    <div className="location_list">
      {showOverlay && <div className="overlay"></div>}
      <LocationHeading title={"Sub-Admin"} />
      <div class="loaction-list-main">
        <div class="loaction-list-head">
          <div class="search-bar">
            <input
              type="text"
              value={search}
              placeholder="Search by name"
              onChange={(e) => setSearch(e.target.value)}
            />

            {search && (
              <i
                class="fa fa-times-circle clear-icon"
                aria-hidden="true"
                onClick={handleClearInput}
                style={{ cursor: "pointer" }}></i>
            )}
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
          <div class="add-location">
            <button>
              <Link to={"/rolesandpermission/add-role"}>
                <i class="fa fa-plus me-2" aria-hidden="true"></i>
                Add Role
              </Link>
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className="overlay">
            <Loading />
          </div>
        ) : (
          <Table size="lg" className="location_list-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Updated On</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {roles?.length === 0 ||
              (debouncedTitle &&
                roles?.every(
                  (role) =>
                    !role.firstName
                      .toLowerCase()
                      .includes(debouncedTitle.toLowerCase())
                )) ? (
                <tr>
                  <td colSpan="6" className="text-center  no_data-image">
                    {debouncedTitle ? (
                      <img
                        src={noData}
                        alt="No Data"
                        width={"100%"}
                        height="100%"
                      />
                    ) : (
                      <img
                        src={noData}
                        alt="No Data"
                        width={"100%"}
                        height="100%"
                      />
                    )}
                  </td>
                </tr>
              ) : (
                roles?.map((role, index) => (
                  <tr key={index}>
                  <td>
                    <table>
                      <tr>
                        <td class="product-img" style={{ border: "none" }}>
                          {role?.image ? (
                            <img src={role?.image} alt="agent" />
                          ) : (
                            <div className="placeholder-name">
                              {getInitials(role?.firstName)}
                            </div>
                          )}
                        </td>
                        <td class="rating" style={{ border: "none" }}>
                          {role?.firstName} {role?.lastName}
                        </td>
                      </tr>
                    </table>
                    </td>
                    <td>{role?.email}</td>
                    <td className="datetime-cell">
                      {new Date(role.updatedAt).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                      <br />
                      <span className="time text-muted">
                        {new Date(role.updatedAt).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </td>
                    <td>
                      <button
                        className={`status_button ${
                          !role?.isBlocked ? "published" : "user-blocked"
                        }`}>
                        {!role?.isBlocked ? "Active" : "Blocked"}
                      </button>
                    </td>
                    <td>
                      <Dropdown className="details_dropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                          <button>
                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                          </button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <Link to={`/rolesandpermission/details/${role.id}`}>
                              <i
                                className="fa fa-eye me-2"
                                style={{ color: "yellow" }}
                                aria-hidden="true"></i>
                              View Details
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link onClick={() => handleDelete(role.id)}>
                              <i
                                className="fa fa-trash me-2"
                                style={{ color: "red" }}
                                aria-hidden="true"></i>
                              Delete
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link
                              to={`/rolesandpermission/details/${role.id}?editable=true`}
                              style={{ color: "#000000" }}>
                              <i
                                className="ri-pencil-line me-2"
                                style={{ color: "green" }}
                                aria-hidden="true"></i>
                              Edit
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            {role?.isBlocked === true ? (
                              <Link onClick={() => handleUnBlock(role?.id)}>
                                <i
                                  className="fa fa-user-plus me-2"
                                  style={{ color: "#219653" }}
                                  aria-hidden="true"></i>
                                Un-Block
                              </Link>
                            ) : (
                              <Link onClick={() => handleBlock(role?.id)}>
                                <i
                                  className="fa fa-ban me-2"
                                  style={{ color: "#bdbdbd" }}
                                  aria-hidden="true"></i>
                                Block
                              </Link>
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
        <div className="next-previous-slide">
          <div className="slides-heading">
            <span>
              Showing {(currentPage - 1) * perPage + 1} to{" "}
              {Math.min(currentPage * perPage, total)} out of {total}
            </span>
          </div>

          <div className="slides-button">
            <ul className="d-flex justify-center align-items-center">
              <li>
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}>
                  <i
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ cursor: "pointer", color: "#333" }}></i>
                </button>
              </li>
              <li className="d-flex justify-center align-items-center">
                {Array.from({ length: Math.ceil(total / perPage) }).map(
                  (_, index) => {
                    // Add logic to display ellipsis (...) if there are many pages
                    if (
                      index < 2 ||
                      index > Math.ceil(total / perPage) - 3 ||
                      (index >= currentPage - 2 && index <= currentPage + 1)
                    ) {
                      return (
                        <button
                          key={index + 1}
                          onClick={() => handlePageChange(index + 1)}
                          className={currentPage === index + 1 ? "active" : ""}>
                          {index + 1}
                        </button>
                      );
                    } else if (
                      index === 2 ||
                      index === Math.ceil(total / perPage) - 3
                    ) {
                      // Display ellipsis (...) for the third and third-to-last position
                      return <span key={index}>...</span>;
                    }
                    return null;
                  }
                )}
              </li>
              <li>
                <button
                  disabled={currentPage === Math.ceil(total / perPage)}
                  onClick={() => handlePageChange(currentPage + 1)}>
                  <i
                    className="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showBlockModal && <div className="overlay"></div>}
      <NewBlockModal
        isOpen={showBlockModal}
        onClose={cancelDelete}
        isLoading={blockLoading}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to Block?"
        onConfirmBlock={() => confirmBlock(userToBlock.id)}
      />
      {showUnBlockModal && <div className="overlay"></div>}
      <NewUnBlockModal
        isOpen={showUnBlockModal}
        onClose={cancelDelete}
        isLoading={blockLoading}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to Un-Block?"
        onConfirmUnBlock={() => confirmUnBlock(userToUnBlock.id)}
      />
      {showDeleteModal && <div className="overlay"></div>}
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={cancelDelete}
        isLoading={blockLoading}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to delete?"
        onConfirmDelete={() => confirmDelete(blogToDelete.id)}
      />
    </div>
  );
}

export default Roles;
