import "./App.css";
import "./Pages/admin/admin.css";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import AddLocationDetails from "./Pages/Dashboard/location/AddLocationDetails";
import LocationDetails from "./Pages/Dashboard/location/LocationDeatils";
import Login from "./Pages/Login/Login";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import VerifyOTP from "./Pages/Login/VerifyOTP";
import ConfirmPassword from "./Pages/Login/ConfirmPassword";
import ResetComplete from "./Pages/Login/ResetComplete";
import PlotDetails from "./Pages/Dashboard/plot/PlotDetails";
import AddPlotDetails from "./Pages/Dashboard/plot/AddPlotDetails";
import { EmailProvider } from "./components/context/EmailContext";
import AddBlogDetails from "./Pages/Dashboard/blog/AddBlogDetails";
import BlogDetails from "./Pages/Dashboard/blog/BlogDetails";
import EditBlog from "./Pages/Dashboard/blog/EditBlog";
import Navbar from "./components/Navbar/Navbar";
import AddSalesAgentDetails from "./Pages/Dashboard/salesAgent/AddSalesAgentDetails";
import SalesAgentDetails from "./Pages/Dashboard/salesAgent/SalesAgentDetails";
import EditLocation from "./Pages/Dashboard/location/EditLocation";
import EditPlot from "./Pages/Dashboard/plot/EditPlot";
import AddAdditionalItemDetails from "./Pages/Dashboard/additionalItem/AddAdditionalItemDetails";
import ItemDetails from "./Pages/Dashboard/additionalItem/ItemDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditItem from "./Pages/Dashboard/additionalItem/EditItem";
import EditSalesAgent from "./Pages/Dashboard/salesAgent/EditSalesAgent";
import ParentLocation from "./Pages/Dashboard/location/ParentLocation";
import ParentPlot from "./Pages/Dashboard/plot/ParentPlot";
import ParentItem from "./Pages/Dashboard/additionalItem/ParentItem";
import ParentAgent from "./Pages/Dashboard/salesAgent/ParentAgent";
import ParentBlog from "./Pages/Dashboard/blog/ParentBlog";
import OrderList from "./Pages/Dashboard/order/OrderList";
import OrderDetails from "./Pages/Dashboard/order/OrderDetails";
import UserList from "./Pages/Dashboard/users/UserList";
import UserDetails from "./Pages/Dashboard/users/UserDetails";
import Settings from "./Pages/Dashboard/setting/Settings";
import PlotManagement from "./Pages/Dashboard/plotManagement/PlotManagement";
import { PlotProvider } from "./components/context/PlotContext";
import PlotDetailsOne from "./Pages/Dashboard/plotManagement/PlotDetailsOne";
import AddRole from "./Pages/Dashboard/roles/AddRole";
import EditRole from "./Pages/Dashboard/roles/EditRole";
import Roles from "./Pages/Dashboard/roles/Roles";
import RoleDetails from "./Pages/Dashboard/roles/RoleDetails";

function PrivateRoute({ element }) {
  const isLoggedIn = localStorage.getItem("token");

  return isLoggedIn ? element : <Navigate to="/login" />;
}

function App() {
  const isLoggedIn = localStorage.getItem("token");
  return (
    <>
      <EmailProvider>
        <PlotProvider>
          <div className="admin_navbar">
            <BrowserRouter>
              <Navbar />
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate to={isLoggedIn ? "/dashboard" : "/login"} />
                  }
                />
                <Route
                  path="/dashboard"
                  element={<PrivateRoute element={<Dashboard />} />}
                />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/locations/*"
                  element={<PrivateRoute element={<ParentLocation />} />}
                />
                <Route
                  path="/locations/editLocation/:id"
                  element={<PrivateRoute element={<EditLocation />} />}
                />
                <Route
                  path="/locations/locationDetails/:id"
                  element={<PrivateRoute element={<LocationDetails />} />}
                />
                <Route
                  path="/locations/add-location-details"
                  element={<PrivateRoute element={<AddLocationDetails />} />}
                />
                {/* Plot Routes */}
                <Route
                  path="/plots/*"
                  element={<PrivateRoute element={<ParentPlot />} />}
                />
                <Route
                  path="/plots/addPlotDetails"
                  element={<PrivateRoute element={<AddPlotDetails />} />}
                />
                <Route
                  path="/plots/plotdetails/:id"
                  element={<PrivateRoute element={<PlotDetails />} />}
                />
                <Route
                  path="/plots/editplot/:id"
                  element={<PrivateRoute element={<EditPlot />} />}
                />
                {/* Plot Management Items Routes */}
                <Route
                  path="/plotsManagement/*"
                  element={<PrivateRoute element={<PlotManagement />} />}
                />
                <Route
                  path="/plots/newPlotDetail/:id"
                  element={<PrivateRoute element={<PlotDetailsOne />} />}
                />
                {/* Additional Items Routes */}
                <Route
                  path="/additionalItems/*"
                  element={<PrivateRoute element={<ParentItem />} />}
                />
                <Route
                  path="/additionalItems/details"
                  element={
                    <PrivateRoute element={<AddAdditionalItemDetails />} />
                  }
                />
                <Route
                  path="/additionalItems/itemDetails/:id"
                  element={<PrivateRoute element={<ItemDetails />} />}
                />
                <Route
                  path="/additionalItems/edititem/:id"
                  element={<PrivateRoute element={<EditItem />} />}
                />
                {/* Blog Routes */}
                <Route
                  path="/blog/*"
                  element={<PrivateRoute element={<ParentBlog />} />}
                />
                <Route
                  path="/blog/addBlogDetails"
                  element={<PrivateRoute element={<AddBlogDetails />} />}
                />
                <Route
                  path="/blog/blogDetails/:id"
                  element={<PrivateRoute element={<BlogDetails />} />}
                />
                <Route
                  path="/blog/editBlog/:id"
                  element={<PrivateRoute element={<EditBlog />} />}
                />
                {/* Agent Routes */}
                <Route
                  path="/salesAgent/*"
                  element={<PrivateRoute element={<ParentAgent />} />}
                />
                <Route
                  path="/salesAgent/addSalesAgentDetails"
                  element={<PrivateRoute element={<AddSalesAgentDetails />} />}
                />
                <Route
                  path="/salesAgent/salesAgentDetails/:id"
                  element={<PrivateRoute element={<SalesAgentDetails />} />}
                />
                <Route
                  path="/salesAgent/editAgentDetails/:id"
                  element={<PrivateRoute element={<EditSalesAgent />} />}
                />
                {/* Order Routes */}
                <Route
                  path="/order/*"
                  element={<PrivateRoute element={<OrderList />} />}
                />
                <Route
                  path="/order/details/:id"
                  element={<PrivateRoute element={<OrderDetails />} />}
                />
                {/* Users Routes */}
                <Route
                  path="/users/*"
                  element={<PrivateRoute element={<UserList />} />}
                />
                <Route
                  path="/users/userDetails/:id"
                  element={<PrivateRoute element={<UserDetails />} />}
                />

                {/* Roles Routes */}
                <Route
                  path="/rolesandpermission/*"
                  element={<PrivateRoute element={<Roles />} />}
                />
                <Route
                  path="/rolesandpermission/add-role"
                  element={<PrivateRoute element={<AddRole />} />}
                />
                <Route
                  path="/rolesandpermission/details/:id"
                  element={<PrivateRoute element={<RoleDetails />} />}
                />
                <Route
                  path="/edit-role/*"
                  element={<PrivateRoute element={<EditRole />} />}
                />
                {/* MAIN ROUTES */}
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/verifyotp" element={<VerifyOTP />} />
                <Route path="/confirmpassword" element={<ConfirmPassword />} />
                <Route path="/resetcomplete" element={<ResetComplete />} />
                <Route path="/settings" element={<Settings />} />
              </Routes>
            </BrowserRouter>
            <ToastContainer />
          </div>
        </PlotProvider>
      </EmailProvider>
    </>
  );
}

export default App;
