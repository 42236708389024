import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import error from "../assets/Images/warning 1.png";

Modal.setAppElement("#root");

const CustomModal = ({
  isOpen,
  onClose,
  SuccessMessage,
  ModalMessage,
  onConfirmDelete,
  isLoading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Location Added Successfully"
      className="modal-contentmodal-content"
      overlayClassName="modal-overlay">
      <div className="modal-body">
        <img src={error} alt="" />
        <h2>{SuccessMessage}</h2>
        <p>{ModalMessage}</p>
        <div className="d-flex justify-content-center align-items-center">
          <button className="modal-close-btn" onClick={onClose}>
            No
          </button>
          <button
            className="modal-confirm-btn modal-confirm-btn-one ms-2"
            onClick={onConfirmDelete}
            disabled={isLoading}>
            {isLoading ? <div className="upload_loader-two"></div> : "Confirm"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
