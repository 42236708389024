import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  root: {
    // input label when focused
    "& label.Mui-focused": {
      color: "#219653",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#219653",
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#219653",
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#219653",
      },
    },
  },
});

const CustomInput = ({ type, value, name, handleChange, label }) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        type={type}
        name={name}
        className={classes.root}
        id="outlined-basic"
        label={label}
        fullWidth
        onChange={handleChange}
        value={value}
        variant="outlined"
      />
    </>
  );
};

export default CustomInput;
