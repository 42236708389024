import React, { useState } from "react";
import HomeImage from "../../shared/HomeImage";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CommonSection from "../../shared/CommonSection";
import LogoImage from "../../shared/LogoImage";
import api from "../../services/api";
import { useEmailContext } from "../../components/context/EmailContext";
import ErrorModal from "../../shared/ErrorModal";
import { useUserContext } from "../../components/context/UserContext";
import Loading from "../../shared/Loading";

export default function ConfirmPassword() {
  const { email } = useEmailContext();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmationStatus, setConfirmationStatus] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [modalMessage, setModalMessage] = useState(""); // Message for the modal
  const [isLoading, setIsLoading] = useState(false);

  const openModal = (message) => {
    setIsModalOpen(true);
    setModalMessage(message);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMessage("");
  };

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityOne = () => {
    setShowConPassword(!showConPassword);
  };

  const handleConfirmPassword = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      // Password length is less than 8 characters
      setPassword("");
      setConfirmPassword("");
      setConfirmationStatus("Password must be at least 8 characters");
    } else if (password !== confirmPassword) {
      // Password and Confirm Password do not match
      setPassword("");
      setConfirmPassword("");
      openModal(true);
      // setConfirmationStatus("Password and confirm password do not match.");
    } else {
      try {
        setIsLoading(true);
        const response = await api.confirmPassword(
          confirmPassword,
          email,
          password
        );

        if (response.data.passwordsMatch) {
          setConfirmationStatus("Passwords match!");
        } else {
          setConfirmationStatus("Password and confirm password do not match.");
          openModal(true);
        }
        navigate("/resetcomplete");
      } catch (error) {
        console.log(error);
        setConfirmationStatus("Error occurred while confirming password.");
        openModal(true);
        setIsLoading(true);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col
            lg="6"
            className="d-flex align-items-center justify-content-center">
            <div className="homepage_left">
              <div className="mb-5">
                <LogoImage />
              </div>
              <CommonSection title={"Set new password"} />
              <div className="login-input-details">
                <p>Must be at least 8 characters</p>
                <form>
                  <div className="mb-3 input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${
                        showPassword ? "text-input" : "password-input"
                      }`}
                      placeholder="Password"
                      id="exampleInputPassword1"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <i class="fa fa-lock" aria-hidden="true"></i>
                    <div
                      class="password-hide"
                      onClick={togglePasswordVisibility}>
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="mb-3 input-group">
                    <input
                      type={showConPassword ? "text" : "password"}
                      className={`form-control ${
                        showConPassword ? "text-input" : "password-input"
                      }`}
                      placeholder="Confirm  Password"
                      id="exampleInputPassword2"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <i class="fa fa-lock" aria-hidden="true"></i>
                    <div
                      class="password-hide"
                      onClick={togglePasswordVisibilityOne}>
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </div>
                  </div>
                  <button
                    onClick={handleConfirmPassword}
                    type="submit"
                    class="btn login-btn">
                    Reset Password
                  </button>
                </form>
                <div className="mt-3">
                  <Link className="back_login-page" to={"/login"}>
                    <i
                      class="fa fa-long-arrow-left me-2"
                      aria-hidden="true"></i>
                    Back To Login
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6" style={{ padding: "0px" }}>
            <HomeImage />
          </Col>
        </Row>
      </Container>
      {isModalOpen && <div className="overlay"></div>}
      <ErrorModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        SuccessMessage={"Error Occurred"}
        errorMessage={"Password and confirm password do not match!"}
      />
    </>
  );
}
